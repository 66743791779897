
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  name: 'Cookies Image Viewer',
  created () {},
  mounted () {
    if (this.DialogData?.Data.FirstImage) {
      this.SelectedImage = this.DialogData?.Data.FirstImage
    }
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  activated () {
    this.activated = true
  },
  data () {
    return {
      SelectedImage: 0,
      TouchStartX: 0,
      activated: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  methods: {
    OpenImage (ImgUrl :string) {
      window.open(ImgUrl)
    },
    ChangeImage (i :number) {
      var iModified = i
      if (this.$store.getters.Direction === 'rtl') {
        iModified = -i
      }
      this.$refs.ImageRef.style.transform = 'translateX(' + 50 * -i + '%)'
      var NewImage = this.SelectedImage + iModified
      if (NewImage >= 0 && NewImage < this.Images.length) {
        setTimeout(() => {
          this.$refs.ImageRef.style.transform = 'translateX(' + 130 * -i + '%)'
          this.$refs.ImageRef.style.opacity = 0
          // this.$refs.ImageRef.style.display = 'none'
        }, 200)
        setTimeout(() => {
          this.$refs.ImageRef.style.transform = 'translateX(' + 130 * i + '%)'
          this.$refs.ImageRef.style.opacity = 0
          // this.$refs.ImageRef.style.display = 'none'
        }, 400)
        setTimeout(() => {
          this.$refs.ImageRef.style.transform = 'translateX(0)'
          this.$refs.ImageRef.style.opacity = 1
          // this.$refs.ImageRef.style.display = 'block'
          this.SelectedImage = NewImage
        }, 600)
      } else {
        setTimeout(() => {
          this.$refs.ImageRef.style.transform = 'translateX(0)'
        }, 200)
      }
    },
    TouchStart (event :TouchEvent) {
      this.TouchStartX = event.touches[0].clientX
    },
    TouchEnd (event :TouchEvent) {
      if (event.changedTouches[0].clientX > this.TouchStartX + 70) {
        this.ChangeImage(-1)
      } else if (event.changedTouches[0].clientX < this.TouchStartX - 70) {
        this.ChangeImage(1)
      }
    },
    HandleSelection () {
      if (!this.CurrentImage.Selected) {
        if (this.DialogData?.Data?.ParentThis?.ImageSelected) this.DialogData.Data.ParentThis.ImageSelected(this.SelectedImage)
        this.Images[this.SelectedImage].Selected = true
      } else {
        if (this.DialogData?.Data?.ParentThis?.ImageSelected) this.DialogData.Data.ParentThis.ImageDeSelected(this.SelectedImage)
        this.Images[this.SelectedImage].Selected = false
      }
    }
  },
  computed: {
    CurrentImage () :CookiesImageView {
      if (this.Images !== undefined && this.Images.length > 0) {
        return this.Images[this.SelectedImage]
      } else {
        return { Name: this.Translate('No Image Found'), ImgUrl: '' } as CookiesImageView
      }
    },
    Images () :Array<CookiesImageView> {
      return this.DialogData?.Data.ParentThis[this.DialogData?.Data.Images]
    }
  }
})
