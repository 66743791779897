<template>
<div :style="Name ==='' ? {'padding-inline-end': '0px'} : {'padding-inline-end': '0.2em'}" class="M-Auto" :class="Disabled ? 'CookiesInputDisabled' : ''" style="user-select: none;">
  <label style="color: inherit; padding-inline-end: 0;" class="F-C2 F-Bold SwitchInput" :class=" Type === 'Switch' ? DirectionalSwitchClass : Type === 'Check' ? DirectionalCheckBoxContainerClass : 'D-None'"><span style="border-radius: var(--rad3); padding: 0.2em;">{{Name}}</span>
    <input class="" @change="OnChange()" :ref="Res" v-model="$store.state.CookiesMemory[Res]" type="checkbox" :value="$store.state.CookiesMemory[Res]" :disabled="Disabled">
    <span :class=" Type === 'Switch' ? 'CookiesSlider round' : Type === 'Check' ? DirectionalCheckBoxClass : 'D-None'"></span>
  </label>
</div>
</template>

<script>
export default {
  name: 'CookiesCheckBox',
  created () {
    if (!this.PreserveMemory || this.$store.state.CookiesMemory[this.Res] === undefined) {
      this.$store.state.CookiesMemory[this.Res] = this.Default
    }
  },
  data () {
    return {}
  },
  props: {
    Default: { default: false },
    DefaultReset: { default: false },
    Name: { default: '', Type: String },
    Res: { default: '', type: String },
    Type: { default: 'Check', Type: String }, // Check, Switch
    Disabled: { default: false, Type: Boolean },
    PreserveMemory: { default: true, Type: Boolean }
  },
  methods: {
    OnChange () {
      try {
        this.$refs[this.Res].checked = this.$store.state.CookiesMemory[this.Res]
        this.$refs[this.Res].blur()
      } catch (error) {
      }
      this.$emit('changed')
      // this.$store.state.CookiesMemory[this.Res] = event.target.value
    }
  },
  computed: {
    DirectionalSwitchClass () {
      if (this.$store.getters.Direction === 'ltr') {
        return 'CookiesSwitchLeft'
      } else {
        return 'CookiesSwitchRight'
      }
    },
    DirectionalCheckBoxContainerClass () {
      if (this.$store.getters.Direction === 'ltr') {
        return 'CookiesCheckBoxContaierLeft'
      } else {
        return 'CookiesCheckBoxContaierRight'
      }
    },
    DirectionalCheckBoxClass () {
      if (this.$store.getters.Direction === 'ltr') {
        return 'CookiesCheckBoxLeft'
      } else {
        return 'CookiesCheckBoxRight'
      }
    },
    CurrentStoreValue () {
      return this.$store.state.CookiesMemory[this.Res]
    }
  },
  watch: {
    CurrentStoreValue: function (newVal, oldVal) { // watch it
      this.$store.state.CookiesMemory[this.Res] = newVal
    },
    Default: function (newVal, oldVal) {
      console.log(this.Res, oldVal, newVal)
      if (this.DefaultReset) this.$store.state.CookiesMemory[this.Res] = this.Default
    }
  }
}
</script>

<style scoped>

.CookiesInputDisabled{
  opacity: 0.3;
  pointer-events: none;
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Cookies Check Box */
.CookiesCheckBoxLeft{
  width: 1.6em;
  background-color: v-bind('Theme.InputColor');
  height: 1.6em;
  position: absolute;
  top: -0.2em;
  inset-inline-start: 0;
  cursor: pointer;
  border-radius: 0.5em;
}
.CookiesCheckBoxRight{
  width: 1.6em;
  background-color: v-bind('Theme.InputColor');
  height: 1.6em;
  position: absolute;
  top: -0.2em;
  inset-inline-start: 0;
  cursor: pointer;
  border-radius: 0.5em;
}
.CookiesCheckBoxContaierLeft input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  white-space: nowrap;
}
.CookiesCheckBoxContaierLeft{
  position: relative;
  padding-inline-start: 2em;
  padding-inline-end: 0.7em;
  white-space: nowrap;
}
.CookiesCheckBoxContaierLeft input:checked ~ .CookiesCheckBoxLeft {
  background-color: v-bind('Theme.InputColor');
}
.CookiesCheckBoxContaierLeft input:checked ~ .CookiesCheckBoxLeft:after {
  display: block;
}
.CookiesCheckBoxContaierLeft .CookiesCheckBoxLeft:after {
  inset-inline-start: 0.5em;
  top: -0.2em;
  width: 0.5em;
  height: 1.1em;
  border: solid v-bind('Theme.UserColors.fourth');
  border-width: 0 0.4em 0.4em 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.CookiesCheckBoxContaierRight input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  white-space: nowrap;
}
.CookiesCheckBoxContaierRight{
  position: relative;
  padding-inline-start: 2em;
  padding-inline-end: 0.7em;
  white-space: nowrap;
}
.CookiesCheckBoxContaierRight input:checked ~ .CookiesCheckBoxRight {
  background-color: v-bind('Theme.InputColor');
}
.CookiesCheckBoxContaierRight input:checked ~ .CookiesCheckBoxRight:after {
  display: block;
}
.CookiesCheckBoxContaierRight .CookiesCheckBoxRight:after {
  inset-inline-end: 0.5em;
  top: -0.2em;
  width: 0.5em;
  height: 1.1em;
  border: solid v-bind('Theme.UserColors.fourth');
  border-width: 0 0.4em 0.4em 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.CookiesCheckBoxLeft::after{
  content: "";
  position: absolute;
  display: none;
}
.CookiesCheckBoxRight::after{
  content: "";
  position: absolute;
  display: none;
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Cookies Check Box */
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Cookies Switch */
/* The switch - the box around the slider */
.CookiesSwitchLeft {
  position: relative;
  display: inline-block;
  height: 2em;
  padding-inline-start: 4.2em;
  margin-top: 0.75em;
}
.CookiesSwitchRight {
  position: relative;
  display: inline-block;
  height: 2em;
  padding-inline-start: 4.2em;
  margin-top: 0.75em;
}

/* Hide default HTML checkbox */
.CookiesSwitchLeft input {
  opacity: 0;
  width: 0;
  height: 0;
}
.CookiesSwitchRight input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.CookiesSlider {
  position: absolute;
  cursor: pointer;
  top: -0.36em;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset var(--shadow2);
  background-color: var(--shade2);
  -webkit-transition: .3s;
  transition: .3s;
  transition-property: background-color;
  -webkit-transition-property: background-color;
  width: 3.5em;
  height: 2em;
}

.CookiesSlider:before {
  position: absolute;
  content: "";
  height: 1.5em;
  width: 1.5em;
  left: 0.24em;
  bottom: 0.24em;
  background-color: rgb(255, 255, 255);
  filter: drop-shadow(var(--shadow1));
  -webkit-transition: .5s;
  transition: .5s;
  transition-property: transform;
  -webkit-transition-property: transform;
}

input:checked + .CookiesSlider {
  background-color: v-bind('Theme.UserColors.fourth');
}

input:checked + .CookiesSlider:before {
  -webkit-transform: translateX(1.5em);
  -ms-transform: translateX(1.5em);
  transform: translateX(1.5em);
}

/* Rounded CookiesSliders */
.CookiesSlider.round {
  border-radius: 100px;
}

.CookiesSlider.round:before {
  border-radius: 50%;
}
.SwitchInput:focus-within span{
  outline: 1px solid v-bind('Theme.UserColors.third_50') !important;
}
.SwitchInput{
  outline: 1px solid transparent;
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Cookies Switch */
</style>
