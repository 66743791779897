
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      TableSaving: [] as Array<'Saving'>,
      Saved: undefined as boolean|undefined,
      Loading: [] as Array<'Loading'>,
      CookiesData: {} as any,
      Categories: [] as any,
      ErrorsVisibilty: false,
      OriginalData: {} as any,
      Saving: false
    }
  },
  computed: {
    DialogMapper () {
      var DM = [
        { Path: 'Name', Res: 'D_Company_Name' },
        { Path: 'LegalName', Res: 'D_Company_LegalName' },
        { Path: 'Description', Res: 'D_Company_Description' },
        // //////////////////////////////////////////
        { Path: 'LogoURL', Res: 'D_Company_Logo', IsImage: true },
        { Path: 'FavIconURL', Res: 'D_Company_FavIcon', IsImage: true },
        // //////////////////////////////////////////
        { Path: 'CompanyNO', Res: 'D_Company_CompanyNO' },
        { Path: 'Alies', Res: 'D_Company_Alies' },
        // //////////////////////////////////////////
        { Path: 'TaxRatio', Res: 'D_Company_TaxRatio' },
        { Path: 'TaxNO', Res: 'D_Company_TaxNO' },
        { Path: 'Currency', Res: 'D_Company_Currency' },
        // //////////////////////////////////////////
        { Path: 'Governorate', Res: 'D_Company_Governorate' },
        { Path: 'City', Res: 'D_Company_City' },
        { Path: 'Area', Res: 'D_Company_Area' },
        { Path: 'Street', Res: 'D_Company_Street' },
        // //////////////////////////////////////////
        { Path: 'PhoneNumber1', Res: 'D_Company_PhoneNumber1' },
        { Path: 'PhoneNumber2', Res: 'D_Company_PhoneNumber2' },
        { Path: 'Fax', Res: 'D_Company_Fax' },
        { Path: 'PoBox', Res: 'D_Company_PoBox' },
        // //////////////////////////////////////////
        { Path: 'Email', Res: 'D_Company_Email' },
        // //////////////////////////////////////////
        { Path: 'FoundingDate', Res: 'D_Company_FoundingDate' }
      ]
      return DM as Array<DialogMapperObject>
    }
  },
  methods: {
    SaveData () {
      if (this.MEMRead('D_Company_FoundingDate') === '') this.MEMClean('D_Company_FoundingDate')
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Companies',
        OriginalData: this.OriginalData,
        PatchAPI: 'Companies',
        Editing: this.DialogData?.Editing,
        PatchQuery: '/' + this.DialogData?.Data?.ID
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    }
  },
  unmounted () {
    this.MEMClean('D_Company')
  },
  created () {
    if (this.DialogData?.Data) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data) as Company
    }
  },
  mounted () {
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
    if (this.DialogData?.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
      this.Console(this.OriginalData)
    }
  }
})
