
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {
    this.ReadCookiesData()
    this.ReadCustomers()
  },
  data () {
    return {
      CookiesData: [] as Array<any>,
      Loading: [] as Array<string>,
      Customers: [] as any[],
      CustomerTransactions: [] as any[],
      MappedCustomers: {} as any,
      Warehouses: [] as Array<any>,
      InfiniteScroller: true
    }
  },
  computed: {
    Name () {
      return this.Translate('Security')
    },
    TableSettings () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'R_InventoryExports_Table',
        ResID: 'ID',
        MultiLevel: this.MEMRead('R_Exports_Multi'),
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Date',
            Type: 'datetime',
            Value: 'CustomDate'
          },
          {
            Name: 'Type',
            Type: 'normal',
            Value: 'Type',
            Bold: true,
            Class: 'F1_25em',
            IsSearchable: true
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'ItemName',
            IsSearchable: true
          },
          {
            Name: 'Location',
            Type: 'normal',
            Value: 'Location',
            IsSearchable: true
          },
          {
            Name: 'Description',
            Type: 'normal',
            Value: 'Description',
            IsSearchable: true
          },
          {
            Name: 'Quantity',
            Type: 'normal',
            Value: 'Quantity',
            IsSearchable: true,
            Total: true,
            Unit: this.Translate('m3')
          },
          {
            Name: 'Price',
            Type: 'normal',
            Value: 'Price',
            IsSearchable: true,
            Unit: this.PriceUnit
          },
          {
            Name: 'Added Cost',
            Type: 'normal',
            Value: 'AddedCost',
            IsSearchable: true,
            Unit: this.PriceUnit
          },
          {
            Name: 'Discount',
            Type: 'normal',
            Value: 'Discount',
            IsSearchable: true,
            Unit: this.PriceUnit
          },
          {
            Name: 'On him',
            Type: 'normal',
            Value: 'TotalOn',
            IsSearchable: true,
            Total: true,
            Unit: this.PriceUnit,
            Bold: true,
            CellDynamicClass: {
              ClassList: 'BGRed2',
              FixedValue: 0,
              Operator: 'LARGER',
              ValuePath: 'TotalOn'
            }
          },
          {
            Name: 'From him',
            Type: 'normal',
            Value: 'TotalFor',
            IsSearchable: true,
            Total: true,
            Unit: this.PriceUnit,
            Bold: true,
            CellDynamicClass: {
              ClassList: 'BGGreen',
              FixedValue: 0,
              Operator: 'LARGER',
              ValuePath: 'TotalFor'
            }
          },
          {
            Name: 'Balance',
            Type: 'normal',
            Value: 'Balance',
            IsSearchable: true,
            Class: 'F1_25em',
            Unit: this.PriceUnit,
            Bold: true,
            Style: 'background-color: var(--colorr2); padding: 0.1em 0.3em;',
            CellDynamicClass: {
              ClassList: 'FORCED_BGGreen',
              FixedValue: 0,
              Operator: 'LARGER',
              ValuePath: 'Balance'
            }
          }
        ],
        Footer: true,
        Header: true,
        HideTableName: true,
        TableSelectMemory: true,
        PrintControls: true,
        TableSelectDefaults: true,
        InfiniteScroller: this.InfiniteScroller,
        DefaultSort: {
          Direction: 1,
          Path: 'CustomDate'
        }
      } as CookiesTable
      return Table
    },
    FilteredData () {
      // var Category = this.MEMRead('R_Exports_Category_Value')
      var DateFrom = new Date(this.$store.state.CurrentDate.From)
      var DateTo = new Date(new Date(this.$store.state.CurrentDate.To).setHours(23, 59, 59, 9999))
      return this.TableData.filter((element :any) => {
        // if (Category && Category !== '' && Category.ID && element.CategoryID !== Category.ID) return false
        var InDateRange = new Date(element.CustomDate) >= DateFrom && new Date(element.CustomDate) <= DateTo
        return InDateRange
      })
    },
    ModifiedData () {
      return this.CookiesData.map((value) => { return { ...value, CustomerName: this.MappedCustomers['c' + value.CustomerID?.toString()] } })
    },
    SelectedCustomer () {
      return this.MEMRead('R_Sales_Customers_Value')
    },
    TableData () {
      var CombinedData: any[] = []
      this.CookiesData.forEach((value) => {
        CombinedData.push({
          ...value,
          Type: this.Translate('Sale'),
          TotalOn: value.Total
        })
      })
      this.CustomerTransactions.forEach((value) => {
        CombinedData.push({
          ...value,
          Type: this.Translate('Collect'),
          TotalFor: value.Value,
          Discount: -value.paymentDiscountValue
        })
      })
      var AddedDateSortedData = this.GeneralSort(CombinedData, 'AddedDate', 1)
      var SortedData = this.GeneralSort(AddedDateSortedData, 'CustomDate', 1)
      var DecodedData: any[] = []
      var Balance = 0
      SortedData.forEach((value) => {
        Balance -= value.TotalOn !== undefined ? value.TotalOn : 0
        Balance += (value.TotalFor !== undefined ? value.TotalFor + value.Discount : 0)
        DecodedData.push({
          ...value,
          Balance: Balance
        })
      })
      return DecodedData
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Sales_Orders', '?CustomerID=' + this.SelectedCustomer?.ID).then(response => {
        this.CookiesData = response.map((value: any) => { return { ...value, PhoneCall: 'tel:' + value.PhoneNumber, Total: (value.Price * value.Quantity) + value.AddedCost + value.Discount } })
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Transfers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
      this.ReadCustomerTransactions()
    },
    ReadCustomers () {
      this.Loading.push('Loading')
      this.Get('CRM_Customers', '?Type=c').then(response => {
        this.Customers = this.GeneralSort(response.map((value: any) => { return { ...value, ID: value.ID.toString(), Serial: '#' + value.ID } }), 'ID', -1)
        this.Customers.forEach((value, index, array) => { this.MappedCustomers['c' + value.ID] = value.Name })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCustomerTransactions () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Finance_Transactions', '?CustomerID=' + this.SelectedCustomer?.ID).then(response => {
        this.CustomerTransactions = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    printIT () {
      this.InfiniteScroller = false
      setTimeout(() => {
        var bodd = document.getElementById('TableToPrint')
        if (!bodd) return
        this.$store.state.Printing = bodd.cloneNode(true) as HTMLElement
      }, 200)
      setTimeout(() => {
        this.InfiniteScroller = true
      }, 500)
    },
    DownloadExcel () {
      var DatatoExport :any = this.MEMRead(this.TableSettings.CookiesID).map((element :any) => {
        var addedspecs :any = {}
        // if (element.Specifications && element.Specifications[0] && element.Specifications[0].Name) addedspecs[element.Specifications[0].Name] = element.Specifications[0].Description
        // if (element.Specifications && element.Specifications[1] && element.Specifications[1].Name) addedspecs[element.Specifications[1].Name] = element.Specifications[1].Description
        return {
          No: element.TransferNO,
          AddedDate: this.DateFormatter(element.AddedDate),
          Time: this.DateFormatter(element.AddedDate, 'Time'),
          LastEdit: this.DateFormatter(element.UPDTime),
          Customer: element.CustomerName,
          Status: element.TranslatedStatus
        }
      })
      this.ExportFromJSON(DatatoExport, 'Exports Report')
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
        this.ReadCustomers()
      }
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadCookiesData()
      this.ReadCustomers()
    },
    SelectedCustomer: function (newVal, oldVal) { // watch it
      this.CookiesData = []
      this.CustomerTransactions = []
      this.ReadCookiesData()
      this.ReadCustomers()
    }
  }
})
