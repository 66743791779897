
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      SelectedItems: [] as any
    }
  },
  computed: {},
  // ---------------------------------------------------
  methods: {
    Selected (item :any) {
      return this.IndexFinder(this.SelectedItems, 'ID', item.ID)
    },
    Select (item :any) {
      // console.log(item)
      var SelectedItem = { AvalID: item.ID, ...this.DialogData?.Data.Item, Quantity: item.Quantity, Details: item.Details }
      if (this.DialogData?.Data?.this?.AddItem) this.DialogData.Data.this.AddItem(SelectedItem)
      this.SelectedItems.push(item)
      // var Found = this.IndexFinder(this.SelectedItems, 'ID', item.ID)
      // if (Found > -1) {
      //   this.SelectedItems.splice(Found, 1)
      // } else {
      //   this.SelectedItems.push(item)
      // }
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    try {
      var SelectedItems = this.DialogData?.Data.this.TransferItems
      var AvailableItems = this.DialogData?.Data?.AvailableItems
    } catch (error) {
    }
    if (SelectedItems && SelectedItems.length > 0 && AvailableItems && AvailableItems.length > 0) {
      SelectedItems.forEach((selectedItem :any) => {
        var Found = AvailableItems.find((availableItem :any) => {
          return availableItem.ID === selectedItem.AvalID
        })
        if (Found) {
          this.SelectedItems.push(Found)
        }
      })
    }
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "132a965b": (_ctx.Theme.BG2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__