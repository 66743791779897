
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Warehouse>,
      ItemsLocations: [] as Array<any>,
      Branches: [] as Array<Category>,
      Sections: [] as Array<Section>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {}
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Warehouse',
          this: this,
          Function: 'NewWarehouse',
          Icon: 'Add',
          Visible: this.Access('Inventory_Warehouses_Create')
        }
        // {
        //   Name: 'Fill Warehouses',
        //   this: this,
        //   Function: 'FillWarehouse',
        //   Icon: 'Arrow',
        //   Visible: this.Access('SuperAdmin')
        // }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: { Direction: 1, Path: ['Code'] },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Code',
            Path: ['Code'],
            Sort: ['Code'],
            Bold: true,
            Class: 'F1_25em'
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name']
          },
          {
            Type: 'Normal',
            Name: 'Description',
            Path: ['Description'],
            Sort: ['Description'],
            Class: 'F_75em'
          },
          {
            Type: 'Normal',
            Name: 'Branch',
            Path: ['Branch', 'Name'],
            Sort: ['Branch', 'Name'],
            Visible: this.Access('Branches_Read')
          },
          {
            Type: 'Normal',
            Name: 'Volume',
            Path: 'Volume',
            Sort: ['Volume'],
            Bold: true,
            Unit: this.VolumeUnit
          },
          {
            Type: 'Bar',
            Name: 'Used',
            Path: ['AvailableQuantityVolume'],
            Sort: ['AvailableQuantityVolume'],
            BarMinFixed: 0,
            BarMaxPath: 'MaximumVolume',
            BarValues: [
              {
                Name: this.Translate('Used'),
                ValuePath: 'AvailableQuantityVolume'
              },
              {
                Name: this.Translate('Empty'),
                ValuePath: 'EmptyVolume',
                CustomStyle: 'background: none; width: 0;'
              }
            ],
            Style: 'min-width: 4em;'
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                Style: 'cursor: unset !important; font-size: 0.75em',
                ID: 'Info',
                DynamicToolTip: ['Description'],
                DynamicVisiblity: {
                  FixedValue: '',
                  Operator: 'NOT EQUAL LOWERCASE',
                  ValuePath: 'Description'
                }
              },
              {
                ID: 'Shelves',
                Visible: this.Access('Inventory_Warehouses_Read'),
                ToolTip: this.Translate('Sections'),
                Emits: 'EditWarehouseSections'
              },
              {
                ID: 'Edit',
                Visible: this.Access('Inventory_Warehouses_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditWarehouse'
              },
              {
                ID: 'Delete',
                Emits: 'DeleteWarehouse',
                Visible: this.Access('Inventory_Warehouses_Remove'),
                ToolTip: this.Translate('Remove')
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      var FilteredData = this.CookiesData.map(warehouse => {
        var Total = warehouse?.AvailableQuantityVolume
        if (!Total) Total = 0
        return {
          EmptyVolume: warehouse.MaximumVolume ? warehouse.MaximumVolume - Total : 0,
          ...warehouse
        }
      })
      return this.CookiesFilter(FilteredData, this.Filter, this.Search)
    },
    LastLevelSections () {
      return this.Sections.filter((sec :any) => {
        var Found = this.Sections.some(innersec => {
          return innersec.SectionLevel1ID === sec.ID || innersec.SectionLevel2ID === sec.ID
        })
        return !Found
      })
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Inventory_Warehouses').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Warehouses'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditWarehouse (Warehouse :Warehouse) {
      if (!this.Access('Inventory_Warehouses_Edit')) {
        return
      }
      this.OpenDialog('Inventory_Warehouse_Dialog', this.Translate('Edit Warehouse') + ' (' + Warehouse.Name + ') ', { Data: Warehouse, Editing: true }, {})
    },
    EditWarehouseSections (Warehouse :Warehouse) {
      if (!this.Access('Inventory_Warehouses_Read')) {
        return
      }
      this.OpenDialog('Inventory_WarehouseSections_Dialog', this.Translate('Edit Sections'), { Data: Warehouse, Editing: true }, {})
    },
    DeleteWarehouse (Warehouse :Warehouse) {
      if (!this.Access('Inventory_Warehouses_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Inventory_Warehouses', AddQuery: '/' + Warehouse.ID, Text: 'Are you sure you want to delete <strong>' + Warehouse.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    UserDeleted (ImgUrl :string) {
      if (ImgUrl) this.DeleteImage(ImgUrl)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewWarehouse':
          this.OpenDialog('Inventory_Warehouse_Dialog', this.Translate('New Warehouse'), { Data: {} })
          break
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
