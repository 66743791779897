
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {
    if (this.Tabs.length > 0) {
      this.$router.push(this.Tabs[0].Link as any)
    }
  },
  data () {
    return {}
  },
  computed: {
    RouteName () {
      return this.$route.name
    },
    Name () {
      return this.Translate('Security')
    },
    Tabs () {
      var Tabs = [
        { Name: this.Translate('Inventory'), Link: '/Reports/Inventory', Access: this.Access('Inventory_View') },
        { Name: this.Translate('Sales'), Link: '/Reports/Sales', Access: this.Access('Sales_View') },
        { Name: this.Translate('Purchases'), Link: '/Reports/Purchase', Access: this.Access('Purchase_View') },
        { Name: this.Translate('Finance'), Link: '/Reports/Finance', Access: this.Access('Finance_View') }
      ] as Array<CookiesTabs>
      return Tabs.filter(tab => {
        return tab.Access
      })
    }
  },
  methods: {},
  watch: {
    RouteName: function () { // watch it
      // var ArrayofPaths = this.AttributeArray(this.Tabs, 'Link')
      var currentPath = this.$route.fullPath
      console.log(currentPath)
      if (this.Tabs.length > 0 && this.Tabs[0].Link && currentPath && currentPath === '/Reports') {
        this.$router.push(this.Tabs[0].Link as any)
      }
      // if (this.Tabs.length > 0 && this.Tabs[0].Link && currentPath && !(ArrayofPaths.indexOf(currentPath) > -1)) {
      //   this.$router.push(this.$route.path + '/' + this.Tabs[0].Link)
      // }
    }
  }
})
