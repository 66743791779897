
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  setup () {},
  components: {},
  name: 'Test',
  created () {},
  data () {
    return {
      Saving: false,
      Loading: [],
      NewPassWord: '',
      ConfirmNewPassWord: '',
      ErrorsVisibilty: false,
      ErrorText: '',
      DialogMapper: [
        { Path: 'OldPassword', Res: 'D_ChangePassword_OldPassword' },
        { Path: 'NewPassword', Res: 'D_ChangePassword_NewPassword' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ChangePassword () {
      if (this.Saving) return
      // --------------------------------------------------------------
      // ----------------------- Validity Check -----------------------
      // --------------------------------------------------------------
      // checking any dom inside this dialog that contains attribute CookiesInvalidInput = true
      this.Saving = true
      if (this.Requirments(this).Incomplete) {
        console.log('Incomplete Data')
        this.ErrorsVisibilty = true
        this.Saving = false
        this.GlobalSaveDone(false)
      }
      this.Saving = true
      this.ErrorText = ''
      if (this.$store.state.UserManagment.CurrentUser?.IsCookiesAdmin) {
        this.Post('POST', 'Security_ChangePasswordByAdmin', {
          UserID: this.DialogData?.ID,
          NewPassword: this.MEMRead('D_ChangePassword_NewPassword')
        }).then((value) => {
          this.$router.go(-1)
        }, error => {
          this.Saving = false
          this.ErrorText = error?.message
        })
        return
      }
      this.Post('POST', 'Security_ChangePassword', {
        NewPassword: this.MEMRead('D_ChangePassword_NewPassword'),
        OldPassword: this.MEMRead('D_ChangePassword_OldPassword')
      }, '/' + this.$store.state.UserManagment?.CurrentUser?.ID).then((value) => {
        this.$router.go(-1)
      }, error => {
        this.Saving = false
        this.ErrorText = error?.message
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    }
  },
  mounted () {
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  }
})
