
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
import Papa from 'papaparse'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  data () {
    return {
      PhoneNumber: '',
      ServerError: '',
      Saving: false,
      CSVLoaded: false,
      Fields: [] as any,
      CurrentData: true,
      DataIsValid: false,
      Data: [] as any,
      BodyToSave: [] as any
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: {} as DialogData }
  },
  computed: {
    Tabs () :Array<CookiesTabs> {
      return [
        {
          Name: this.Translate('Single Contact'),
          Access: this.$store.state.CookiesConfig.WhatsappStatus === 'true',
          Value: 'Single'
        },
        {
          Name: this.Translate('List of Contacts'),
          Access: this.$store.state.CookiesConfig.WhatsappStatus === 'true',
          Value: 'Multiple'
        }
      ]
    },
    TableSettings () :CookiesTable {
      var ComputedFields :Array<CookiesTableField> = [{
        Name: '',
        Type: 'index',
        Value: ''
      }]
      this.Fields.forEach((element :any) => {
        ComputedFields.push({
          Name: element,
          Type: 'normal',
          Value: element
        })
      })
      return {
        CookiesID: 'MassItemsImportTable',
        Fields: ComputedFields,
        Footer: false,
        Header: true,
        ResID: this.Fields[0]
      }
    }
  },
  mounted () {
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  methods: {
    SendMessages () {
      this.Saving = true
      this.Post('POST', 'Inventory_Items_MassADD', this.BodyToSave).then((response) => {
        this.Saving = false
        this.$router.go(-1)
      }, error => {
        this.Saving = false
        console.log(error)
      })
    },
    ParseCSV () {
      this.Saving = true
      this.DataIsValid = false
      this.CSVLoaded = false
      var MyEvent :any = event
      var file = MyEvent.target.files[0]
      var _this = this
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results :any) {
          console.log(results)
          _this.Fields = results.meta.fields
          _this.Data = results.data
          _this.CSVLoaded = true
          _this.Saving = false
          var MandatoryFields = ['Name', 'Code', 'PackSize', 'ImgUrl', 'Description', 'CategoryID', 'BrandID', 'IsUserSelect']
          var SpecsFields = results.meta.fields.filter((field :any) => {
            return MandatoryFields.indexOf(field) === -1
          })
          if (results.data.length === 0) {
            return
          }
          var IsMissing = MandatoryFields.filter(element => {
            return results.data[0][element] === undefined
          })
          console.log(IsMissing)
          console.log(SpecsFields)
          var ModifiedData :any = []
          if (IsMissing.length === 0) {
            _this.DataIsValid = true
            results.data.forEach((item :any) => {
              var ComputedItem :any = {
                Specifications: []
              }
              MandatoryFields.forEach(element => {
                if (element === 'PackSize' && item[element] === '') return
                ComputedItem[element] = item[element]
              })
              SpecsFields.forEach((spec :any) => {
                if (!item[spec] || item[spec] === '') return
                ComputedItem.Specifications.push({
                  Name: spec,
                  Description: item[spec]
                })
              })
              ModifiedData.push(ComputedItem)
            })
            _this.BodyToSave = ModifiedData
            console.log(ModifiedData)
          }
        }
      })
    }
  }
})
