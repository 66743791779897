
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {
    this.ReadCookiesData()
    this.ReadCustomers()
  },
  data () {
    return {
      CookiesData: [] as Array<any>,
      Loading: [] as Array<string>,
      Customers: [] as any[],
      MappedCustomers: {} as any,
      Warehouses: [] as Array<any>,
      InfiniteScroller: true
    }
  },
  computed: {
    Name () {
      return this.Translate('Security')
    },
    TableSettings () {
      var Table = {
        TableName: this.Translate('Purchase Orders'),
        CookiesID: 'R_PurchaseOrders_Table',
        ResID: 'ID',
        MultiLevel: this.MEMRead('R_Exports_Multi'),
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Date',
            Type: 'datetime',
            Sort: 'CustomDate',
            Value: 'CustomDate'
          },
          {
            Name: 'NO.',
            Type: 'normal',
            Value: 'CustomerID',
            Bold: true,
            Sort: 'CustomerID',
            IsID: true,
            IsSearchable: true
          },
          {
            Name: 'Vendor',
            Type: 'normal',
            Value: 'CustomerName',
            IsSearchable: true,
            Sort: 'CustomerName',
            Bold: true
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'ItemName',
            IsSearchable: true,
            Sort: 'ItemName',
            Bold: true
          },
          {
            Name: 'Description',
            Type: 'normal',
            Value: 'Description',
            IsSearchable: true,
            Sort: 'Description'
          },
          {
            Name: 'Quantity',
            Type: 'normal',
            Value: 'Quantity',
            IsSearchable: true,
            Sort: 'Quantity'
          },
          {
            Name: 'Price',
            Type: 'normal',
            Value: 'Price',
            IsSearchable: true,
            Unit: this.PriceUnit,
            Sort: 'Price'
          },
          {
            Name: 'Total',
            Type: 'normal',
            Value: 'Total',
            IsSearchable: true,
            Sort: 'Total',
            Total: true,
            Unit: this.PriceUnit,
            Bold: true
          }
        ],
        Footer: true,
        Header: true,
        HideTableName: true,
        TableSelectMemory: true,
        PrintControls: true,
        TableSelectDefaults: true,
        InfiniteScroller: this.InfiniteScroller,
        DefaultSort: {
          Direction: 1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      return Table
    },
    FilteredData () {
      var Search = this.MEMRead('R_Exports_Search')
      var Customer = this.MEMRead('D_Sales_Order_Customer_Value')
      // var Category = this.MEMRead('R_Exports_Category_Value')
      var DateFrom = new Date(this.$store.state.CurrentDate.From)
      var DateTo = new Date(new Date(this.$store.state.CurrentDate.To).setHours(23, 59, 59, 9999))
      return this.ModifiedData.filter((element :any) => {
        if (Customer && Customer !== '' && Customer.ID && element.CustomerID !== Customer.ID) return false
        // if (Category && Category !== '' && Category.ID && element.CategoryID !== Category.ID) return false
        var InDateRange = new Date(element.CustomDate) >= DateFrom && new Date(element.CustomDate) <= DateTo
        var Searched = this.CookiesSearcher(element, Search)
        return Searched && InDateRange
      }).map((element :any) => {
        return { ...element, TranslatedStatus: this.Translate(element.Status) }
      })
    },
    ModifiedData () {
      return this.CookiesData.map((value) => { return { ...value, CustomerName: this.MappedCustomers['c' + value.CustomerID?.toString()] } })
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Sales_Orders', '?SerialNO=p').then(response => {
        this.CookiesData = response.map((value: any) => { return { ...value, PhoneCall: 'tel:' + value.PhoneNumber, Total: (value.Price * value.Quantity) + value.AddedCost + value.Discount } })
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Transfers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCustomers () {
      this.Loading.push('Loading')
      this.Get('CRM_Customers', '?Type=v').then(response => {
        this.Customers = this.GeneralSort(response.map((value: any) => { return { ...value, ID: value.ID.toString(), Serial: '#' + value.ID } }), 'ID', -1)
        this.Customers.forEach((value, index, array) => { this.MappedCustomers['c' + value.ID] = value.Name })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    printIT () {
      this.InfiniteScroller = false
      setTimeout(() => {
        var bodd = document.getElementById('TableToPrint')
        if (!bodd) return
        this.$store.state.Printing = bodd.cloneNode(true) as HTMLElement
      }, 200)
      setTimeout(() => {
        this.InfiniteScroller = true
      }, 500)
    },
    DownloadExcel () {
      var DatatoExport :any = this.MEMRead(this.TableSettings.CookiesID).map((element :any) => {
        var addedspecs :any = {}
        // if (element.Specifications && element.Specifications[0] && element.Specifications[0].Name) addedspecs[element.Specifications[0].Name] = element.Specifications[0].Description
        // if (element.Specifications && element.Specifications[1] && element.Specifications[1].Name) addedspecs[element.Specifications[1].Name] = element.Specifications[1].Description
        return {
          No: element.TransferNO,
          AddedDate: this.DateFormatter(element.AddedDate),
          Time: this.DateFormatter(element.AddedDate, 'Time'),
          LastEdit: this.DateFormatter(element.UPDTime),
          Customer: element.CustomerName,
          Status: element.TranslatedStatus
        }
      })
      this.ExportFromJSON(DatatoExport, 'Exports Report')
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
        this.ReadCustomers()
      }
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadCookiesData()
      this.ReadCustomers()
    }
  }
})
