
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {}
  },
  computed: {
    LanguagesArray () {
      if (!this.$store.state.CompanyManagment.AvailableCompanies) return []
      var computedArray :Array<any> = this.$store.state.CompanyManagment.AvailableCompanies
      return computedArray
    }
  },
  // ---------------------------------------------------
  methods: {
    async SaveCompany (Company :any) {
      this.LocalSave('SelectedCompany', Company)
      this.$store.state.CompanyManagment.CurrentCompany = Company
      if (Company.ID === 2) {
        this.$store.state.CookiesMemory.CurrentWarehouse = { Name: 'الرقيم', IDs: '[9,10,11,13,14,15,16,17,18,22,23,24,26,28,29,30,32,33,34,35,36,37,38,null]' }
      } else {
        this.$store.state.CookiesMemory.CurrentWarehouse = undefined
      }
      if (this.DialogData?.resolve) {
        this.DialogData.resolve()
      }
      var Data = await this.Get('Config')
      console.log('Data')
      console.log(Data)
      this.ConfigMapper(Data)
      this.CookiesAuthorization()
      this.$router.go(-1)
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})
