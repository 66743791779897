
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    DialogIndex: Number,
    DialogData: { default: {} as DialogData }
  },
  data () {
    return {
      Saving: false,
      Loading: [] as Array<'Loading'>,
      ErrorsVisibilty: false,
      ItemsLocations: [] as Array<any>,
      TransferItems: [] as Array<any>,
      ExportItems: [] as Array<any>,
      AvailableItems: [] as any,
      ImportLocations: {} as any
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'D_TransferCheck_Table',
        ResID: 'ID',
        MultiLevel: true,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Details',
            Type: 'normal',
            Value: 'Details'
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize'
          },
          {
            Name: 'Quantity',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Code'
        }
      } as CookiesTable
      return Table
    },
    NotEnoughSpace () {
      var NoSpace = false
      var Locations = this.ImportLocations
      Object.keys(Locations).some(function (item) {
        var Found = Locations[item].find((element:any) => {
          console.log(element)
          return element.LocationFullPath === 'Not Enough Space'
        })
        console.log(Found)
        if (Found) {
          NoSpace = true
        }
      })
      return NoSpace
    }
  },
  // ---------------------------------------------------
  methods: {
    printIT () {
      var bodd = document.getElementById('TableToPrint')
      if (!bodd) return
      this.$store.state.Printing = bodd.cloneNode(true) as HTMLElement
    },
    ReadItemsLocations () {
      console.log(this.DialogData)
      this.Loading.push('Loading')
      this.Get('Inventory_ItemsLocation', '?ItemID=' + this.DialogData?.ItemID).then(response => {
        this.ItemsLocations = response.map((value: any) => {
          return {
            ...value,
            // LocationDecoded: (value.WarehouseID && value.WarehouseID !== 'null' && value.SectionID && value.SectionID !== 'null') ? ((value.WarehouseName && value.WarehouseName !== '' ? value.WarehouseName : '') + `(${value.WarehouseCode})` + (value.SectionLevel1ID ? (' > ' + (value.Section1Name && value.Section1Name !== '' ? value.Section1Name : '') + `(${value.Section1Code})`) : '') + (value.SectionLevel2ID ? (' > ' + (value.Section2Name && value.Section2Name !== '' ? value.Section2Name : '') + `(${value.Section2Code})`) : '') + ' > ' + (value.SectionName && value.SectionName !== '' ? value.SectionName : '') + `(${value.SectionCode})`) : ''
            LocationDecoded: value?.Section?.Warehouse?.ID ? (value?.Section?.Warehouse?.Name + `(${value?.Section?.Warehouse?.Code})` + (value?.Section?.SectionLevel1?.ID ? (' > ' + value?.Section?.SectionLevel1?.Name + `(${value?.Section?.SectionLevel1?.Code})`) : '') + (value?.Section?.SectionLevel2?.ID ? (' > ' + value?.Section?.SectionLevel2?.Name + `(${value?.Section?.SectionLevel2?.Code})`) : '') + ' > ' + (value?.Section?.Name) + `(${value?.Section?.Code})`) : ''
          }
        })
        console.log(this.ItemsLocations)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Items'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SectionItems (LocationID: number) {
      return this.DialogData?.Data.filter((value: any) => { return value.ItemLocationID === LocationID }).map((value :any) => {
        if (!value.IsPack) value.PackSize = 1
        return {
          ...value
        }
      })
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {
    this.ReadItemsLocations()
  },
  beforeMount () {},
  mounted () {},
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})
