import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesTabs = _resolveComponent("CookiesTabs")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.Tabs.length > 1)
      ? (_openBlock(), _createBlock(_component_CookiesTabs, {
          key: 0,
          Style: 'border-top-left-radius: 0; border-top-right-radius: 0; border-top: solid 1px rgba(0,0,0,0.2);',
          Class: 'F_85em',
          TabsContainerWidth: '90%',
          Res: 'Inventory',
          Tabs: _ctx.Tabs,
          Default: (_ctx.Tabs[0] as CookiesTabs)
        }, null, 8, ["Style", "Tabs", "Default"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}