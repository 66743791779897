import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "CookiesMainView"
}
const _hoisted_2 = { class: "BigPlaceholder P-Rel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesFunctionalView = _resolveComponent("CookiesFunctionalView")!

  return (_ctx.Access('CRM_View'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CookiesFunctionalView, {
            Tabs: _ctx.Tabs,
            Name: _ctx.Name
          }, null, 8, ["Tabs", "Name"])
        ])
      ]))
    : _createCommentVNode("", true)
}