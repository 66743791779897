
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      Loading: [] as Array<'Loading'>,
      Saving: false
    }
  },
  methods: {
    SaveData () {
      if (this.DialogData?.AfterDialogFunction) this.DialogData.AfterDialogFunction(this.MEMRead('D_Calendar'), this.DialogData?.Data)
    }
  },
  computed: {},
  mounted () {},
  unmounted () {},
  watch: {}
})
