
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
import { PropType, defineComponent } from 'vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Company: { default: () => { return undefined }, type: Object as PropType<Company> }
  },
  computed: {
    SliceDate: function () {
      if (!this.Company) return ''
      return new Date(this.Company.AddedDate.replaceAll(' ', '')).toUTCString().slice(4, 16)
    }

  },
  methods: {
    EditCompany (Company :Company) {
      if (!this.Access('Main_Companies_Edit')) {
        return
      }
      this.OpenDialog('Company_Dialog', 'Edit Company (' + Company.Name + ')', { Data: Company, Editing: true }, {})
    },
    DeleteCompany (Company :Company) {
      if (!this.Access('Main_Companies_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Companies', AddQuery: '/' + Company.ID, Text: 'Are you sure you want to delete <strong>' + Company.Name + '</strong><br><strong style="color: var(--colorr1);">All copmany data will be lost!!</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), Icon: 'HasIssue' } as GeneralAcceptDialogData, {})
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "148776e0": (_ctx.Theme.BG3)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__