
const routes :Array<CookiesRouter> = [
  {
    path: '/Security',
    name: 'Security',
    meta: {
      Access: 'Security_View' as AccessFeature
    },
    component: () => import('@/Apps/Security/views/Security.vue'),
    children: [
      {
        path: '/Security/Users',
        name: 'Users',
        component: () => import('@/Apps/Security/views/Users.vue'),
        meta: {
          Access: 'CookiesAdmin',
          LoginHeader: true
        }
      },
      {
        path: '/Security/People',
        name: 'People',
        component: () => import('@/Apps/Security/views/People.vue'),
        meta: {
          Access: 'Security_People_Read',
          LoginHeader: true
        }
      },
      {
        path: '/Security/Profiles',
        name: 'Profiles',
        component: () => import('@/Apps/Security/views/Roles.vue'),
        meta: {
          Access: 'Security_Profiles_Read'
        }
      }
    ]
  }
]

export const SecurityRoutes = routes
