<template>
<div :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <DialogDisabler v-if="Loading.length > 0"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <div class="DialogBody">
      <div class="W-100 D-Flex">
        <div class="D-Flex F1_25em">
          <div class="P_5em color1" style="text-align: start;">
            <div class="F1_5em F-Bold D-Flex"><span class="V-Auto">{{DeepFetcher(UserData, 'Name')}}</span><div class="V-Auto"><CookiesIcon v-if="DeepFetcher(UserData, 'CanLogIn')" class="SQR1em BGShade1 Rad2 F_75em" style="padding: 0.1em; margin: auto 0.2em;" :ID="'Key'"></CookiesIcon></div></div>
            <div class="D-Flex"><CookiesIcon class="SQR1em V-Auto P_25em" :ID="'Phone'"></CookiesIcon><span class="V-Auto">{{DeepFetcher(UserData, 'PhoneNumber')}}</span></div>
            <div class="D-Flex" v-if="DeepFetcher(UserData, 'Email') !== ''"><CookiesIcon class="SQR1em V-Auto P_25em" :ID="'Email'"></CookiesIcon><span class="V-Auto">{{DeepFetcher(UserData, 'Email')}}</span></div>
            <div class="D-Flex"><CookiesIcon class="SQR1em V-Auto P_25em" :ID="'Location'"></CookiesIcon><span class="V-Auto">{{DeepFetcher(UserData, 'AddressL1')}}</span></div>
          </div>
        </div>
        <div class="" style="margin-inline-start: auto;">
          <div class="BG2 P1em Rad3 D-Flex" style="gap: 1em;">
            <CookiesIcon :ToolTip="Translate('Whatsapp')" @click="OpenWhatsapp(DeepFetcher(UserData, 'PhoneNumber'))" class="SQR1_5em HoverUp" :ID="'Whatsapp'"></CookiesIcon>
            <CookiesIcon :ToolTip="Translate('Edit')" @click="EditCustomer" class="SQR1_5em HoverUp" :ID="'Edit'"></CookiesIcon>
          </div>
        </div>
      </div>
      <div class="CookiesRowSplitter"></div>
      <!-- <div class="CookiesRowSpacer"></div> -->
      <div class="D-Flex" style="gap: 0.8em; justify-content: center;">
        <button @click="NewSale()" class="CookiesButton BGMain Shadow0"><CookiesIcon class="SQR1_5em M-Auto" :ID="'Cart'"></CookiesIcon><span class="M-Auto F-Bold O-85">{{Translate('New Sale')}}</span></button>
        <button @click="NewVisit()" class="CookiesButton BGMain Shadow0"><CookiesIcon class="SQR1_5em M-Auto" :ID="'Location'"></CookiesIcon><span class="M-Auto F-Bold O-85">{{Translate('New Visit')}}</span></button>
      </div>
      <!-- <div class="CookiesRowSpacer"></div> -->
      <div class="CookiesRowSplitter"></div>
      <!-- <div class="CookiesRowSpacer"></div> -->
      <div class="D-Flex"><span class="Rad3 F-Bold D-Flex M-Auto" style="padding: 0.3em 0.6em;"><span class="O-75">{{Translate('Timeline')}}</span><span class="P_25em"></span><CookiesIcon @click="ReadCustomerActions" class="SQR1_5em HoverUp" :ID="'Recover'"></CookiesIcon></span></div>
      <CookiesLoading class="CookiesRowSpacer" v-if="LoadingActions.length > 0"></CookiesLoading>
      <div v-else class="CookiesRowSpacer"></div>
      <div class="D-Flex CookiesTimeline" style="flex-direction: column;">
        <div v-for="Checkpoint in CustomerActions" :key="Checkpoint" class="D-Flex" style="margin-bottom: 4em;">
          <!-- ------------------------------------------------------------------------------------------------ -->
          <!-- --------------------------------------Sale Checkpoint------------------------------------------- -->
          <template v-if="DeepFetcher(Checkpoint, 'CheckpointType') === 'sale'">
            <div class="CheckpointHeader">
              <div class="F-Bold F1_25em">{{JsonDateParser(DeepFetcher(Checkpoint, 'Date'))}}</div>
              <div>{{JsonTimeParser(DeepFetcher(Checkpoint, 'Date'))}}</div>
            </div>
            <div class="" style="width: 0.5em;">
              <div class="P-Rel Shadow1 D-Flex BG2 CheckpointIcon" style="border: solid 0.3em rgb(57, 207, 82);">
                <CookiesIcon class="M-Auto" style="width: 80%; height: 80%;" :Color="{ColorA: 'rgb(62, 198, 85)', ColorB: 'rgb(79, 162, 93)'}"  :ID="'Cart'"></CookiesIcon>
              </div>
              <div class="H-100 Rad3" style="background-color: rgb(57, 207, 82);"></div>
            </div>
            <div class="CheckpointDetails P-Rel">
              <div class="P-Abs BG2 Rad1 D-Flex" style="inset-inline-end: 0; top: 0;">
                <div class="M-Auto" style="margin-inline-start: 2em;">{{Translate('Sale')}} #{{DeepFetcher(Checkpoint, 'ID')}}</div>
                <!-- ====== Buttons ====== -->
                <CookiesIcon style="width: 1.4em; height: 1.4em;" class="W-100 H-100 HoverUp Pointer P_5em" :Tooltip="Translate('Rate')" :ID="'Star'"></CookiesIcon>
                <CookiesIcon style="width: 1.4em; height: 1.4em;" class="W-100 H-100 HoverUp Pointer P_5em" :Tooltip="Translate('Make a complaint')" :ID="'VeryAngry'"></CookiesIcon>
                <CookiesIcon style="width: 1.4em; height: 1.4em;" class="W-100 H-100 HoverUp Pointer P_5em" :Tooltip="Translate('View')" :ID="'Expand'"></CookiesIcon>
                <!-- <CookiesIcon style="width: 1.4em; height: 1.4em;" class="W-100 H-100 HoverUp Pointer P_5em" :Tooltip="'ssss'" :ID="'Delete'"></CookiesIcon> -->
              </div>
              <div class="CookiesRowSpacer"></div>
              <div class="D-Flex"><CookiesIcon class="SQR1_5em F1_75em" :ID="'ToBeSetteled'" style="filter: saturate(0);"></CookiesIcon><span class="P_5em"></span><strong class="F1_75em">{{DeepFetcher(Checkpoint, 'TotalAmount')}} {{PriceUnit}}</strong></div>
              <div class="CookiesRowSplitter"></div>
              <div class="D-Flex"><CookiesIcon class="SQR1_5em F1_25em" :ID="'Location'" style="filter: saturate(0);"></CookiesIcon><span class="P_5em"></span><span class="F-Bold">{{DeepFetcher(Checkpoint, 'BranchName')}}</span></div>
              <div class="CookiesRowSplitter"></div>
              <div class="D-Flex"><span class="P_5em"></span><span class="F-Bold">{{DeepFetcher(Checkpoint, 'Status')}}</span></div>
              <div class="CookiesRowSplitter"></div>
              <div class="D-Flex"><span class="P_5em"></span><span class="F-Bold">{{Translate('Products')}}</span></div>
              <div class="D-Flex F_75em">
                <CookiesTable class="Shadow0 TimelineTable" :Header="false"
                :Fields="[
                  { name: Translate('ID'), value: ['ProductID'], id: true },
                  { name: Translate('Name'), value: ['Category', 'Name'] },
                  { name: Translate('Type'), value: 'Name' },
                  { name: Translate('Code'), value: 'Code' },
                  { name: Translate('Price'), value: 'Price', pricetag: true},
                  { name: Translate('Quantity'), value: 'Quantity'},
                  { name: Translate('Discount'), value: 'Discount', unit: '%'}
                ]"
                :PrintControls="false" :Footer="'PrintControls'" :Data="ItemsFixer(DeepFetcher(Checkpoint, ['CustomerCart', 'Item']))"></CookiesTable>
              </div>
              <div class="CookiesRowSplitter"></div>
              <div class="D-Flex P_75em F_85em"><span class="P_5em"></span><span class="">{{DeepFetcher(Checkpoint, 'Note')}}</span></div>
              <div class="CookiesRowSplitter"></div>
            </div>
          </template>
          <!-- ------------------------------------------------------------------------------------------------ -->
          <!-- -------------------------------------Visit Checkpoint------------------------------------------- -->
          <template v-if="DeepFetcher(Checkpoint, 'CheckpointType') === 'visit'">
            <div class="CheckpointHeader">
              <div class="F-Bold F1_25em">{{JsonDateParser(DeepFetcher(Checkpoint, 'Date'))}}</div>
              <div>{{JsonTimeParser(DeepFetcher(Checkpoint, 'Date'))}}</div>
            </div>
            <div class="" style="width: 0.5em;">
              <div class="P-Rel Shadow1 D-Flex BG2 CheckpointIcon" style="border: solid 0.3em rgb(41, 161, 141);">
                <CookiesIcon class="M-Auto" style="width: 80%; height: 80%;" :Color="{ColorA: 'rgb(41, 161, 141)', ColorB: 'rgb(32, 86, 76)'}"  :ID="'Location'"></CookiesIcon>
              </div>
              <div class="H-100 Rad3" style="background-color: rgb(41, 161, 141);"></div>
            </div>
            <div class="CheckpointDetails P-Rel">
              <div class="P-Abs BG2 Rad1 D-Flex" style="inset-inline-end: 0; top: 0;">
                <div class="M-Auto" style="margin-inline-start: 2em;">{{Translate('Visit')}}</div>
                <!-- ====== Buttons ====== -->
                <CookiesIcon style="width: 1.4em; height: 1.4em;" class="W-100 H-100 HoverUp Pointer P_5em" :Tooltip="Translate('Create Sale')" :ID="'Cart'"></CookiesIcon>
                <CookiesIcon style="width: 1.4em; height: 1.4em;" class="W-100 H-100 HoverUp Pointer P_5em" :Tooltip="Translate('View')" :ID="'Expand'"></CookiesIcon>
                <!-- <CookiesIcon style="width: 1.4em; height: 1.4em;" class="W-100 H-100 HoverUp Pointer P_5em" :Tooltip="'ssss'" :ID="'Delete'"></CookiesIcon> -->
              </div>
              <div class="CookiesRowSpacer"></div>
              <div class="D-Flex"><CookiesIcon class="SQR1_5em F1_75em" :ID="'Location'" style="filter: saturate(0);"></CookiesIcon><span class="P_5em"></span><strong class="F1_75em">{{DeepFetcher(Checkpoint, 'BranchName')}}</strong></div>
              <div class="CookiesRowSplitter"></div>
              <div class="D-Flex"><CookiesIcon class="SQR1em F1_25em" :ID="'Info'" style="filter: saturate(0); margin-top: auto; margin-bottom: auto; margin-left: 0.5em; margin-right: 0.5em;"></CookiesIcon><span class="P_5em"></span><span class="F-Bold">{{DeepFetcher(Checkpoint, ['Reason', 'Value'])}}</span></div>
              <div class="CookiesRowSplitter"></div>
              <div class="D-Flex"><span class="P_5em"></span><span class="F-Bold">{{Translate('Interested In')}}</span></div>
              <div class="D-Flex F_75em">
                <CookiesTable class="Shadow0 TimelineTable" :Header="false"
                :Fields="[
                  { name: Translate('ID'), value: ['ProductID'], id: true },
                  { name: Translate('Name'), value: ['Category', 'Name'] },
                  { name: Translate('Type'), value: 'Name' },
                  { name: Translate('Code'), value: 'Code' },
                  { name: Translate('Price'), value: 'Price', pricetag: true},
                  { name: Translate('Quantity'), value: 'Quantity'},
                  { name: Translate('Discount'), value: 'Discount', unit: '%'}
                ]"
                :PrintControls="false" :Footer="'PrintControls'" :Data="ItemsFixer(DeepFetcher(Checkpoint, ['VisitCart']))"></CookiesTable>
              </div>
              <div class="CookiesRowSplitter"></div>
              <div class="D-Flex P_75em F_85em"><span class="P_5em"></span><span class="">{{DeepFetcher(Checkpoint, 'Note')}}</span></div>
              <div class="CookiesRowSplitter"></div>
            </div>
          </template>
          <!-- ------------------------------------------------------------------------------------------------ -->
          <!-- --------------------------------------Case Checkpoint------------------------------------------- -->
          <template v-if="DeepFetcher(Checkpoint, 'CheckpointType') === 'case'">
            <div class="CheckpointHeader">
              <div class="F-Bold F1_25em">{{JsonDateParser(DeepFetcher(Checkpoint, 'Date'))}}</div>
              <div>{{JsonTimeParser(DeepFetcher(Checkpoint, 'Date'))}}</div>
            </div>
            <div class="" style="width: 0.5em;">
              <div class="P-Rel Shadow1 D-Flex BG2 CheckpointIcon" style="border: solid 0.3em rgb(207, 57, 57);">
                <CookiesIcon class="M-Auto" style="width: 80%; height: 80%;" :ID="'VeryAngry'"></CookiesIcon>
              </div>
              <div class="H-100 Rad3" style="background-color: rgb(207, 57, 57);"></div>
            </div>
            <div class="CheckpointDetails P-Rel">
              <div class="P-Abs BG2 Rad1 D-Flex" style="inset-inline-end: 0; top: 0;">
                <div class="M-Auto" style="margin-inline-start: 2em;">{{Translate('Complaint')}}</div>
                <!-- ====== Buttons ====== -->
                <CookiesIcon style="width: 1.4em; height: 1.4em;" class="W-100 H-100 HoverUp Pointer P_5em" :Tooltip="Translate('View')" :ID="'Expand'"></CookiesIcon>
                <!-- <CookiesIcon style="width: 1.4em; height: 1.4em;" class="W-100 H-100 HoverUp Pointer P_5em" :Tooltip="'ssss'" :ID="'Delete'"></CookiesIcon> -->
              </div>
              <div class="CookiesRowSpacer"></div>
              <div class="D-Flex"><CookiesIcon class="SQR1_5em" :ID="'Cart'" style="filter: saturate(0);"></CookiesIcon><span class="P_5em"></span><strong class="">#{{DeepFetcher(Checkpoint, 'RefID')}}</strong></div>
              <div class="CookiesRowSplitter"></div>
              <div class="D-Flex"><CookiesIcon class="SQR1_5em" :ID="'Location'" style="filter: saturate(0);"></CookiesIcon><span class="P_5em"></span><strong class="">{{DeepFetcher(Checkpoint, 'BranchName')}}</strong></div>
              <div class="CookiesRowSplitter"></div>
              <div class="D-Flex"><CookiesIcon class="SQR1_5em F2em Shadow0" :ID="DeepFetcher(Checkpoint, ['ClientMood'])" style=""></CookiesIcon><span class="P_5em"></span><span class="F-Bold F1_5em">{{DeepFetcher(Checkpoint, ['CaseDescription'])}}</span></div>
              <div class="CookiesRowSplitter"></div>
            </div>
          </template>
          <!-- ------------------------------------------------------------------------------------------------ -->
          <!-- -----------------------------------Feed Back Checkpoint----------------------------------------- -->
          <template v-if="DeepFetcher(Checkpoint, 'CheckpointType') === 'feedback'">
            <div class="CheckpointHeader">
              <div class="F-Bold F1_25em">{{JsonDateParser(DeepFetcher(Checkpoint, 'Date'))}}</div>
              <div>{{JsonTimeParser(DeepFetcher(Checkpoint, 'Date'))}}</div>
            </div>
            <div class="" style="width: 0.5em;">
              <div class="P-Rel Shadow1 D-Flex BG2 CheckpointIcon" style="border: solid 0.3em rgb(207, 155, 57);">
                <CookiesIcon class="M-Auto" style="width: 80%; height: 80%;" :ID="'Star'"></CookiesIcon>
              </div>
              <div class="H-100 Rad3" style="background-color: rgb(207, 155, 57);"></div>
            </div>
            <div class="CheckpointDetails P-Rel">
              <div class="P-Abs BG2 Rad1 D-Flex" style="inset-inline-end: 0; top: 0;">
                <div class="M-Auto" style="margin-inline-start: 2em;">{{Translate('Feedback')}}</div>
                <!-- ====== Buttons ====== -->
                <CookiesIcon style="width: 1.4em; height: 1.4em;" class="W-100 H-100 HoverUp Pointer P_5em" :Tooltip="Translate('View')" :ID="'Expand'"></CookiesIcon>
                <!-- <CookiesIcon style="width: 1.4em; height: 1.4em;" class="W-100 H-100 HoverUp Pointer P_5em" :Tooltip="'ssss'" :ID="'Delete'"></CookiesIcon> -->
              </div>
              <div class="CookiesRowSpacer"></div>
              <div class="D-Flex F-Bold" style="">
                <div class="D-Flex BGShade1 Rad3" style="padding: 0.3em 0.8em; border-radius: 20em;">
                  <template v-for="Star in [1, 2, 3, 4, 5]" :key="(Star)">
                    <CookiesIcon class="SQR2em" :ID="Star > DeepFetcher(Checkpoint, 'Rate') ? 'EmptyStar': 'Star'"></CookiesIcon>
                  </template>
                </div>
              </div>
              <div class="CookiesRowSpacer"></div>
              <div class="D-Flex"><CookiesIcon class="SQR1_5em" :ID="'Cart'" style="filter: saturate(0);"></CookiesIcon><span class="P_5em"></span><strong class="">#{{DeepFetcher(Checkpoint, 'SaleID')}}</strong></div>
              <div class="CookiesRowSplitter"></div>
              <div class="D-Flex"><CookiesIcon class="SQR1_5em" :ID="'Location'" style="filter: saturate(0);"></CookiesIcon><span class="P_5em"></span><strong class="">{{DeepFetcher(Checkpoint, 'BranchName')}}</strong></div>
              <div class="CookiesRowSplitter"></div>
              <div class="D-Flex"><span class="P_5em"></span><span class="F-Bold">{{DeepFetcher(Checkpoint, ['FeedBack'])}}</span></div>
              <div class="CookiesRowSplitter"></div>
            </div>
          </template>
        </div>
        <div class="D-Flex" style="margin-bottom: 4em;">
          <div class="CheckpointHeader">
          </div>
          <div class="" style="width: 0.5em;">
            <div class="P-Rel Shadow1 D-Flex BGShade1 CheckpointIcon" style="margin-inline-start: -0.75em;">
            </div>
          </div>
          <div class="CheckpointDetails P-Rel">
            <div class="P-Abs Rad1 D-Flex" style="inset-inline-end: 0; top: 0; background: none;">
              <div class="M-Auto" style="margin-inline-start: 2em;">{{CustomerActions.length > 0 ? Translate('Start'): LoadingActions ? Translate('Loading') + '...': Translate('No Customer Actions')}}</div>
              <!-- ====== Buttons ====== -->
              <div style="width: 1.4em; height: 1.4em;" class="W-100 H-100 P_5em"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  beforeCreate () {
    this.$store.state.CookiesMemory.NewEmpGender = {}
  },
  created () {},
  data () {
    return {
      Editing: false,
      UserData: {},
      Name: '',
      PhoneNumber: '',
      AddressL1: '',
      Email: '',
      Password: '',
      ConfirmPassword: '',
      ErrorsVisibilty: false,
      CustomerActions: [],
      Saving: false,
      OldAccount: false,
      Loading: [],
      LoadingActions: [],
      UnitsCatolgsCount: 1
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    this.UserData = this.DeepFetcher(this.DialogData, 'Data')
    this.ReadCustomerActions(this.DeepFetcher(this.UserData, 'ID'))
    if (!this.Access('CustomerView')) {
      return
    }
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  methods: {
    ReadCustomerActions (CustomerID) {
      this.LoadingActions.push('Loading') // Add Loading flag
      this.Get(['ReadCustomerActions', '?CustomerID=' + CustomerID]).then(response => {
        this.Console(response)
        var Sales = []
        var Visits = []
        var Cases = []
        var Feedbacks = []
        var AllCheckPoints = []
        try { // Adding Checkpoints to sales
          Sales = this.DeepFetcher(response, 'Sales').map(sale => {
            sale.CheckpointType = 'sale'
            return sale
          })
        } catch (error) {
        }
        this.Console(Sales)
        try { // Adding Checkpoints to visits
          Visits = this.DeepFetcher(response, 'Visits').map(visit => {
            visit.CheckpointType = 'visit'
            return visit
          })
        } catch (error) {
        }
        try { // Adding Checkpoints to CustomerCases
          Cases = this.DeepFetcher(response, 'Cases').map(CustomerCase => {
            CustomerCase.CheckpointType = 'case'
            return CustomerCase
          })
        } catch (error) {
        }
        try { // Adding Checkpoints to feedbacks
          Feedbacks = this.DeepFetcher(response, 'Feedbacks').map(feedback => {
            feedback.CheckpointType = 'feedback'
            return feedback
          })
        } catch (error) {
        }
        this.Console(Feedbacks)
        AllCheckPoints = AllCheckPoints.concat(Sales)
        AllCheckPoints = AllCheckPoints.concat(Visits)
        AllCheckPoints = AllCheckPoints.concat(Cases)
        AllCheckPoints = AllCheckPoints.concat(Feedbacks)
        this.CustomerActions = this.GeneralSort(AllCheckPoints, 'Date', -1)
        this.LoadingActions.pop() // Remove Loading flag
      }, error => {
        this.LoadingActions.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Customers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ItemsFixer (CartItems) {
      var ModifiedItems = []
      CartItems.forEach(item => {
        var x = this.DeepCloner(item)
        x.Discount = x.Discount * 100
        ModifiedItems.push(x)
      })
      return ModifiedItems
    },
    EditCustomer () {
      this.OpenDialog('CRM_Customer_Dialog', this.Translate('Edit Customer') + ' : ' + this.UserData.Name, { Data: this.UserData, Editing: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    NewVisit () {
      this.OpenDialog('CRM_Customer_Visit_Dialog', this.Translate('New Visit'), {}) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  computed: {},
  watch: {
    Loading: {
      handler: function (val, oldVal) {
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 900px) {
  .DialogBody{
    max-width: 80vw;
  }
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 2em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
  .TimelineTable{
    font-size: 1.7vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
  .CookiesTimeline{
    font-size: 2.5vw;
  }
}
.CheckpointHeader{
  width: 20%;
  text-align: end;
  padding-inline-end: 2em;
}
.CheckpointIcon{
  width: 2em;
  height: 2em;
  border-radius: 100em;
  margin-inline-start: -1.05em;
  margin-bottom: -0.3em;
  z-index: 10;
}
.CheckpointDetails{
  width: 80%;
  text-align: start;
  padding-inline-start: 12%;
  padding-top: 2em;
}
.CheckpointDetails>div:first-child{
  background: v-bind('Theme.BG4');
  justify-content: end;
  width: 100%;
}
</style>
