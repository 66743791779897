
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

import { DoughnutChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import stringSimilarity from 'string-similarity'
import chroma from 'chroma-js'

Chart.register(...registerables)

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Type: { type: String, default: '' },
    Similarity: { type: Number, default: 1 },
    GroupBy: { type: String, default: '' },
    OnClickDialog: { default: () => { return undefined }, type: String as PropType<CookiesDialog> },
    CustomLabel: { default: () => { return undefined }, type: String as PropType<ObjectPath> },
    OrderBy: { default: 'Volume', type: String },
    CustomQuery: { default: undefined, type: String },
    Unit: { default: () => { return 'Volume' }, type: String as PropType<'Quantity' | 'Volume'> },
    Legend: { type: Boolean, default: true },
    CustomData: []
  },
  name: 'DonutCard',
  components: { DoughnutChart },
  setup () {},
  data () {
    return {
      ID: Date.now(),
      CookiesData: [] as any,
      InventoryItems: [] as any,
      LabelsPathCashed: ['Category, Name'] as ObjectPath,
      DataPathCashed: ['Volume'] as ObjectPath,
      MyChart: {} as any,
      MyLegend: [] as Array<any>,
      CurrentHover: null as null | number,
      Loading: false,
      LastHoverTime: new Date()
    }
  },
  methods: {
    LegendClicked (i :number) {
      if (this.GroupBy === 'Transfer.CustomerName' && this.Type === 'Export') this.OpenDialog('Inventory_CustomerTransfersPrint_Dialog', this.Translate('Info'), { Data: this.SimilarityFixed[i], Similarity: this.Similarity })
      if (!this.OnClickDialog) return
      this.OpenDialog(this.OnClickDialog, this.Translate('Info'), this.SimilarityFixed[i])
    },
    Generated (event :any) {
      this.MyChart = event
      this.MyLegend = event.legend.legendItems
      const canvas = this.MyChart.canvas as HTMLCanvasElement
      const canvasParent = canvas.parentElement
      const exist = canvasParent?.querySelector('.DonutInnerShadower')
      if (!exist) {
        var element = document.createElement('div')
        element.classList.add('DonutInnerShadower')
        element.innerHTML = '<div><div></div></div>'
        if (canvasParent) canvasParent.appendChild(element)
      }
    },
    onHover (itemIndex :number) {
      this.LastHoverTime = new Date()
      if (itemIndex === this.CurrentHover) return
      this.CurrentHover = itemIndex
      // var Color = ''
      // this.MyChart.data.datasets[0].backgroundColor.forEach((color:any, index:number, colors:any) => {
      //   Color = index === itemIndex ? color : Color
      //   colors[index] = index === itemIndex || color.length === 9 ? color : color + '1D'
      // })
      this.DrawInnerPieLabel(itemIndex, this.ComputedColoers[itemIndex])
      // this.$refs.myChart.update()
    },
    onLeave () {
      const _this = this
      var DateNow = new Date()
      setTimeout(() => {
        Left(DateNow)
      }, 100)
      function Left (date :Date) {
        if (date > _this.LastHoverTime) {
          _this.CurrentHover = null
          _this.RemoveInnerLabel()
        }
      }
      // this.CurrentHover = null
      // this.MyChart.data.datasets[0].backgroundColor.forEach((color:any, index:number, colors:any) => {
      //   colors[index] = color.length === 9 ? color.slice(0, -2) : color
      // })
      // this.RemoveInnerLabel()
      // this.$refs.myChart.update()
    },
    DrawInnerPieLabel (index :number, color :string) {
      const value = this.Series[index]
      const canvas = this.MyChart.canvas as HTMLCanvasElement
      var SpecsString = ''
      var Label = this.DeepFetcher(this.SimilarityFixed[index], this.LabelsPathCashed)
      if (this.LabelsPathCashed === 'TransferItemItemID') {
        try {
          Label = this.DeepFetcher(this.FilteredData[index], 'ItemCode')
          var specs = this.InventoryItems.find((value :any) => { return value.ID === this.FilteredData[index].TransferItemItemID }).Specifications
          SpecsString = specs.reduce((previousValue :any, currentValue :any, currentIndex :number) => {
            return previousValue + (currentIndex ? ' . ' : '') + currentValue.Description
          }, '')
        } catch (error) {
        }
      }
      var InnerHtml =
      `
      <div style="max-width:${0.7 * canvas.width}px">
        <div class="D-Flex F-Bold O-75 " style="width: 70%; height: 2.2em; align-items: center; justify-content: center;">${Label}</div>
        <div class="F-Bold O-50 F_85em">${SpecsString}</div>
        <div class="LabelSplitter"></div>
        <div><span class="F1_75em F-Bold">${this.DecimalFixer(value, 1)}</span></div>
        <div><span class="F_75em">${this.ComputedUnit}</span></div>
        <div class="LabelSplitter"></div>
        <div class="F-Bold F1_25em Shadow3" style="color:${color}">${this.DecimalFixer(value / this.DataTotal * 100, 1)}%</div>
      </div>
      `
      const canvasParent = canvas.parentElement
      const exist = canvasParent?.querySelector('.DonutInnerLabel')
      if (exist) {
        exist.innerHTML = InnerHtml
      } else {
        var element = document.createElement('div')
        element.classList.add('DonutInnerLabel')
        element.innerHTML = InnerHtml
        if (canvasParent) canvasParent.appendChild(element)
      }
    },
    RemoveInnerLabel () {
      const canvas = this.MyChart.canvas as HTMLCanvasElement
      const canvasParent = canvas.parentElement
      const exist = canvasParent?.querySelector('.DonutInnerLabel')
      if (exist) {
        exist.innerHTML = ''
        // `
        // <div>${this.Translate('Total')}</div>
        // <div class="F1_25em F-Bold" ></div>
        // `
      }
    },
    ReadCookiesData () {
      this.Loading = true
      if (this.CustomData) {
        this.CookiesData = this.CustomData
        return
      }
      this.Get('DashBoard', `?Type=${this.Type}&DashBoardDataGroup=${this.GroupBy}&DashBoardDataOrder=${this.OrderBy}${(this.MEMRead('CurrentWarehouse') ? '&WarehouseID=' + this.MEMRead('CurrentWarehouse').IDs : '')}&${this.DateAPIURL()}${this.CustomQuery ? '&' + this.CustomQuery : ''}`).then(response => {
        this.CookiesData = response
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Items'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadItems () {
      this.Get('Inventory_Items', (this.MEMRead('CurrentWarehouse') ? '?WarehouseID=' + this.MEMRead('CurrentWarehouse').IDs : '')).then(response => {
        this.InventoryItems = response
      }, error => {
        error.CookiesError = 'Error in reading Items'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  mounted () {
    this.LabelsPathCashed = this.GroupBy.replaceAll('.', '')
    this.DataPathCashed = this.Unit
    this.ReadCookiesData()
    this.ReadItems()
  },
  computed: {
    ComputedColoers () {
      var Colors:Array<any> = []
      // var ColorsPallete = this.ColorsGenerator(this.Series.length)
      var ColorsPallete = this.$store.state.CookiesChart.colors
      var ColorsLength = ColorsPallete.length - 1
      this.Series.forEach((element :any, index :number) => {
        Colors.push(chroma(ColorsPallete[index - (Math.floor(index / ColorsLength) * ColorsLength)]) + ((this.CurrentHover || this.CurrentHover === 0) && this.CurrentHover !== index ? '1D' : ''))
      })
      return Colors
    },
    ChartData () {
      var Colors:Array<any> = this.ComputedColoers
      return {
        labels: this.Labels,
        datasets: [
          {
            data: this.Series,
            borderWidth: 0.3,
            borderColor: this.Theme.BG1,
            backgroundColor: Colors
          }
        ]
      }
    },
    ChartOptions () {
      const _this = this
      return {
        responsive: true,
        // resizeDelay: 100,
        cutout: '70%',
        circumference: 360,
        // hoverBackgroundColor (item :any, chart :any) {
        //   _this.onHover(item)
        //   // var myColor = item.element.options.backgroundColor
        //   // return (myColor && typeof myColor === 'string' && myColor.length >= 7) ? (myColor.slice(0, 7) + '88') : myColor
        // },
        animations: {
          colors: false
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            callbacks: {
              footer (tooltipItems :any) {
                let sum = 0
                tooltipItems.forEach(function (tooltipItem :any) {
                  sum += tooltipItem.y
                })
                return 'Sum: ' + sum
              }
            }
          }
        },
        onHover (e :any) {
          _this.onLeave()
          const myChart :any = this
          const points = myChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
          if (points.length && myChart.data) {
            const firstPoint = points[0]
            _this.onHover(firstPoint.index)
            // _this.DrawInnerPieLabel(firstPoint.index, color)
          }
        },
        onClick (e :any) {
          const myChart :any = this
          const points = myChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
          if (points.length && myChart.data) {
            const firstPoint = points[0]
            // const MyData :any = myChart.data
            _this.LegendClicked(firstPoint.index)
          }
        }
      }
    },
    DataTotal () {
      var DataTotal = 0
      this.Series.forEach((element :any) => {
        DataTotal = DataTotal + element
      })
      return DataTotal
    },
    Series () {
      try {
        return this.SimilarityFixed.map((element :any) => {
          return this.DeepFetcher(element, this.DataPathCashed)
        })
      } catch (error) {
        return []
      }
    },
    Labels () {
      try {
        return this.SimilarityFixed.map((element :any) => {
          if (this.LabelsPathCashed === 'TransferItemItemID') {
            var SpecsString = ''
            SpecsString = ' - ' + element.CategoryName
            return this.DeepFetcher(element, 'ItemCode') + SpecsString
          }
          return this.DeepFetcher(element, this.LabelsPathCashed)
        })
      } catch (error) {
        return []
      }
    },
    ComputedUnit () {
      switch (this.DataPathCashed) {
        case 'Volume':
          return this.VolumeUnit
        default:
          return ''
      }
    },
    SimilarityFixed () {
      if (this.Similarity === 1) {
        return this.FilteredData
      }
      var SimilarArray :Array<any> = []
      this.FilteredData.forEach((element :any) => {
        var found = SimilarArray.find((oldElement :any) => {
          if (!(this.DeepFetcher(element, this.LabelsPathCashed)) || !(this.DeepFetcher(oldElement, this.LabelsPathCashed))) return false
          return stringSimilarity.compareTwoStrings(this.DeepFetcher(element, this.LabelsPathCashed), this.DeepFetcher(oldElement, this.LabelsPathCashed)) > this.Similarity
        })
        if (found) {
          this.DeepChanger(found, 'Quantity', this.DeepFetcher(found, 'Quantity') + this.DeepFetcher(element, 'Quantity'))
          this.DeepChanger(found, 'Volume', this.DeepFetcher(found, 'Volume') + this.DeepFetcher(element, 'Volume'))
        } else {
          SimilarArray.push(element)
        }
      })
      return SimilarArray
    },
    FilteredData () {
      var Search = this.MEMRead('CookiesDonut_' + this.ID + '_Search')
      if (Search) {
        return this.CookiesData.filter((value :any) => {
          return this.CookiesSearcher(value, Search)
        })
      } else {
        return this.CookiesData
      }
    }
  },
  watch: {
    Type: function (newVal, oldVal) { // watch it
      if (newVal !== oldVal) {
        this.ReadCookiesData()
      }
    },
    GroupBy: function (newVal, oldVal) { // watch it
      if (newVal !== oldVal) {
        this.LabelsPathCashed = this.GroupBy.replaceAll('.', '')
        this.ReadCookiesData()
      }
    },
    OrderBy: function (newVal, oldVal) { // watch it
      if (newVal !== oldVal) {
        this.ReadCookiesData()
      }
    },
    Unit: function (newVal, oldVal) { // watch it
      if (newVal !== oldVal) {
        this.DataPathCashed = this.Unit
        this.ReadCookiesData()
      }
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadCookiesData()
    },
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "bce9fee2": (_ctx.$store.getters.Direction),
  "86834a46": (_ctx.Theme.color4)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__