
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: false,
      selectedFile: null,
      Loading: [] as Array<'Loading'>,
      Profiles: [],
      ErrorsVisibilty: false,
      DialogMapper: [
        { Path: 'Name', Res: 'D_Purchase_Vendor_Name' },
        { Path: 'PhoneNumber', Res: 'D_Purchase_Vendor_PhoneNumber' },
        { Path: 'Description', Res: 'D_Purchase_Vendor_Description' },
        { Path: 'SerialNO', Value: '', Default: '' },
        { Path: 'Type', Value: 'v' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'CRM_Customers',
        OriginalData: this.OriginalData,
        PatchAPI: 'CRM_Customers',
        Editing: this.DialogData?.Editing,
        PatchQuery: this.DialogData?.Editing ? '/' + this.DialogData?.Data.ID : ''
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    LogoChanged (event :any) {
      var file = event.target.files[0]
      this.selectedFile = file
      if (!file) {
        return
      }
      this.PostImage(this.selectedFile).then((response :any) => {
        this.MEMWrite('D_Purchase_Vendor_Logo', response)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Uploading Image'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  created () {
    if (this.DialogData?.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
    }
  },
  mounted () {
    if (this.DialogData?.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
      if (this.DialogData?.Editing && this.DialogData?.Data && this.DialogData?.Data.IsCustomIdentity) {
        this.MEMWrite('D_Purchase_Vendor_CustomIdentity', true)
      }
    }
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  }
})
