
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  data () {
    return {
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { type: Object as PropType<any> }
  },
  mounted () {
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  methods: {
    Confirm () {
      var InvoiceNO = this.MEMRead('D_Inventory_Transfer_InvoiceNO')
      this.Post('PATCH', 'Inventory_Transfers_InvoiceNO', { InvoiceNO: InvoiceNO, TransferID: this.DialogData?.Data }, `/${this.DialogData?.Data}`).then(response => {
        this.Saving = false
        this.$router.go(-1)
      }, error => {
        this.Saving = false
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  }
})
