const routes :Array<CookiesRouter> = [
  {
    path: '/Inventory',
    name: 'Inventory',
    meta: {
      Access: 'Inventory_View' as AccessFeature
    },
    component: () => import('@/Apps/Inventory/views/Inventory.vue'),
    children: [
      {
        path: '/Inventory/Warehouses',
        name: 'Warehouses',
        component: () => import('@/Apps/Inventory/views/Warehouses.vue'),
        meta: {
          Access: 'Inventory_Warehouses_Read' as AccessFeature
        }
      },
      {
        path: '/Inventory/Items',
        name: 'Items',
        component: () => import('@/Apps/Inventory/views/Items.vue'),
        meta: {
          Access: 'Inventory_Items_Read' as AccessFeature
        }
      },
      {
        path: '/Inventory/Exports',
        name: 'Exports',
        component: () => import('@/Apps/Inventory/views/Exports.vue'),
        meta: {
          Access: 'Inventory_Transfers_Read' as AccessFeature
        }
      },
      {
        path: '/Inventory/Imports',
        name: 'Imports',
        component: () => import('@/Apps/Inventory/views/Imports.vue'),
        meta: {
          Access: 'Inventory_Transfers_Read' as AccessFeature
        }
      },
      {
        path: '/Inventory/InternalTransfers',
        name: 'InternalTransfers',
        component: () => import('@/Apps/Inventory/views/InternalTransfers.vue'),
        meta: {
          Access: 'Inventory_Transfers_Read' as AccessFeature
        }
      }
    ]
  }
  // ==============================================================================
  // ====================
]

export const InventoryRoutes = routes
