
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  data () {
    return {
      DateNow: new Date()
    }
  },
  mounted () {
    this.UpdateTime()
  },
  props: {
    Items: { type: Object as PropType<NavItem[]> },
    IconsSheet: { default: '' }
  },
  methods: {
    LogOut () {
      this.OpenDialog('Security_Logout_Dialog', this.Translate('Log Out'), {}) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ChangeBranch () {
      this.OpenDialog('Inventory_Branch_Selector', 'Select Branch', {})
    },
    ChangeCompany () {
      this.OpenDialog('Inventory_Company_Selector', 'Select Company', {})
    },
    ChangeWarehouse () {
      this.OpenDialog('Inventory_Warehouse_Selector', 'Select Branch', {})
    },
    LogIN () {
      this.$store.state.AutoSignIn = false
      this.$store.state.Accessable = false
    },
    ChangeLanguage () {
      this.OpenDialog('ChangeLanguage', this.Translate('Select Language'), {})
    },
    UpdateTime () {
      this.DateNow = new Date()
      setTimeout(() => {
        this.UpdateTime()
      }, 10000)
    }
  },
  computed: {
    CurrentPage () {
      return this.$route.matched[0]?.name
    },
    Icons () {
      return this.IconsSheet === '' ? require('@/assets/icons/ThemeIcons.svg') : this.IconsSheet
    },
    Maintenance () {
      return this.$store.state.CookiesConfig.MaintenanceMode === 'true'
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "059fa1fa": (_ctx.Theme.BackBlur),
  "2f110e79": (_ctx.Theme.BG3),
  "60832fe3": (_ctx.Theme.UserColors.main_50),
  "6fc62b0a": (_ctx.Theme.UserColors.fourth_25),
  "50cd09fb": (_ctx.Theme.Shade3),
  "2f110e78": (_ctx.Theme.BG2),
  "54a7a3f7": (_ctx.Theme.UserColors.main),
  "483933dc": (_ctx.Theme.BG2Fixed),
  "2f110e7c": (_ctx.Theme.BG6),
  "6fc62b62": (_ctx.Theme.UserColors.fourth_50)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__