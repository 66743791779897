import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// //////////////////////////////////////////////////////////////
import store from '@/store'
import { FinanceRoutes } from '@/Apps/Finance/router/index'
import { HRRoutes } from '@/Apps/HR/router/index'
import { CRMRoutes } from '@/Apps/CRM/router/index'
import { SalesRoutes } from '@/Apps/Sales/router/index'
import { PurchaseRoutes } from '@/Apps/Purchase/router/index'
import { SecurityRoutes } from '@/Apps/Security/router/index'
import { CatalogsRoutes } from '@/Apps/Catalogs/router'
import { InventoryRoutes } from '@/Apps/Inventory/router'
import { DashBoardRoutes } from '@/Apps/DashBoard/router'
import { BranchesRoutes } from '@/Apps/Branches/router'
import { ReportsRoutes } from '@/Apps/Reports/router'

const DefaultTitle = 'Cookies Core'

const Generalroutes :Array<CookiesRouter> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HelloFromCookies.vue'),
    meta: {
      Access: 'Main_Navigation',
      LoginHeader: true
    }
  },
  {
    path: '/Settings',
    name: 'Settings',
    redirect: '/Settings/System',
    component: () => import('@/views/Settings.vue'),
    meta: {
      Access: 'Main_Settings_View'
    },
    children: [
      {
        path: 'System',
        component: () => import('@/components/Settings/SystemConfig.vue'),
        meta: {
          Access: 'Main_Settings_System'
        }
      },
      {
        path: 'Account',
        component: () => import('@/components/Settings/Account.vue'),
        meta: {
          Access: 'Security_Public'
        }
      },
      {
        path: 'CompanyInfo',
        component: () => import('@/components/Settings/CompanyInfo.vue'),
        meta: {
          Access: 'Main_Settings_Company'
        }
      },
      {
        path: 'Integration',
        component: () => import('@/components/Settings/Integration.vue'),
        meta: {
          Access: 'Main_Settings_Integration'
        }
      },
      {
        path: 'SuperAdmin',
        component: () => import('@/components/Settings/SuperAdmin.vue'),
        meta: {
          Access: 'SuperAdmin'
        }
      }
    ]
  },
  {
    path: '/GetMyLocation',
    component: () => import('@/components/GetMyLocation.vue'),
    meta: {
      Access: 'Main_Public',
      NoHeader: true
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
    meta: {
      Access: 'Main_Public'
    }
  }
]

var routes :Array<RouteRecordRaw> = []

// routes = routes.concat(OldRoutes)
routes = routes.concat(HRRoutes)
routes = routes.concat(CRMRoutes)
routes = routes.concat(SalesRoutes)
routes = routes.concat(PurchaseRoutes)
routes = routes.concat(SecurityRoutes)
routes = routes.concat(InventoryRoutes)
routes = routes.concat(CatalogsRoutes)
routes = routes.concat(DashBoardRoutes)
routes = routes.concat(BranchesRoutes)
routes = routes.concat(ReportsRoutes)
routes = routes.concat(FinanceRoutes)
routes = routes.concat(Generalroutes)

const router = createRouter({
  history: createWebHistory(),
  // mode: 'history',
  routes
})
var FirstTime = true
router.beforeEach((to) => {
  // alert(to.meta.Access)
  if (FirstTime) {
    to.query = {}
    FirstTime = false
    return to
  }
  const storeData = store as any
  var RouteMetaAccess :string = to.meta.Access as string
  if (RouteMetaAccess.slice(-6) !== 'Public' as AccessFeature && storeData.state && storeData.state.UserManagment.Status && storeData.state.UserManagment.CurrentUser?.RoleNames?.indexOf('SuperAdmin') === -1 && storeData.state.UserManagment.CurrentUser?.RoleNames?.indexOf(to.meta.Access) === -1) {
    return false
  }
})

router.afterEach((to) => {
  document.title = to.name as string || DefaultTitle
  // console.log(to)
  // if (to.children && to.children.length === 1) {
  //   router.push(to.children[0])
  // }
})
export const GeneralRoutes = Generalroutes

export default router
