
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as InventoryItem,
      Saving: false,
      TableSaving: [] as Array<'Saving'>,
      Saved: undefined as boolean|undefined,
      Loading: [] as Array<'Loading'>,
      CookiesData: {} as any,
      Categories: [] as any,
      CookiesSpecs: [] as any,
      Brands: [] as any,
      ErrorsVisibilty: false,
      CurrentImage: undefined as File | undefined
    }
  },
  methods: {
    ReadDialogData () {
      // this.ReadItem()
      this.ReadCategories()
      this.ReadSpecs()
      this.ReadBrands()
    },
    ReadItem () {
      this.Loading.push('Loading')
      this.Get('Inventory_Items', this.OriginalData.ID.toString()).then(response => {
        this.CookiesData = response
        this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.CookiesData) as InventoryItem
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Catalog'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCategories () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Items').then(response => {
        this.Categories = response
        if (this.DialogData?.Data && this.DialogData?.Data.CategoryID) {
          var SelectedCategory = this.Categories.find((cat :any) => {
            return cat.ID === this.DialogData?.Data.CategoryID
          })
          if (SelectedCategory) {
            this.MEMWrite('D_Item_Category_Value', SelectedCategory)
            this.MEMWrite('D_Item_Category', SelectedCategory.Name)
          }
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBrands () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Brands').then(response => {
        this.Brands = response
        if (this.DialogData?.Data && this.DialogData?.Data.BrandID) {
          var SelectedBrand = this.Brands.find((brand :any) => {
            return brand.ID === this.DialogData?.Data.BrandID
          })
          if (SelectedBrand) {
            this.MEMWrite('D_Item_Brand_Value', SelectedBrand)
            this.MEMWrite('D_Item_Brand', SelectedBrand.Name)
          }
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    FieldEdited (item :any) {
      if (!this.DialogData?.Editing) return
      this.Saved = undefined
      this.TableSaving.push('Saving')
      var Original = this.CookiesSpecs.find((cat :Category) => {
        return cat.ID === item.ID
      })
      var Patch = this.CookiesPatcher(Original, item)
      this.Post('PATCH', 'Categories', Patch, '/' + item.ID).then((response) => {
        this.TableSaving.shift()
        this.Saved = true
        this.ReadSpecs()
      }, error => {
        this.TableSaving.shift() // Remove Loading flag
        this.Saved = false
        console.log(error)
      })
    },
    RemoveSpec (row:any) {
      this.CookiesSpecs.splice(row.OriginalIndex, 1)
    },
    ReadSpecs () {
      if (!this.DialogData?.Editing) return
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=ItemSpecs&RefID=' + this.DialogData?.Data.ID).then(response => {
        this.CookiesSpecs = response.map((element :any) => {
          element.TempID = element.ID
          return element
        })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    AddSpecs () {
      this.CookiesSpecs.push({
        Name: '',
        Description: '',
        TempID: 'TempID' + new Date().getTime()
      })
    },
    SaveData () {
      console.log(this.OriginalData)
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Inventory_Items',
        OriginalData: this.OriginalData,
        PatchAPI: 'Inventory_Items',
        Editing: this.DialogData?.Editing,
        PatchQuery: this.DialogData?.Editing ? '/' + this.DialogData?.Data.ID : ''
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    CropImage (file :File) {
      if (file) {
        if (this.MEMRead('D_Item_Code') === '' || this.MEMRead('D_Item_Code') === undefined) {
          this.MEMWrite('D_Item_Code', file.name.split('.')[0])
        }
        this.OpenDialog('CookiesImageCropper', '', { Data: { File: file, Res: 'D_Item_Image' } }, { header: false, background: 'transparent', FullWindow: true })
      }
    },
    EditCategories (Type: string, Name: string) {
      this.OpenDialog('Main_Categories_Dialog', this.Translate('Edit ' + Name), { Data: {}, Type: Type })
    }
  },
  created () {
    if (this.DialogData?.Data) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data) as InventoryItem
    }
  },
  mounted () {
    this.ReadDialogData()
    console.log(this.DialogData)
    if (this.DialogData?.Data) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data) as InventoryItem
      if (this.DialogData?.Data.IsNew) {
        this.MEMWrite('D_Item_Name', this.DialogData?.Data.Name)
        this.MEMWrite('D_Item_IsNew', true)
      }
      if (this.DialogData?.Data.IsEdited) {
        this.MEMWrite('D_Item_Name', this.DialogData?.Data.Name)
        this.MEMWrite('D_Item_IsEdited', true)
      }
      if (this.MEMRead('D_Item_Name') === '') {
        this.MEMWrite('D_Item_Name', this.DialogData?.Data.ResIndex)
      }
      if (this.DialogData?.Editing && this.DialogData?.Data && this.DialogData?.Data.IsUserSelect) {
        this.MEMWrite('D_Item_UserSelect', true)
      }
    }
    // if (this.DialogData?.Editing) {
    //   this.ReadDialogData()
    // }
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_Item')
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Categories') + this.DialogData?.Type ? ' ' + this.DialogData?.Type : '',
        CookiesID: 'D_Items_Specs',
        ResID: 'TempID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: this.Translate('Spec'),
            Type: 'normal',
            Value: 'Name',
            Sort: 'Name',
            Editable: true,
            Bold: true,
            Res_ID: 'TempID'
          },
          {
            Name: this.Translate('Value'),
            Editable: true,
            Type: 'normal',
            Value: 'Description',
            Sort: 'Description',
            Res_ID: 'TempID'
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      Table.Fields.push({
        Name: 'Delete',
        Value: '',
        Type: 'delete',
        NoHeader: true
      })
      return Table
    },
    DialogMapper () {
      var DM = [
        { Path: 'Name', Res: 'D_Item_Name', Default: '' },
        { Path: 'Code', Res: 'D_Item_Code' },
        { Path: 'Specifications', Res: 'D_Items_Specs' },
        { Path: 'PackSize', Res: 'D_Item_PackSize' },
        { Path: 'IsUserSelect', Res: 'D_Item_UserSelect', Default: false },
        { Path: 'ImgUrl', Res: 'D_Item_Image', IsImage: true, Default: '' },
        { Path: 'Description', Res: 'D_Item_Description', Default: '' },
        { Path: 'CategoryID', Res: 'D_Item_Category', ValuePath: 'ID' },
        { Path: 'BrandID', Res: 'D_Item_Brand', ValuePath: 'ID' },
        { Path: 'Price', Res: 'D_Item_Price', ValuePath: 'ID' },
        { Path: 'Volume', Res: 'D_Item_Volume', Default: 0 },
        { Path: 'QuantityMax', Res: 'D_Item_MaxQuantity', Default: 10 },
        { Path: 'QuantityMin', Res: 'D_Item_MinQuantity', Default: 0 }
      ]
      // if (!this.DialogData?.Editing) DM.push({ Path: 'Specifications', Res: 'D_Items_Specs' })
      return DM as Array<DialogMapperObject>
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadDialogData()
      }
    }
  }
})
