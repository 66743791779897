
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
import { PropType, defineComponent } from 'vue'

type CheckpointData = {
  Name?: string
  Value?: string
  Class?: string
  Style?: string
  Icon?: IconsIDs
  Border?: boolean
  ID?: boolean
}

type CheckPointButtons = {
  Tooltip?: string
  Link?: string
  Icon: IconsIDs
}

type Checkpoint = {
  PointTitle: string
  PointName?: string
  PointData?: CheckpointData[]
  Buttons?: CheckPointButtons[]
  Color?: string
  Date: string
  AddedDate: string
  Icon?: IconsIDs
}

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: false,
      selectedFile: null,
      Loading: [] as Array<'Loading'>,
      LoadingActions: [] as Array<'Loading'>,
      Profiles: [],
      VendorOrders: [] as any,
      VendorTransactions: [] as any,
      Vendor: {} as any,
      ErrorsVisibilty: false,
      Views: 5,
      DialogMapper: [
        { Path: 'Name', Res: 'D_CRM_Customers_Name' },
        { Path: 'PhoneNumber', Res: 'D_CRM_Customers_PhoneNumber' },
        { Path: 'Description', Res: 'D_CRM_Customers_Description' },
        { Path: 'SerialNO', Value: '', Default: '' },
        { Path: 'Type', Value: 'c' }
      ] as Array<DialogMapperObject>
    }
  },
  computed: {
    Total () {
      var Total = this.Vendor?.InitialBalance
      this.VendorOrders.forEach((value: any) => {
        Total += value.Price * value.Quantity + value.AddedCost + value.Discount
      })
      this.VendorTransactions.forEach((value: any) => {
        Total += value.paymentDiscountValue
      })
      return Total
    },
    Paid () {
      var Total = 0
      this.VendorTransactions.forEach((value: any) => {
        Total += typeof value.Value === 'string' ? parseFloat(value.Value) : value.Value
      })
      return Total
    },
    CustomerActions () :Checkpoint[] {
      var Orders: Checkpoint[] = this.VendorOrders.map((value: any) => {
        return {
          Icon: this.Vendor.Type === 'v' ? 'Cart' : 'OnDelivery',
          PointTitle: this.Vendor.Type === 'v' ? 'Purchase' : 'Sale',
          PointName: value.Item,
          PointData: [
            {
              Border: true,
              Value: value.ID,
              ID: true,
              Class: ''
            },
            ...value.ItemName ? [
              {
                Icon: 'AddItem',
                Border: true,
                Name: this.Translate('Item'),
                Value: value.ItemName,
                Class: ''
              }
            ] : [],
            {
              Icon: 'Packages',
              Border: true,
              Name: this.Translate('Quantity'),
              Value: value.Quantity,
              Class: ''
            },
            ...value.Price ? [
              {
                Icon: 'ToBeSetteled',
                Border: true,
                Name: this.Translate('Price'),
                Value: value.Price + ' ' + this.PriceUnit,
                Class: ''
              }
            ] : [],
            ...value.AddedCost ? [
              {
                Icon: 'Revenue',
                Border: true,
                Name: this.Translate('Added Cost'),
                Value: value.AddedCost + ' ' + this.PriceUnit,
                Class: ''
              }
            ] : [],
            ...value.Discount !== 0 ? [
              {
                Icon: 'Expenses',
                Border: true,
                Name: this.Translate('Discount'),
                Value: value.Discount + ' ' + this.PriceUnit,
                Class: 'colorr1'
              }
            ] : [],
            ...value.Descripiton ? [
              {
                Border: true,
                Value: value.Descripiton,
                Class: ''
              }
            ] : [],
            {
              Icon: 'ToBeSetteled',
              Border: true,
              Value: (value.Price * value.Quantity + value.AddedCost + value.Discount) + ' ' + this.PriceUnit,
              Class: 'F1_5em F-Bold'
            }
          ],
          Color: this.Theme.UserColors.main,
          Date: value.CustomDate,
          AddedDate: value.AddedDate
        } as Checkpoint
      })
      var Transactions: Checkpoint[] = this.VendorTransactions.map((value: any) => {
        return {
          Icon: 'ToBeSetteled',
          PointTitle: value.Type === 'Pay' ? 'Payment' : 'Collect',
          PointName: value.Item,
          Buttons: [
            ...value.Invoice && value.Invoice !== '' ? [
              {
                Icon: 'Clone',
                Link: value.Invoice,
                Tooltip: 'Attachment'
              }
            ] : []
          ],
          PointData: [
            {
              Border: true,
              Value: value.ID,
              ID: true,
              Class: ''
            },
            {
              Value: value.Description,
              Class: ''
            },
            ...value.paymentDiscountValue !== 0 ? [
              {
                Icon: 'Expenses',
                Border: true,
                Name: this.Translate('Discount'),
                Value: value.paymentDiscountValue + ' ' + this.PriceUnit,
                Class: 'colorr1'
              }
            ] : [],
            {
              Icon: 'ToBeSetteled',
              Border: true,
              Value: value.Value + ' ' + this.PriceUnit,
              Class: 'F1_5em F-Bold'
            }
          ],
          Color: this.Vendor.Type === 'v' ? 'var(--colorr1);' : 'var(--colorg2);',
          Date: value.CustomDate,
          AddedDate: value.AddedDate
        } as Checkpoint
      })
      var AddedDateSorted = this.GeneralSort(Orders.concat(Transactions), ['AddedDate'], -1)
      return this.GeneralSort(AddedDateSorted, ['Date'], -1)
    },
    SlicedActions () {
      return this.CustomerActions.slice(0, this.Views)
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadVendor()
      this.ReadVendorOrders()
      this.ReadVendorTransactions()
    },
    ReadVendor () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Purchase_Vendors', '?ID=' + this.DialogData?.Data?.VendorID).then(response => {
        this.Vendor = response[0]
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadVendorOrders () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Purchase_Orders', '?CustomerID=' + this.DialogData?.Data?.VendorID).then(response => {
        this.VendorOrders = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadVendorTransactions () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Finance_Transactions', '?CustomerID=' + this.DialogData?.Data?.VendorID).then(response => {
        this.VendorTransactions = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  created () {
    if (this.ReadDialogData) this.ReadDialogData()
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "52dee176": (_ctx.Theme.BG4)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__