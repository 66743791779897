// -------------------------------------------------------------------------------- //
// ------------------------------- Cookies Full ERP ------------------------------- //
// -------------------------------------------------------------------------------- //

import { createApp } from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from './router'
import { GeneralData } from '@/Apps/ALL/AppData/General'
import { APIMapper } from '@/Apps/ALL/AppData/APIMapper'

// ------------------------------------------------------------------------- Styles //
// -------------------------------------------------------------------------------- //
import '@/style/CookiesGlobal.css'

// ------------------------------------------------------------- Plugins components //
// -------------------------------------------------------------------------------- //
import VueBarcode from '@chenfengyuan/vue-barcode'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'

// ----------------------------------------------------------------- Core functions //
// -------------------------------------------------------------------------------- //
import CookiesCoreFunctions from '@/modules/CookiesCoreMixin.vue'

// ----------------------------------------------------------------------- App DATA //
// -------------------------------------------------------------------------------- //
const AppData = {
  mixins: [GeneralData, APIMapper],
  computed: {
    NavItems () :Array<NavItem> {
      return [
        {
          Name: 'DashBoard',
          Icon: 'Main',
          Access: 'DashBoard_View',
          Link: '/DashBoard'
        },
        {
          Name: 'Branches',
          Icon: 'Location',
          Access: 'Branches_View',
          Link: '/Branches'
        },
        {
          Name: 'Sales',
          Icon: 'Sales',
          Access: 'Sales_View',
          Link: '/Sales'
        },
        // {
        //   Name: 'Customers',
        //   Icon: 'People',
        //   Access: 'CRM_View',
        //   Link: '/CRM'
        // },
        {
          Name: 'Purchases',
          Icon: 'Cart',
          Access: 'Purchase_View',
          Link: '/Purchase'
        },
        {
          Name: 'Finance',
          Icon: 'MoneyPaper',
          Access: 'Finance_View',
          Link: '/Finance'
        },
        {
          Name: 'Warehouse',
          Icon: 'Packages',
          Access: 'Inventory_View',
          Link: '/Inventory'
        },
        {
          Name: 'Catalog',
          Icon: 'Catalog',
          Access: 'Catalogue_View',
          Link: '/Catalogs'
        },
        {
          Name: 'Manufacturing',
          Icon: 'Factory',
          Access: 'MRP_View',
          Link: '/Manufacturing'
        },
        {
          Name: 'Suppliers',
          Icon: 'Customers',
          Access: 'MRP_View',
          Link: '/Suppliers'
        },
        {
          Name: 'HR',
          Icon: 'Employees',
          Access: 'HR_View',
          Link: '/HR'
        },
        {
          Name: 'Reports',
          Icon: 'Reports',
          Access: 'Reports_View',
          Link: '/Reports'
        },
        {
          Name: 'Security',
          Icon: 'Key',
          Access: 'Security_View',
          Link: '/Security',
          Down: true
        },
        {
          Name: 'Settings',
          Icon: 'Settings',
          Access: 'Main_Settings_View',
          Link: '/Settings',
          Down: true
        }
      ]
    }
  }
}
// --------------------------------------------------------------- Creating the APP //
// -------------------------------------------------------------------------------- //
const CookiesApp = createApp(App)
CookiesApp.use(store).use(router).mixin(CookiesCoreFunctions as any).mixin(AppData)

CookiesApp.config.globalProperties.AppDataMixin = AppData

// ---------------------------------------------------------------- Folders Loading //
// -------------------------------------------------------------------------------- //
CookiesDynamicLoader(require.context('@/Apps', true, /\.vue$/))
CookiesDynamicLoader(require.context('@/components', true, /\.vue$/))

function CookiesDynamicLoader (VueComponents: any) {
  VueComponents.keys().forEach((comp: any) => {
    var ComponentName = comp.split('/').pop().split('.')[0]
    CookiesApp.component(ComponentName, VueComponents(comp).default)
  })
}

// --------------------------------------------------------------- Plugins Mounting //
// -------------------------------------------------------------------------------- //
if (VueBarcode.name !== undefined) {
  CookiesApp.component(VueBarcode.name, VueBarcode)
}
CookiesApp.component('vueQr', vueQr)

// --------------------------------------------------------------- Mounting the APP //
// -------------------------------------------------------------------------------- //
CookiesApp.mount('#CookiesApp')
