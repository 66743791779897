
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as Section,
      Saving: false,
      Loading: [] as Array<'Loading'>,
      CookiesData: {} as any,
      Items: [] as any,
      Brands: [] as any,
      Categories: [] as any,
      ErrorsVisibilty: false,
      CurrentImage: undefined as File | undefined
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadItems()
      this.ReadBrands()
      this.ReadCategories()
    },
    ReadItems () {
      this.Loading.push('Loading')
      this.Get('Inventory_Items', '?ListAllWarehouses=true').then(response => {
        this.Items = response
        if (this.DialogData?.Data && this.DialogData?.Data.ItemID) {
          var SelectedItem = response.find((item :any) => {
            return item.ID === this.DialogData?.Data.ItemID
          })
          if (SelectedItem) {
            this.MEMWrite('D_ItemLocation_Item_Value', SelectedItem)
            // this.MEMWrite('D_ItemLocation_Item', SelectedItem.Name)
          }
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCategories () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Items').then(response => {
        this.Categories = this.GeneralSort(response, 'Name', 1)
        // if (this.DialogData?.Data && this.DialogData?.Data.CategoryID) {
        //   var SelectedCategory = this.Categories.find((cat :any) => {
        //     return cat.ID === this.DialogData?.Data.CategoryID
        //   })
        //   if (SelectedCategory) {
        //     this.MEMWrite('D_Item_Category_Value', SelectedCategory)
        //     this.MEMWrite('D_Item_Category', SelectedCategory.Name)
        //   }
        // }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBrands () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Brands').then(response => {
        this.Brands = this.GeneralSort(response, 'Name', 1)
        // if (this.DialogData?.Data && this.DialogData?.Data.BrandID) {
        //   var SelectedBrand = this.Brands.find((brand :any) => {
        //     return brand.ID === this.DialogData?.Data.BrandID
        //   })
        //   if (SelectedBrand) {
        //     this.MEMWrite('D_Item_Brand_Value', SelectedBrand)
        //     this.MEMWrite('D_Item_Brand', SelectedBrand.Name)
        //   }
        // }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Inventory_ItemsLocation',
        OriginalData: this.OriginalData,
        PatchAPI: 'Inventory_ItemsLocation',
        Editing: this.DialogData?.Editing,
        PatchQuery: this.DialogData?.Editing ? '/' + this.DialogData?.Data.ID : ''
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    }
  },
  created () {
  },
  mounted () {
    if (this.DialogData?.Data) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data) as Section
      if (this.DialogData?.Editing && this.DialogData?.Data && this.DialogData?.Data.IsFlex) {
        this.MEMWrite('D_ItemLocation_IsFlex', true)
      }
    }
    this.ReadDialogData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_ItemLocation')
  },
  computed: {
    SelectedItemPackSize () {
      // console.log(this.MEMRead('D_ItemLocation_Item'))
      // console.log(this.MEMRead('D_ItemLocation_Item_Value'))
      return this.MEMRead('D_ItemLocation_Item_Value') && this.MEMRead('D_ItemLocation_Item_Value').PackSize
    },
    ItemInfo () {
      var Tooltip = ''
      if (this.MEMRead('D_ItemLocation_Item_Value')) {
        try {
          Tooltip += this.Translate('Code') + ':<span class="colorMain">' + this.MEMRead('D_ItemLocation_Item_Value').Code
          Tooltip += '</span><br>'
          Tooltip += this.Translate('Name') + ':<span class="colorMain">' + this.MEMRead('D_ItemLocation_Item_Value').Name
          Tooltip += '</span><br>'
          Tooltip += this.Translate('Category') + ':<span class="colorMain">' + this.MEMRead('D_ItemLocation_Item_Value').CategoryName
          Tooltip += '</span><br>'
          Tooltip += this.Translate('Brand') + ':<span class="colorMain">' + this.MEMRead('D_ItemLocation_Item_Value').Brand?.Name
          Tooltip += '</span><br>'
          Tooltip += this.Translate('Pack Size') + ':<span class="colorMain">' + this.MEMRead('D_ItemLocation_Item_Value').PackSize
          Tooltip += '</span><br>'
          Tooltip += this.Translate('Specs') + ':<span class="colorMain">' + this.MEMRead('D_ItemLocation_Item_Value').Specifications[0].Description
          Tooltip += '</span><br>'
          Tooltip += this.Translate('Specs') + ':<span class="colorMain">' + this.MEMRead('D_ItemLocation_Item_Value').Specifications[1].Description
        } catch (error) {
        }
      }
      return Tooltip
    },
    FilteredItems () {
      // var IsSearch = this.MEMRead('D_Transfer_Search') && this.MEMRead('D_Transfer_Search').length > 2
      var IsBrand = this.MEMRead('D_Transfer_Brand') && this.MEMRead('D_Transfer_Brand').ID !== undefined
      var IsCategory = this.MEMRead('D_Transfer_Brand') && this.MEMRead('D_Transfer_Category').ID !== undefined
      // var TransferItems = this.Items
      var Filteredd = this.Items.filter((item :any) => {
        // var ItemSelected = false
        // if (this.DialogData?.Type === 'Export') {
        //   ItemSelected = TransferItems.some((element :any) => {
        //     return item.ID === element.ID
        //   })
        // }
        // var Search = this.MEMRead('D_Transfer_Search') === '' || this.MEMRead('D_Transfer_Search').length < 3 || this.CookiesSearcher(item, this.MEMRead('D_Transfer_Search'))
        var Brand = !this.MEMRead('D_Transfer_Brand').ID || (this.MEMRead('D_Transfer_Brand').ID === item.BrandID)
        var Category = !this.MEMRead('D_Transfer_Category').ID || (this.MEMRead('D_Transfer_Category').ID === item.CategoryID)
        return (!IsBrand && !IsCategory) || (Brand && Category)
      })
      return Filteredd
    },
    IsEmpty () {
      return (this.DialogData?.Data.ReservedQuantity === 0) && (this.DialogData?.Data.AvailableQuantity === 0) && (this.DialogData?.Data.OrderedQuantity === 0)
    },
    DialogMapper () {
      return [
        { Path: 'ItemID', Res: 'D_ItemLocation_Item', ValuePath: 'ID' },
        { Path: 'IsPack', Res: 'D_ItemLocation_IsPack' },
        { Path: 'QuantityLow', Res: 'D_ItemLocation_Low' },
        { Path: 'QuantityMax', Res: 'D_ItemLocation_Max' },
        { Path: 'IsFlex', Res: 'D_ItemLocation_IsFlex', Default: false },
        { Path: 'SectionID', Value: this.DialogData?.SectionID },
        { Path: 'Notes', Res: 'D_ItemLocation_Note', Default: '' }
      ] as Array<DialogMapperObject>
    }
  }
})
