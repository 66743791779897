
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    FilterSettings: { default: () :FilterObject => { return {} }, type: Object as PropType<FilterObject> },
    ShowFilter: { default: true, type: Boolean }
  },
  data () {
    return {
      DateFrom: this.$store.state.CurrentDate.From,
      DateTo: this.$store.state.CurrentDate.To
    }
  },
  mounted () {
    if (this.FilterSettings.Checks) {
      this.FilterSettings.Checks.forEach(checksGroup => {
        if (checksGroup.DefaultOpen) {
          this.MEMWrite('ChecksGroupOpen_' + checksGroup.GroupName, true)
        }
      })
    }
  },
  methods: {
    DatesChanged () {
      this.$store.state.CurrentDate.From = this.DateFrom
      this.$store.state.CurrentDate.To = this.DateTo
      this.$emit('DateChanged')
    },
    AllFilterSelector (ChecksGroup :FilterGroup, value :boolean) {
      if (ChecksGroup.Checks === undefined) {
        return
      }
      ChecksGroup.Checks.forEach(CheckBox => {
        this.MEMWrite(CheckBox.Res, value)
      })
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "753a1a48": (_ctx.Theme.BackBlur),
  "0c8786ff": (_ctx.Theme.BG2),
  "0c878701": (_ctx.Theme.BG4)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__