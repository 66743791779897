
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'

import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/leaflet.js'

export default defineComponent({
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  async created () {
    if (this.$store?.state.CompanyManagment?.CurrentCompany) await this.ReadConfig()
  },
  mounted () {
    this.ResetLocation()
  },
  mixins: [CookiesCoreMixinVue],
  data () {
    return {
      markerLatLng: null as any,
      GPSLongitude: null,
      GPSLatitude: null,
      clickedPlace: {
        latitude: null,
        longitude: null,
        address: null,
        countryCode: null
      },
      zoom: 5,
      OriginalData: {} as any,
      Saving: false,
      selectedFile: null,
      Loading: [] as Array<'Loading'>,
      Profiles: [],
      ErrorsVisibilty: false
    }
  },
  methods: {
    handleMapClick (e: { latlng: { lat: any; lng: any } }) {
      const { lat, lng } = e.latlng
      this.GPSLatitude = lat
      this.GPSLongitude = lng
      // Perform reverse geocoding to get place information
      // this.getPlaceInformation(lat, lng)
      this.markerLatLng = [lat, lng]
      this.MEMWrite('D_Branch_LatLongText', lat + ',' + lng)
    },
    CopyLocation () {
      const elem = document.createElement('textarea')
      elem.value = this.markerLatLng
      document.body.appendChild(elem)
      elem.select()
      document.execCommand('copy')
      document.body.removeChild(elem)
      this.PushNotification('Link Copied')
    },
    ResetLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.markerLatLng = [position.coords.latitude, position.coords.longitude]
            // this.UpdateLocation(position.coords.latitude, position.coords.longitude)
          },
          (error) => {
            console.error(error)
          }
        )
      } else {
        alert('Geolocation is not supported by this browser.')
        console.error('Geolocation is not supported by this browser.')
      }
    },
    GetLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.markerLatLng = [position.coords.latitude, position.coords.longitude]
            // this.UpdateLocation(position.coords.latitude, position.coords.longitude)
          },
          (error) => {
            console.error(error)
          }
        )
      } else {
        alert('Geolocation is not supported by this browser.')
        console.error('Geolocation is not supported by this browser.')
      }
    }
  },
  computed: {
    CheckGPSLongitude () {
      return this.GPSLongitude
    },
    CheckGPSLatitude () {
      return this.GPSLatitude
    },
    CheckmarkerLatLng () {
      return this.markerLatLng
    }
  }
})
