import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cdc7b57a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["accept", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!

  return (_openBlock(), _createElementBlock("label", {
    onDragenter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Dropping = true)),
    onDragleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Dropping = false)),
    onDragover: _cache[5] || (_cache[5] = ($event: any) => (_ctx.PreventDragEvent($event))),
    onDrop: _cache[6] || (_cache[6] = ($event: any) => (_ctx.FileDropped($event))),
    class: _normalizeClass([ [_ctx.ErrorData && ' ErrorBorder ', (_ctx.Disabled || _ctx.Selecting) && ' Disabled ', _ctx.Dropping ? 'Dropping' : ''], "UserImage M-Auto P-Rel"]),
    style: _normalizeStyle([_ctx.IsFile ? 'background: none; box-shadow: none;' : ('background-image: url(' + _ctx.DefaultImageHandler(typeof(_ctx.MEMRead(_ctx.Res)) === 'string' ? _ctx.MEMRead(_ctx.Res) : _ctx.SelectedImgUrl, _ctx.Placeholder) + '); background-color: var(--shade2); box-shadow: inset var(--shadow1);'), _ctx.MEMRead(_ctx.Res) && _ctx.MEMRead(_ctx.Res) !== '' && _ctx.backGroundContain ? 'background-size: 70%; background-repeat: no-repeat' : ''])
  }, [
    _createElementVNode("input", {
      accept: _ctx.Accept,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Clicked())),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectionDone())),
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.UserImageChanged())),
      name: _ctx.Res,
      type: "file"
    }, null, 40, _hoisted_1),
    (_ctx.IsFile)
      ? (_openBlock(), _createBlock(_component_CookiesIcon, {
          key: 0,
          class: "",
          style: {"position":"absolute","inset":"0.3em"},
          ID: 'Clone'
        }))
      : _createCommentVNode("", true),
    (_ctx.IsFile && _ctx.SelectedImgUrl !== '')
      ? (_openBlock(), _createBlock(_component_CookiesIcon, {
          key: 1,
          class: "",
          style: {"position":"absolute","inset":"0.8em","bottom":"1.2em","top":"-0.4em","left":"-1.2em"},
          ID: 'Done'
        }))
      : _createCommentVNode("", true)
  ], 38))
}