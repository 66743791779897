const routes :Array<CookiesRouter> = [
  {
    path: '/Purchase',
    name: 'Purchase',
    meta: {
      Access: 'Purchase_View' as AccessFeature
    },
    component: () => import('@/Apps/Purchase/views/Purchase.vue'),
    children: [
      {
        path: '/Purchase/Orders',
        name: 'Purchases',
        component: () => import('@/Apps/Purchase/views/PurchaseOrders.vue'),
        meta: {
          Access: 'Purchase_Orders_Read' as AccessFeature,
          LoginHeader: true
        }
      },
      {
        path: '/Purchase/Vendors',
        name: 'Vendors',
        component: () => import('@/Apps/Purchase/views/Vendors.vue'),
        meta: {
          Access: 'Purchase_Vendors_Read' as AccessFeature,
          LoginHeader: true
        }
      }
    ]
  }
]

export const PurchaseRoutes = routes
