
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

import { LineChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import stringSimilarity from 'string-similarity'
import chroma from 'chroma-js'
import { de } from 'date-fns/locale'
import 'chartjs-adapter-date-fns'

Chart.register(...registerables)

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Type: { type: String, default: '' },
    Similarity: { type: Number, default: 1 },
    GroupBy: { type: String, default: 'Transfer.AddedDate' },
    OnClickDialog: { default: () => { return undefined }, type: String as PropType<CookiesDialog> },
    OrderBy: { default: '', type: String },
    Unit: { default: () => { return '' }, type: String as PropType<'Quantity' | 'Volume'> },
    Legend: { type: Boolean, default: false }
  },
  name: 'Home',
  components: { LineChart },
  setup () {},
  data () {
    return {
      CookiesData: [] as any,
      LabelsPathCashed: ['TransDate'] as ObjectPath,
      DataPathCashed: ['Transfers'] as ObjectPath,
      MyChart: {} as any,
      MyLegend: [] as Array<any>,
      CurrentHover: null as null | number,
      Loading: false
    }
  },
  methods: {
    LegendClicked (i :number) {
      if (!this.OnClickDialog) return
      this.OpenDialog(this.OnClickDialog, this.Translate('Info'), this.SimilarityFixed[i])
    },
    Generated (event :any) {
      this.MyChart = event
      this.MyLegend = event.legend.legendItems
    },
    onHover (itemIndex :number) {
      this.CurrentHover = itemIndex
      var Color = ''
      this.MyChart.data.datasets[0].backgroundColor.forEach((color:any, index:number, colors:any) => {
        Color = index === itemIndex ? color : Color
        colors[index] = index === itemIndex || color.length === 9 ? color : color + '1D'
      })
      this.DrawInnerPieLabel(itemIndex, Color)
      this.$refs.myChart.update()
    },
    onLeave () {
      this.CurrentHover = null
      this.MyChart.data.datasets[0].backgroundColor.forEach((color:any, index:number, colors:any) => {
        colors[index] = color.length === 9 ? color.slice(0, -2) : color
      })
      this.RemoveInnerLabel()
      this.$refs.myChart.update()
    },
    DrawInnerPieLabel (index :number, color :string) {
      const value = this.Series[index]
      const canvas = this.MyChart.canvas as HTMLCanvasElement
      var InnerHtml =
      `
      <div style="max-width:${0.7 * canvas.width}px">
        <div class="D-Flex" style="width: 70%; height: 2.2em; align-items: center; justify-content: center;">${this.DeepFetcher(this.SimilarityFixed[index], this.LabelsPathCashed)}</div>
        <div class="LabelSplitter"></div>
        <div><span class="F1_75em F-Bold">${this.DecimalFixer(value, 1)}</span></div>
        <div><span class="F_75em">${this.ComputedUnit}</span></div>
        <div class="LabelSplitter"></div>
        <div class="F-Bold F1_25em Shadow3" style="color:${color}">${this.DecimalFixer(value / this.DataTotal * 100, 1)}%</div>
      </div>
      `
      const canvasParent = canvas.parentElement
      const exist = canvasParent?.querySelector('.DonutInnerLabel')
      if (exist) {
        exist.innerHTML = InnerHtml
      } else {
        var element = document.createElement('div')
        element.classList.add('DonutInnerLabel')
        element.innerHTML = InnerHtml
        if (canvasParent) canvasParent.appendChild(element)
      }
    },
    RemoveInnerLabel () {
      const canvas = this.MyChart.canvas as HTMLCanvasElement
      const canvasParent = canvas.parentElement
      const exist = canvasParent?.querySelector('.DonutInnerLabel')
      if (exist) {
        exist.innerHTML = ''
        // `
        // <div>${this.Translate('Total')}</div>
        // <div class="F1_25em F-Bold" ></div>
        // `
      }
    },
    ReadCookiesData () {
      this.Loading = true
      this.Get('DashBoard_Transfers', `?${this.DateAPIURL()}${(this.MEMRead('CurrentWarehouse') ? '&WarehouseID=' + this.MEMRead('CurrentWarehouse').IDs : '')}`).then(response => {
        this.CookiesData = response
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Items'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  mounted () {
    this.LabelsPathCashed = this.GroupBy.replaceAll('.', '')
    this.DataPathCashed = this.Unit
    this.ReadCookiesData()
  },
  computed: {
    ChartData () {
      // var Colors:Array<any> = []
      // // var ColorsPallete = this.ColorsGenerator(this.Series.length)
      // var ColorsPallete = this.$store.state.CookiesChart.colors
      // var ColorsLength = ColorsPallete.length - 1
      // this.Series.forEach((element :any, index :number) => {
      //   Colors.push(chroma(ColorsPallete[index - (Math.floor(index / ColorsLength) * ColorsLength)]))
      // })
      return {
        labels: this.Labels,
        datasets: [
          {
            label: this.Translate('Exports'),
            data: this.CookiesData.map((value :any) => { return { x: value.x, y: value.Exports } }),
            count: this.CookiesData.map((value :any) => { return value.ExportsCount }),
            borderWidth: 3,
            borderColor: this.Theme.UserColors.main,
            tension: 0.2
          },
          {
            label: this.Translate('Imports'),
            data: this.CookiesData.map((value :any) => { return { x: value.x, y: value.Imports } }),
            count: this.CookiesData.map((value :any) => { return value.ImportsCount }),
            borderWidth: 3,
            borderColor: this.$store.state.CookiesChart.colors[3],
            tension: 0.2
          },
          {
            label: this.Translate('Branches Transfer'),
            data: this.CookiesData.map((value :any) => { return { x: value.x, y: value.Transfers } }),
            count: this.CookiesData.map((value :any) => { return value.TransfersCount }),
            borderWidth: 3,
            borderColor: this.$store.state.CookiesChart.colors[4],
            tension: 0.2
          }
        ]
      }
    },
    ChartOptions () {
      const _this = this
      return {
        responsive: true,
        // hoverBackgroundColor (item :any, chart :any) {
        //   _this.onHover(item)
        //   // var myColor = item.element.options.backgroundColor
        //   // return (myColor && typeof myColor === 'string' && myColor.length >= 7) ? (myColor.slice(0, 7) + '88') : myColor
        // },
        borderColor: 'rgb(75, 192, 192)',
        animations: {
          colors: false
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: true,
            cornerRadius: 4,
            caretSize: 4,
            bodyColor: _this.Theme.BG1,
            backgroundColor: _this.Theme.color2,
            caretPadding: 2,
            padding: 10,
            boxPadding: 6,
            boxHeight: 5,
            boxWidth: 5,
            usePointStyle: true,
            borderWidth: 5,
            callbacks: {
              label (tooltipItems :any) {
                try {
                  return `${tooltipItems.dataset.label}: ${tooltipItems.formattedValue}${_this.VolumeUnit}(${_this.ChartData.datasets[tooltipItems.datasetIndex].count[tooltipItems.dataIndex]})`
                } catch (error) {
                  return ''
                }
              }
            }
          }
        }
      }
    },
    DataTotal () {
      var DataTotal = 0
      this.Series.forEach((element :any) => {
        DataTotal = DataTotal + element
      })
      return DataTotal
    },
    Series () {
      try {
        return this.SimilarityFixed.map((element :any) => {
          return this.DeepFetcher(element, this.DataPathCashed)
        })
      } catch (error) {
        return []
      }
    },
    Labels () {
      try {
        return [this.$store.state.CurrentDate.From.toISOString(), this.$store.state.CurrentDate.To.toISOString()]
      } catch (error) {
        return []
      }
    },
    ComputedUnit () {
      switch (this.DataPathCashed) {
        case 'Volume':
          return this.VolumeUnit
        default:
          return ''
      }
    },
    SimilarityFixed () {
      if (this.Similarity === 1) {
        return this.CookiesData
      }
      var SimilarArray :Array<any> = []
      this.CookiesData.forEach((element :any) => {
        var found = SimilarArray.find((oldElement :any) => {
          if (!(this.DeepFetcher(element, this.LabelsPathCashed)) || !(this.DeepFetcher(oldElement, this.LabelsPathCashed))) return false
          return stringSimilarity.compareTwoStrings(this.DeepFetcher(element, this.LabelsPathCashed), this.DeepFetcher(oldElement, this.LabelsPathCashed)) > this.Similarity
        })
        if (found) {
          this.DeepChanger(found, 'Quantity', this.DeepFetcher(found, 'Quantity') + this.DeepFetcher(element, 'Quantity'))
          this.DeepChanger(found, 'Volume', this.DeepFetcher(found, 'Volume') + this.DeepFetcher(element, 'Volume'))
        } else {
          SimilarArray.push(element)
        }
      })
      return SimilarArray
    }
  },
  watch: {
    Type: function (newVal, oldVal) { // watch it
      if (newVal !== oldVal) {
        this.ReadCookiesData()
      }
    },
    GroupBy: function (newVal, oldVal) { // watch it
      if (newVal !== oldVal) {
        this.LabelsPathCashed = this.GroupBy.replaceAll('.', '')
        this.ReadCookiesData()
      }
    },
    OrderBy: function (newVal, oldVal) { // watch it
      if (newVal !== oldVal) {
        this.ReadCookiesData()
      }
    },
    Unit: function (newVal, oldVal) { // watch it
      if (newVal !== oldVal) {
        this.DataPathCashed = this.Unit
        this.ReadCookiesData()
      }
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadCookiesData()
    },
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "46f59865": (_ctx.$store.getters.Direction),
  "c3c4f89a": (_ctx.Theme.color4)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__