
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
// import router from './Apps/ALL/router/index'
const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  components: {},
  created () {
    const favicon = document.getElementById('FavIcon') as HTMLLinkElement
    favicon.href = this.$store.state.CookiesConfig.FavIco
    this.MEMWrite('GlobalSaving', [])
    this.MEMWrite('GlobalSavingDone', [])
    this.MEMWrite('GlobalSavingFailed', [])
    fetch('/Cookies.config.json')
      .then((CustomConfigRes) => {
        if (CustomConfigRes.ok) {
          CustomConfigRes.json()
            .then((CustomConfig) => {
              console.log('CustomConfig')
              this.$store.state.CookiesCustomConfig = CustomConfig
              this.AfterCustomConfig()
            })
        }
      })
      .catch((error) => {
        console.error(error)
        this.AfterCustomConfig()
      })
  },
  beforeMount () {
    this.TimeBeforeMounted()
  },
  mounted () {
    const favicon = document.getElementById('FavIcon') as HTMLLinkElement
    favicon.href = this.$store.state.CookiesConfig.FavIco
    setTimeout(() => {
      window.scrollTo(0, 1)
    }, 300)
  },
  methods: {
    AfterCustomConfig () {
      var LocalConfig = this.LocalRead('CookiesDefaultConfig')
      if (LocalConfig !== null) {
        this.$store.state.CookiesConfig = {
          ...this.$store.state.CookiesConfig,
          ...LocalConfig
        }
      }
      this.CookiesAuthorization()
    },
    ToolTipSence () {
      this.$store.state.ToolTipElement = document.querySelector('[ToolTip]:hover')
    }
  },
  computed: {
    TabHeaderShadower () {
      return this.$store.getters.CurrentTheme === 'light' ? 'rgba(0, 0, 0, 0.20)' : 'rgba(0, 0, 0, 0.17)'
    },
    Maintenance () {
      return this.$store.state.CookiesConfig.MaintenanceMode === 'true'
    },
    VersionChecker () {
      var ServerVersion = this.$store.state.CookiesVueServerVersion
      var CurrentVersion = this.$store.state.CookiesVersion
      if (!ServerVersion) return false
      if (parseInt(ServerVersion.split('.')[0]) > parseInt(CurrentVersion.split('.')[0])) return true
      if (parseInt(ServerVersion.split('.')[0]) < parseInt(CurrentVersion.split('.')[0])) return false
      if (parseInt(ServerVersion.split('.')[1]) > parseInt(CurrentVersion.split('.')[1])) return true
      if (parseInt(ServerVersion.split('.')[1]) < parseInt(CurrentVersion.split('.')[1])) return false
      if (parseInt(ServerVersion.split('.')[2]) > parseInt(CurrentVersion.split('.')[2])) return true
      return false
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "339783d0": (_ctx.$store.getters.CurrentTheme),
  "c63e6102": (_ctx.$store.getters.Direction),
  "72c1dc68": (_ctx.Theme.color3),
  "6dbeeb28": (_ctx.Theme.colorBold),
  "8d7eded2": (_ctx.$store.getters.DefaultTextAlign),
  "63098d20": (_ctx.Theme.UserColors.main),
  "2705666b": (_ctx.Theme.UserColors.second),
  "0151c890": (_ctx.Theme.UserColors.third),
  "116b8c11": (_ctx.Theme.UserColors.fourth),
  "72c1dc6c": (_ctx.Theme.color1),
  "72c1dc6a": (_ctx.Theme.color2),
  "e9735d20": (_ctx.Theme.BG1),
  "e9735d1e": (_ctx.Theme.BG2),
  "e9735d1c": (_ctx.Theme.BG3),
  "e9735d1a": (_ctx.Theme.BG4),
  "e9735d18": (_ctx.Theme.BG5),
  "e9735d16": (_ctx.Theme.BG6),
  "e9735d14": (_ctx.Theme.BG7),
  "e9735d12": (_ctx.Theme.BG8),
  "e7a1e838": (_ctx.Theme.BG1Fixed),
  "e43836fa": (_ctx.Theme.BG2Fixed),
  "e0ce85bc": (_ctx.Theme.BG3Fixed),
  "dd64d47e": (_ctx.Theme.BG4Fixed),
  "d9fb2340": (_ctx.Theme.BG5Fixed),
  "d6917202": (_ctx.Theme.BG6Fixed),
  "d327c0c4": (_ctx.Theme.BG7Fixed),
  "cfbe0f86": (_ctx.Theme.BG8Fixed),
  "50ce8f16": (_ctx.Theme.InputColor),
  "51e2fb4a": (_ctx.Theme.UserColors.Dimmed),
  "4c3bdb87": (_ctx.Theme.UserColors.Gradient),
  "aa2c8f42": (_ctx.Theme.Shade0),
  "aa2c8f40": (_ctx.Theme.Shade1),
  "aa2c8f3e": (_ctx.Theme.Shade2),
  "aa2c8f3c": (_ctx.Theme.Shade3),
  "aa2c8f3a": (_ctx.Theme.Shade4),
  "42dadfaa": (_ctx.Theme.BackBlur),
  "75c27b72": (_ctx.Theme.UserColors.main_25),
  "e787ceac": (_ctx.Theme.UserColors.third_50),
  "c1eea2ce": (_ctx.Theme.Light3),
  "2ee67c58": (_ctx.DirectionStyler.borderLeft),
  "a381202a": (_ctx.DirectionStyler.borderRight),
  "0f86a010": (_ctx.TabHeaderShadower),
  "13add833": (_ctx.CurrentBackgroundColor),
  "7db00cea": (_ctx.DirectionStyler.first),
  "0408b74a": (_ctx.DirectionStyler.last),
  "5aa106ba": (`url(${require("@/assets/elements/HorizontalEdgesMask.svg")})`)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__