const routes :Array<CookiesRouter> = [
  {
    path: '/CRM',
    name: 'CRM',
    meta: {
      Access: 'CRM_View' as AccessFeature
    },
    component: () => import('@/Apps/CRM/views/CRM.vue'),
    children: [
      {
        path: '/CRM/Customers',
        name: 'View Customers',
        component: () => import('@/Apps/CRM/views/Customers.vue'),
        meta: {
          Access: 'CRM_View' as AccessFeature,
          LoginHeader: true
        }
      }
    ]
  }
]

export const CRMRoutes = routes
