
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  name: 'Integration Settings',
  data () {
    return {}
  },
  computed: {}
  // methods: {
  //   ChangePassword () {
  //     this.OpenDialog('Security_ChangePassword_Dialog', 'Change Password', this.$store.state.UserManagment.CurrentUser.Email) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
  //   }
  // }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "38806224": (_ctx.Theme.BG2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__