import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13521263"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "M-Auto DialogContainer" }
const _hoisted_3 = { class: "DialogBody" }
const _hoisted_4 = { class: "DialogSection" }
const _hoisted_5 = { class: "W-100 D-Flex" }
const _hoisted_6 = {
  style: {"min-height":"1em"},
  class: "M-Auto F-CR"
}
const _hoisted_7 = { class: "CookiesDialogFooter" }
const _hoisted_8 = {
  class: "F-Bold",
  style: {"margin":"auto 1em"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogDisabler = _resolveComponent("DialogDisabler")!
  const _component_CookiesNormalInput = _resolveComponent("CookiesNormalInput")!
  const _component_NewPassword = _resolveComponent("NewPassword")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: 'CookiesDialog_' + _ctx.DialogIndex,
      class: "CookiesDialogdBody"
    }, [
      (_ctx.Saving)
        ? (_openBlock(), _createBlock(_component_DialogDisabler, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.Loading.length > 0)
        ? (_openBlock(), _createBlock(_component_DialogDisabler, { key: 1 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.$store.state.UserManagment.CurrentUser?.IsCookiesAdmin)
              ? (_openBlock(), _createBlock(_component_CookiesNormalInput, {
                  key: 0,
                  Placeholder: _ctx.Translate('Old Password'),
                  type: 'password',
                  Res: 'D_ChangePassword_OldPassword',
                  CookiesInvalidInput: !_ctx.PasswordValidator(_ctx.MEMRead('D_ChangePassword_OldPassword')),
                  ErrorData: (_ctx.ErrorsVisibilty && (!_ctx.PasswordValidator(_ctx.MEMRead('D_ChangePassword_OldPassword')))),
                  Default: ''
                }, null, 8, ["Placeholder", "CookiesInvalidInput", "ErrorData"]))
              : _createCommentVNode("", true),
            _createVNode(_component_NewPassword, {
              onChangePassword: _ctx.ChangePassword,
              ErrorsVisibilty: _ctx.ErrorsVisibilty,
              Res: 'D_ChangePassword_NewPassword'
            }, null, 8, ["onChangePassword", "ErrorsVisibilty"]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.ErrorText), 1)
            ])
          ])
        ])
      ])
    ], 8, _hoisted_1),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
          style: {"background":"var(--colorr1)"},
          class: "F-Bold CookiesButton DialogFooterButton"
        }, "Cancel"),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ChangePassword())),
          class: "F-Bold CookiesButton DialogFooterButton BGThird"
        }, "Save")
      ])
    ])
  ], 64))
}