
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ReadVendors()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, undefined)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<any>,
      Vendors: [] as any[],
      MappedVendors: {} as any,
      Profiles: [] as Array<Profile>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {
        Dates: true,
        Lists: [
          {
            Name: 'Vendors',
            Options: this.Vendors,
            Res: 'PurchaseOrderFilter',
            Selectors: [['Name'], ['ID']],
            Visible: true,
            DataPath: ['CustomerID'],
            ListPath: ['ID']
          }
        ]
      }
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Purchase',
          this: this,
          Function: 'NewOrder',
          Icon: 'Add',
          Visible: this.Access('Purchase_Orders_Create')
        },
        {
          Name: this.Translate('Pay'),
          this: this,
          Function: 'NewBill',
          Icon: 'Export',
          Style: 'background: var(--colorr1)',
          Visible: this.Access('Finance_Transactions_Create')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: { Direction: -1, Path: 'ID' },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'DateTime',
            Name: 'Date',
            Path: 'CustomDate',
            Sort: 'CustomDate'
          },
          {
            Type: 'Normal',
            Name: 'Serial',
            Bold: true,
            Path: ['CustomerID'],
            Sort: ['CustomerID'],
            Hash: '#',
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Vendor',
            Path: ['CustomerName'],
            Sort: ['CustomerName'],
            Bold: true,
            Class: 'F1_25em',
            Style: 'cursor: pointer;',
            IsSearchable: true,
            Emits: 'OpenVendor'
          },
          {
            Type: 'Normal',
            Name: 'Item',
            Path: ['ItemName'],
            Sort: ['ItemName'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Details',
            Path: ['Description'],
            Sort: ['Description'],
            MultiLine: true,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Quantity',
            Path: ['Quantity'],
            Sort: ['Quantity'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Price',
            Path: ['Price'],
            Sort: ['Price'],
            Unit: this.PriceUnit,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Total',
            Path: ['Total'],
            Sort: ['Total'],
            Bold: true,
            Class: 'F1_25em',
            Unit: this.PriceUnit,
            IsSearchable: true
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Edit',
                Visible: this.Access('Purchase_Orders_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditBranch',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: false,
                  ValuePath: 'IsNew'
                }
              },
              {
                ID: 'Edit',
                Visible: true,
                ToolTip: this.Translate('Edit'),
                Emits: 'EditBranch',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: true,
                  ValuePath: 'IsNew'
                }
              },
              {
                ID: 'Delete',
                Emits: 'DeleteBranch',
                Visible: this.Access('Purchase_Orders_Remove'),
                ToolTip: this.Translate('Remove'),
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: false,
                  ValuePath: 'IsNew'
                }
              },
              {
                ID: 'Delete',
                Emits: 'DeleteBranch',
                ToolTip: this.Translate('Remove'),
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: true,
                  ValuePath: 'IsNew'
                }
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.ModifiedData, this.Filter, this.Search)
    },
    ModifiedData () {
      var DateSpan = new Date(new Date().getTime() - (60 * 60 * 24 * 1000))
      return this.CookiesData.map((value) => { return { ...value, CustomerName: this.MappedVendors['v' + value.CustomerID?.toString()], IsNew: new Date(value.AddedDate) > DateSpan } })
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Purchase_Orders', '?SerialNO=p').then(response => {
        this.CookiesData = response.map((value: any) => { return { ...value, PhoneCall: 'tel:' + value.PhoneNumber, Total: value.Price * value.Quantity } })
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadVendors () {
      this.Loading.push('Loading')
      this.Get('Purchase_Vendors', '?Type=v').then(response => {
        this.Vendors = this.GeneralSort(response.map((value: any) => { return { ...value, ID: value.ID.toString(), Serial: '#' + value.ID } }), 'ID', -1)
        this.Vendors.forEach((value, index, array) => { this.MappedVendors['v' + value.ID] = value.Name })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditBranch (Customer: any) {
      this.OpenDialog('Purchase_Order_Dialog', this.Translate('Edit Purchase') + ' (' + Customer.Name + ')', { Data: Customer, Editing: true }, {})
    },
    DeleteBranch (Branch: any) {
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Purchase_Orders', AddQuery: '/' + Branch.ID, Text: 'Are you sure you want to delete <strong>' + Branch.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.BranchDeleted, AfterDialogFunctionValue: Branch.ImgUrl } as GeneralAcceptDialogData, {})
    },
    BranchDeleted (ImgUrl :string) {
      if (ImgUrl) this.DeleteImage(ImgUrl)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewOrder':
          this.OpenDialog('Purchase_Order_Dialog', this.Translate('New Purchase'), {})
          break
        case 'NewBill':
          this.OpenDialog('Finance_Transaction_Dialog', this.Translate('New Transaction'), { Data: { Type: 'Pay', CustomerType: 'v' } })
          break
      }
    },
    ViewLocation (Branch :Branch) {
      window.open('https://www.google.com/maps/@' + Branch.GPSLongitude + ',' + Branch.GPSLatitude)
    },
    Whatsapp (customer: any) {
      this.OpenWhatsapp(customer.PhoneNumber)
    },
    OpenVendor (Order :any) {
      this.OpenDialog('Purchase_VendorView_Dialog', this.Translate('View Vendor'), { Data: { VendorID: Order.CustomerID } })
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
        this.ReadVendors()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
