
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {},
  data () {
    return {}
  },
  computed: {
    Name () {
      return this.Translate('CRM')
    },
    Tabs () {
      return [
        { Name: this.Translate('Customers'), Link: 'Customers', Access: this.Access('CRM_View') }
      ] as Array<CookiesTabs>
    }
  },
  methods: {},
  watch: {}
})
