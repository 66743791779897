import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "BigLoading"
}
const _hoisted_2 = {
  class: "D-Flex P1em",
  style: {"justify-content":"space-between"}
}
const _hoisted_3 = { class: "V-Auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesLoading = _resolveComponent("CookiesLoading")!
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!
  const _component_CookiesTabs = _resolveComponent("CookiesTabs")!
  const _component_CookiesFunctionalTable = _resolveComponent("CookiesFunctionalTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.Loading.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createVNode(_component_CookiesLoading)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (this.Access('HR_Employees_Create'))
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.NewEmployee())),
            class: "DialogHeaderButton BGMain F1_25em CookiesFade D-Flex",
            style: {"margin":"0"}
          }, [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_component_CookiesIcon, {
                class: "SQR1_5em",
                ID: 'Add'
              })
            ]),
            _createElementVNode("span", null, _toDisplayString(_ctx.Translate('New Employee')), 1)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_CookiesTabs, {
        Style: 'margin: auto 0 0 0;',
        onSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ReadCookiesData())),
        Res: 'HR_EmployeesView_Type',
        Default: { Name: _ctx.Translate('Employees'), Value: 'Normal' },
        Router: false,
        Tabs: ([{ Name: _ctx.Translate('Employees'), Value: 'Normal' }, { Name: _ctx.Translate('Daily Employees'), Value: 'Daily' }] as CookiesTabs[])
      }, null, 8, ["Default", "Tabs"])
    ]),
    _createVNode(_component_CookiesFunctionalTable, {
      style: {"margin-top":"0"},
      onAssignDevice: _ctx.AssignDevice,
      onAssignNewDevice: _ctx.AssignNewDevice,
      onSwapStatus: _ctx.SwapStatus,
      onPrintSalarySlip: _ctx.PrintSalarySlip,
      onViewEmployee: _ctx.ViewEmployee,
      onEditEmployee: _ctx.EditEmployee,
      onDeleteEmployee: _ctx.DeleteEmployee,
      Loading: _ctx.Loading,
      CookiesData: _ctx.FilteredData,
      TableSettings: _ctx.TableSettings
    }, null, 8, ["onAssignDevice", "onAssignNewDevice", "onSwapStatus", "onPrintSalarySlip", "onViewEmployee", "onEditEmployee", "onDeleteEmployee", "Loading", "CookiesData", "TableSettings"])
  ], 64))
}