
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: false,
      Loading: [],
      ErrorsVisibilty: false,
      DialogMapper: [
        { Path: 'Name', Res: 'D_Account_Name' },
        // { Path: 'Type', Res: 'D_Account_Type' },
        { Path: 'Description', Res: 'D_Account_Description' },
        { Path: 'InitialBalance', Res: 'D_Account_InitialBalance' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Finance_Accounts',
        OriginalData: this.OriginalData,
        PatchAPI: 'Finance_Accounts',
        Editing: this.DialogData?.Editing,
        PatchQuery: '/' + this.DialogData?.Data?.ID
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    }
  },
  mounted () {
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
    if (this.DialogData?.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
      this.Console(this.OriginalData)
    }
  }
})
