import { withKeys as _withKeys, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14c270fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "type"]
const _hoisted_2 = { class: "CookiesInputPlaceholder" }
const _hoisted_3 = {
  key: 0,
  class: "P-Abs Inset0 D-Flex Events-None"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.Placeholder === '' || (_ctx.MEMRead(_ctx.Res) === '' || !_ctx.MEMRead(_ctx.Res)) ? '' : 'padding-top: 0.95em; padding-bottom: 0.45em;'),
    class: _normalizeClass(["CookiesInput P-Rel",  [_ctx.ErrorData && ' ErrorBorder ', _ctx.Disabled && ' Disabled '] ])
  }, [
    _withDirectives(_createElementVNode("input", {
      onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('changed'))),
      onKeypress: _cache[1] || (_cache[1] = _withKeys(($event: any) => (($event.target as any)?.blur()), ["enter"])),
      name: _ctx.Name !== '' ? _ctx.Name : _ctx.Placeholder,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$store.state.CookiesMemory[_ctx.Res ? _ctx.Res : 'undefined']) = $event)),
      type: _ctx.ShowPass ? 'text': _ctx.type,
      autocomplete: "off"
    }, null, 40, _hoisted_1), [
      [_vModelDynamic, _ctx.$store.state.CookiesMemory[_ctx.Res ? _ctx.Res : 'undefined']]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "M-Auto",
        style: _normalizeStyle((_ctx.MEMRead(_ctx.Res) === '' || !_ctx.MEMRead(_ctx.Res)) ? 'margin-inline-start: 1.25em;' : 'margin-inline-start: 0.9em; margin-top: 0.2em; font-size: 0.75em; filter: blur(0.6px)')
      }, _toDisplayString(_ctx.Placeholder), 5)
    ]),
    (_ctx.type === 'password')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_CookiesIcon, {
            class: "M-Auto SQR2em Pointer O-25 ShowPassClass",
            style: _normalizeStyle([_ctx.ShowPass ? 'opacity : 0.5' : '', {"pointer-events":"all","margin-inline-end":"1.5em"}]),
            Color: _ctx.ShowPass ? { ColorA:  _ctx.Theme.UserColors.fourth } : {},
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ShowPass = !_ctx.ShowPass)),
            ID: ('Show' as IconsIDs)
          }, null, 8, ["style", "Color"])
        ]))
      : _createCommentVNode("", true)
  ], 6))
}