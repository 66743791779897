<template>
<div class="CookiesRow W-75" style="margin-top: 4em; font-size: 1.1em;">
  <!-- User Image -------------------------- -->
  <div class="CookiesRow">
    <div class="UserImage" style="pointer-events: none;" :style="'background-image: url(' + DefaultImageHandler($store.state.UserManagment.CurrentUser.ImgURL, $store.state.CookiesConfig.UserImage) + ');'"></div>
  </div>
  <!-- UserName -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <div class="D-Flex FlexColumn FlexCenter EvenContainer">
    <div class="M-Auto F1_75em F-Bold">{{$store.state.UserManagment.CurrentUser.Name}}</div>
    <!-- Email -------------------------- -->
    <div class="M-Auto F1_25em F1em">{{$store.state.UserManagment.CurrentUser.Email}}</div>
  </div>
  <!-- AddedDate -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <div class="M-Auto F1em">{{DateFormatter($store.state.UserManagment.CurrentUser.AddedDate)}}</div>
  <!-- PhoneNumber -------------------------- -->
  <!-- <div class="CookiesRowSplitter"/>
  <div class="M-Auto">{{$store.state.UserManagment.CurrentUser.PhoneNumber}}</div> -->
  <!-- Email -------------------------- -->
  <!-- <div class="CookiesRowSplitter"/>
  <div class="M-Auto">{{$store.state.UserManagment.CurrentUser.Email}}</div> -->
  <!-- Button -------------------------- -->
  <div class="CookiesRowSpacer"/>
  <div class="W-100 F1em D-Flex FlexCenter">
    <CookiesButton class="F1_25em" @click="EditUser($store.state.UserManagment.CurrentUser)">{{Translate('Edit')}}</CookiesButton>
  </div>
</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Account Settings',
  data () {
    return {}
  },
  computed: {},
  methods: {
    EditUser (User) {
      this.OpenDialog('Security_User_Dialog', this.Translate('Edit User') + ' (' + User.Name + ')', { Data: User, Editing: true }, {})
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.CookiesAuthorization()
      }
    }
  }
}
</script>
