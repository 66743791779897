
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as Warehouse,
      Saving: false,
      Loading: [] as Array<'Loading'>,
      CookiesData: {} as any,
      Branches: [] as any,
      ErrorsVisibilty: false,
      CurrentImage: undefined as File | undefined,
      DialogMapper: [
        { Path: 'Name', Res: 'D_Warehouse_Name', Default: '' },
        { Path: 'Code', Res: 'D_Warehouse_Code', Default: '' },
        { Path: 'Volume', Res: 'D_Warehouse_Volume', Default: 1 },
        { Path: ['Branch', 'Name'], Value: this.$store.state.BranchManagment.CurrentBranch?.ID, SavingPath: 'BranchID' },
        { Path: 'Description', Res: 'D_Warehouse_Description', Default: '' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData () {
      // this.ReadWarehouse()
      this.ReadBranches()
    },
    ReadBranches () {
      this.Loading.push('Loading')
      this.Get('Branches').then(response => {
        this.Branches = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Inventory_Warehouses',
        OriginalData: this.OriginalData,
        PatchAPI: 'Inventory_Warehouses',
        Editing: this.DialogData?.Editing,
        PatchQuery: this.DialogData?.Editing ? '/' + this.DialogData?.Data.ID : ''
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    }
  },
  created () {
  },
  mounted () {
    if (this.DialogData?.Data) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data) as Warehouse
    }
    this.ReadDialogData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_Warehouse')
  },
  computed: {}
})
