
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {}
  },
  // pleaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaase remove
  // pleaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaase remove
  // pleaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaase remove
  // pleaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaase remove
  created () {
    this.SaveBranch({ IsAll: true })
  },
  // pleaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaase remove
  // pleaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaase remove
  // pleaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaase remove
  // pleaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaase remove
  computed: {
    LanguagesArray () {
      if (!this.$store.state.BranchManagment.AvailableBranches) return []
      var computedArray :Array<any> = this.$store.state.BranchManagment.AvailableBranches
      return computedArray
    }
  },
  // ---------------------------------------------------
  methods: {
    SaveBranch (Branch :any) {
      this.LocalSave('SelectedBranch', Branch)
      this.$store.state.BranchManagment.CurrentBranch = Branch
      if (Branch?.ID === 2) {
        this.$store.state.CookiesMemory.CurrentWarehouse = { Name: 'الرقيم', IDs: '[9,10,11,13,14,15,16,17,18,22,23,24,26,28,29,30,32,33,34,35,36,37,38,45,null]' }
      } else {
        this.$store.state.CookiesMemory.CurrentWarehouse = undefined
      }
      if (this.DialogData?.resolve) {
        this.DialogData.resolve()
      }
      this.$router.go(-1)
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  beforeMount () {},
  mounted () {},
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})
