
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  name: 'System Config',
  data () {
    return {
      selectedFile: null,
      OriginalConfig: {} as any,
      LanguagesArray: [] as Array<any>,
      Saving: false
    }
  },
  mounted () {
    const _this = this
    Object.keys(this.Languages).some(function (Lang) {
      _this.LanguagesArray.push({
        Name: _this.Languages[Lang].Name,
        Code: Lang
      })
    })
    this.MEMWrite('SystemBackground', this.$store.state.CookiesConfig.background)
    this.MEMWrite('Config_Language', this.Languages[this.$store.state.CookiesConfig.Language])
    this.MEMWrite('Config_LanguageUserSelect', this.$store.state.CookiesConfig.LanguageUserSelect)
    // this.ReadConfig()
  },
  computed: {},
  methods: {
    ChooseTheme (theme : string) {
      this.$store.state.CookiesConfig.Theme = theme
      this.SaveConfig('Theme', theme)
    },
    LanguageChanged (value :any) {
      this.LocalSave('Language', value.Code)
      this.$store.state.CookiesConfig.Language = value.Code
      this.SaveConfig('Language', value.Code)
    },
    LanguageUserSelectChanged () {
      this.$store.state.CookiesConfig.LanguageUserSelect = this.MEMRead('Config_LanguageUserSelect')
      this.SaveConfig('LanguageUserSelect', this.MEMRead('Config_LanguageUserSelect'))
    }
    // LogoChanged () {
    //   var file = event.target.files[0]
    //   this.selectedFile = file
    //   if (!file) {
    //     return
    //   }
    //   this.EditConfig('Logo', file)
    // }
  }
})
