import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bbb9bd9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Container FlexColumn H-100 P-Abs" }
const _hoisted_2 = { class: "Map P-Rel" }
const _hoisted_3 = { class: "F1_5em P-Abs LocationButton Shadow1" }
const _hoisted_4 = ["ToolTip"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!
  const _component_l_tile_layer = _resolveComponent("l-tile-layer")!
  const _component_l_marker = _resolveComponent("l-marker")!
  const _component_l_map = _resolveComponent("l-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CopyLocation())),
            class: "CookiesButton"
          }, _toDisplayString(_ctx.Translate('Copy Location')), 1)
        ]),
        _createElementVNode("div", {
          class: "LeafLetCurrentLocation Shadow1",
          ToolTip: _ctx.Translate('Your Location'),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ResetLocation()))
        }, [
          _createVNode(_component_CookiesIcon, { ID: ('Location' as IconsIDs) })
        ], 8, _hoisted_4),
        _createVNode(_component_l_map, {
          ref: "map",
          zoom: _ctx.zoom,
          center: _ctx.markerLatLng ? _ctx.markerLatLng : [31.9522, 35.9375],
          onClick: _ctx.handleMapClick
        }, {
          default: _withCtx(() => [
            _createVNode(_component_l_tile_layer, { url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" }),
            (_ctx.CheckmarkerLatLng)
              ? (_openBlock(), _createBlock(_component_l_marker, {
                  key: 0,
                  "lat-lng": _ctx.CheckmarkerLatLng
                }, null, 8, ["lat-lng"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["zoom", "center", "onClick"])
      ])
    ])
  ]))
}