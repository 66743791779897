
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  setup () {},
  mixins: [CookiesCoreMixinVue],
  data () {
    return {
      HeaderImage: ''
    }
  },
  mounted () {
    var Sting = '@media print { @page{ size: A4 Landscape !important; } }'
    if (this.PrintingOptions.PageSize !== undefined) {
      Sting = '@media print { @page{ size: ' + this.PrintingOptions.PageSize + ' !important; } }'
    }
    var PrintingStyleElement = document.getElementById('PrintingStyle')
    var PrintingPlaceElement = document.getElementById('PrintingPlace')
    if (PrintingStyleElement) {
      PrintingStyleElement.innerHTML = Sting
    }
    if (PrintingPlaceElement) {
      PrintingPlaceElement.append(this.$store.state.Printing)
    }
    try {
      window.scrollTo(0, 0)
    } catch (error) {}
    setTimeout(() => {
      this.Print()
    }, 200)
    // this.Print()
    // setTimeout(() => {
    //   this.$store.state.PrintingOptions = {}
    //   this.$store.state.Printing = ''
    //   if (PrintingPlaceElement) {
    //     PrintingPlaceElement.innerHTML = ''
    //   }
    // }, 1000)
  },
  beforeCreate () {
    require('@/assets/icons/GoldenCustoms.svg')
  },
  methods: {
    Print () {
      window.print()
    },
    ClosePrint () {
      this.$store.state.PrintingOptions = {}
      this.$store.state.Printing = ''
      var PrintingPlaceElement = document.getElementById('PrintingPlace')
      if (PrintingPlaceElement) {
        PrintingPlaceElement.innerHTML = ''
      }
    }
  },
  computed: {
    PrintingOptions () :PrintingOptions {
      var DefaultOptions = {
        // MinWidth: '20cm',
        // MaxWidth: '27cm',
        FontSize: '8pt',
        Padding: '0.2cm',
        Margin: '3px auto',
        PageSize: 'A4 portrait',
        Header: true
      }
      var CurrentOptions = {
        ...DefaultOptions,
        ...this.$store.state.PrintingOptions
      }
      return CurrentOptions
    },
    PrintingScrollAdjuster () {
      if (this.$store.state.Printing !== '') {
        return 'visible !important'
      } else {
        return 'visible !important'
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3445bcee": (_ctx.PrintingOptions.MinWidth),
  "c3b74dc8": (_ctx.PrintingOptions.MaxWidth),
  "b6f752f0": (_ctx.PrintingOptions.Margin),
  "ec1af312": (_ctx.PrintingOptions.Padding),
  "c7dcb96c": (_ctx.PrintingOptions.FontSize)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__