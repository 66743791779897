
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  name: 'Cookies Dialog',
  components: {},
  props: {},
  methods: {
    HeaderChecker (Options ?:DialogCustomOptions) {
      try {
        if (Options && (Options.header === undefined || Options.header)) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return true
      }
    },
    BackgroundChecker (Options ?:DialogCustomOptions) {
      try {
        if (Options && Options.background) {
          return Options.background + ';'
        } else {
          return ''
        }
      } catch (error) {
        return ''
      }
    },
    FullWindowChecker (Options ?:DialogCustomOptions) {
      try {
        if (Options && Options.FullWindow) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    }
  },
  computed: {
    CurrentDialogs () :Array<DialogObjInterface> {
      return this.$store.state.CookiesDialog.OpenedDialogs
    },
    ErrorDialogs () :Array<DialogObjInterface> {
      return this.$store.state.CookiesDialog.ErrorDialogs
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "f13489da": (_ctx.Theme.BackBlur),
  "61a40791": (_ctx.Theme.BackShader),
  "18b988b0": (_ctx.$store.getters.Direction),
  "1d78386b": (_ctx.Theme.BG5),
  "7c355041": (_ctx.Theme.Light2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__