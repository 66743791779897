import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "CookiesRow W-75",
  style: {"margin-top":"4em","font-size":"1.1em","justify-content":"center","gap":"2em","flex-direction":"column"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesCompanyCard = _resolveComponent("CookiesCompanyCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Companies, (Company) => {
      return (_openBlock(), _createBlock(_component_CookiesCompanyCard, {
        key: (Company.ID),
        Company: Company
      }, null, 8, ["Company"]))
    }), 128)),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.NewCompany())),
      class: "DialogHeaderButton BGMain F1_25em CookiesFade D-Flex"
    }, _toDisplayString(_ctx.Translate('New Company')), 1)
  ]))
}