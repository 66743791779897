const routes :Array<CookiesRouter> = [
  {
    path: '/Sales',
    name: 'Sales',
    meta: {
      Access: 'Sales_View' as AccessFeature
    },
    component: () => import('@/Apps/Sales/views/Sales.vue'),
    children: [
      {
        path: '/Sales/Orders',
        name: 'View Orders',
        component: () => import('@/Apps/Sales/views/Sales_Orders.vue'),
        meta: {
          Access: 'Sales_Orders_Read' as AccessFeature,
          LoginHeader: true
        }
      },
      {
        path: '/Sales/Customers',
        name: 'View Customers',
        component: () => import('@/Apps/CRM/views/Customers.vue'),
        meta: {
          Access: 'CRM_Customers_Read' as AccessFeature,
          LoginHeader: true
        }
      }
    ]
  }
]

export const SalesRoutes = routes
