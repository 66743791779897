import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "DialogSection FlexColumn",
  style: {"min-width":"100%"}
}
const _hoisted_2 = {
  key: 1,
  class: "DialogSection P_75em"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesNormalInput = _resolveComponent("CookiesNormalInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.Editing)
      ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
          _createVNode(_component_CookiesNormalInput, {
            Name: 'NewPassword',
            Placeholder: _ctx.Translate('New Password'),
            type: 'password',
            CookiesInvalidInput: !_ctx.PasswordValidator(_ctx.MEMRead(_ctx.Res)) && !_ctx.Disabled,
            ErrorData: (_ctx.ErrorsVisibilty && !_ctx.PasswordValidator(_ctx.MEMRead(_ctx.Res))) && !_ctx.Disabled,
            Res: _ctx.Res,
            Default: ''
          }, null, 8, ["Placeholder", "CookiesInvalidInput", "ErrorData", "Res"]),
          _createVNode(_component_CookiesNormalInput, {
            Name: 'ConfirmNewPassword',
            Placeholder: _ctx.Translate('Confirm New Password'),
            type: 'password',
            CookiesInvalidInput: (_ctx.MEMRead(_ctx.Res) !== _ctx.MEMRead(_ctx.Res + '_Confirm')) && !_ctx.Disabled,
            ErrorData: (_ctx.ErrorsVisibilty && _ctx.MEMRead(_ctx.Res) !== _ctx.MEMRead(_ctx.Res + '_Confirm')) && !_ctx.Disabled,
            Res: _ctx.Res + '_Confirm',
            Default: ''
          }, null, 8, ["Placeholder", "CookiesInvalidInput", "ErrorData", "Res"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.Editing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("strong", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('ChangePassword'))),
            class: "colorFourth M-Auto Pointer"
          }, _toDisplayString(_ctx.Translate('Change Password')), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}