
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    ID: { type: String as PropType<IconsIDs> },
    Color: { type: Object as PropType<IconColor> },
    IconsSheet: { default: require('@/assets/icons/ThemeIcons.svg') }
  },
  methods: {
    ColorNotValid (color?:string) {
      return color === undefined || color === null || color === ''
    }
  },
  computed: {
    Styler () {
      if (this.Color === undefined) {
        return '--ColorA: ' + this.Theme.color4 + ';' + '--ColorB: ' + this.Theme.color5 + ';' + '--ColorMain: ' + this.Theme.UserColors.main + ';' + '--ColorSecond: ' + this.Theme.UserColors.second + ';'
      } else if (typeof this.Color === 'string') {
        return '--ColorA: ' + this.Color + ';' + '--ColorB: ' + this.Color + ';' + '--ColorMain: ' + this.Color + ';' + '--ColorSecond: ' + this.Color + ';'
      } else {
        var ColorA: string | undefined
        var ColorB: string | undefined
        var ColorMain: string | undefined
        var ColorSecond: string | undefined
        try {
          ColorA = this.Color.ColorA
        } catch (error) {
        }
        try {
          ColorB = this.Color.ColorB
        } catch (error) {
        }
        try {
          ColorMain = this.Color.ColorMain
        } catch (error) {
        }
        try {
          ColorSecond = this.Color.ColorSecond
        } catch (error) {
        }
        if (this.ColorNotValid(ColorA)) {
          ColorA = this.Theme.color4
        }
        if (this.ColorNotValid(ColorB)) {
          ColorB = this.Theme.color5
        }
        if (this.ColorNotValid(ColorMain)) {
          ColorMain = this.Theme.UserColors.main
        }
        if (this.ColorNotValid(ColorSecond)) {
          ColorSecond = this.Theme.UserColors.second
        }
        return '--ColorA: ' + ColorA + ';' + '--ColorB: ' + ColorB + ';' + '--ColorMain: ' + ColorMain + ';' + '--ColorSecond: ' + ColorSecond + ';'
      }
    }
  }
})
