
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    if (!this.MEMRead('V_Items_ViewType')) this.MEMWrite('V_Items_ViewType', 'Tile')
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      AvailableItems: [] as Array<any>,
      CookiesData: [] as Array<InventoryItem>,
      Categories: [] as Array<Category>,
      Brands: [] as Array<Category>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {
        Lists: [
          {
            Name: 'Category',
            Visible: true,
            Options: this.Categories,
            Selectors: ['Name'],
            Res: 'InventoryItems_Filter_Categories',
            DataPath: ['CategoryID'],
            ListPath: ['ID']
          },
          {
            Name: 'Brand',
            Visible: true,
            Options: this.Brands,
            Selectors: ['Name'],
            Res: 'InventoryItems_Filter_Brands',
            DataPath: ['BrandID'],
            ListPath: ['ID']
          }
        ],
        Checks: [
          {
            GroupName: 'Status',
            Checks: [
              {
                Name: this.Translate('Active'),
                Visible: this.Access('Inventory_Items_Read'),
                Icon: 'Done',
                Res: 'Users_Filter_Active',
                Value: 'Active',
                DataPath: ['Status']
              },
              {
                Name: this.Translate('Inactive'),
                Visible: this.Access('Inventory_Items_Read'),
                Icon: 'Inactive',
                Res: 'Users_Filter_Inactive',
                Value: 'Inactive',
                DataPath: ['Status']
              }
            ]
          }
        ]
      }
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Item',
          this: this,
          Function: 'NewItem',
          Icon: 'Add',
          Visible: this.Access('Inventory_Items_Create')
        },
        {
          Name: 'Edit Categories',
          this: this,
          Function: 'EditCategories',
          Icon: 'Edited',
          Visible: this.Access('Main_Categories_Edit')
        },
        {
          Name: 'Upload csv',
          this: this,
          Function: 'MassUpload',
          Icon: 'Reports',
          Visible: this.Access('SuperAdmin')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: { Direction: -1, Path: 'AddedDate' },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Image',
            Path: 'ImgUrl',
            Style: 'width: 2em',
            ImageClass: 'Shadow1'
          },
          {
            Type: 'Normal',
            Name: 'Code',
            Path: ['Code'],
            Sort: ['Code'],
            Bold: true,
            Class: 'F1_25em',
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            IsSearchable: true
          },
          {
            Type: 'Multi',
            Name: 'Specs',
            Path: ['Specifications'],
            MultiFields: {
              Path: 'Name',
              Secondary: {
                Path: ['Description'],
                Bold: true
              }
            },
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Brand',
            Path: ['Brand', 'Name'],
            Sort: ['Brand', 'Name'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Category',
            Path: ['Category', 'Name'],
            Sort: ['Category', 'Name'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Unit Volume',
            Path: ['Volume'],
            Sort: ['Volume'],
            ToolTip: this.VolumeUnit,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Pack Size',
            Path: ['PackSize'],
            Sort: ['PackSize'],
            ToolTip: this.Translate('Unit'),
            IsSearchable: true
          },
          {
            Type: 'Bar',
            Name: 'Available',
            Path: ['AvailableQuantity'],
            Sort: ['AvailableQuantity'],
            BarMinFixed: 0,
            BarMaxPath: 'QuantityMax',
            BarValues: [
              {
                Name: this.Translate('Available'),
                ValuePath: 'AvailableQuantity'
              },
              {
                Name: this.Translate('Ordered'),
                ValuePath: 'OrderedQuantity'
              },
              {
                Name: this.Translate('Reserved'),
                ValuePath: 'ReservedQuantity'
              }
            ],
            Style: 'min-width: 4em;'
          },
          {
            Type: 'ActionIcons',
            Class: 'F1_25em Pointer',
            ActionIcons: [
              {
                Style: 'cursor: unset !important; font-size: 0.75em',
                ID: 'Search',
                Emits: 'ShowLocations',
                ToolTip: 'Show Locations'
              },
              {
                Style: 'cursor: unset !important; font-size: 0.75em',
                ID: 'Recover',
                Emits: 'ViewItemHistory',
                ToolTip: 'History'
              }
            ]
          },
          {
            Type: 'ActionIcons',
            Style: 'white-space: nowrap',
            ActionIcons: [
              {
                Style: 'cursor: unset !important; font-size: 0.75em',
                ID: 'Info',
                DynamicToolTip: ['Description'],
                DynamicVisiblity: {
                  FixedValue: '',
                  Operator: 'NOT EQUAL LOWERCASE',
                  ValuePath: 'Description'
                }
              },
              {
                ID: 'Inactive',
                Visible: this.Access('Inventory_Items_Edit'),
                ToolTip: this.Translate('Activate'),
                Emits: 'SwapStatus',
                DynamicVisiblity: {
                  FixedValue: 'Active',
                  Operator: 'NOT EQUAL',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Inactive',
                Visible: this.Access('Inventory_Items_Edit'),
                ToolTip: this.Translate('Deactivate'),
                Style: 'filter: saturate(0); opacity: 0.5;',
                Emits: 'SwapStatus',
                DynamicVisiblity: {
                  FixedValue: 'Active',
                  Operator: 'EQUAL',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Edit',
                Visible: this.Access('Inventory_Items_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditItem'
              },
              {
                ID: 'Delete',
                Emits: 'DeleteItem',
                Visible: this.Access('Inventory_Items_Remove'),
                ToolTip: this.Translate('Remove')
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    },
    DecodedAval () {
      return this.AvailableItems.map((item :any) => {
        item.DecodedLoc = (item.WarehouseName && item.WarehouseName !== '' ? item.WarehouseName : '') + `(${item.WarehouseCode})` + (item.SectionLevel1ID ? (' > ' + (item.Section1Name && item.Section1Name !== '' ? item.Section1Name : '') + `(${item.Section1Code})`) : '') + (item.SectionLevel2ID ? (' > ' + (item.Section2Name && item.Section2Name !== '' ? item.Section2Name : '') + `(${item.Section2Code})`) : '') + ' > ' + (item.SectionName && item.SectionName !== '' ? item.SectionName : '') + `(${item.SectionCode})`
        return item
      })
    }
  },
  methods: {
    ReadCookiesData () {
      // this.ReadCategories()
      this.ReadAvailableItems()
      // this.ReadBrands()
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Inventory_Items', (this.MEMRead('CurrentWarehouse') ? '?WarehouseID=' + this.MEMRead('CurrentWarehouse').IDs : '')).then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Items'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadAvailableItems () {
      this.Loading.push('Loading')
      this.Get('Inventory_AvailableItems', '?Type=Available').then(response => {
        this.AvailableItems = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ShowLocations (Item :any) {
      console.log(Item)
      console.log(this.DecodedAval)
      var ItemAval = this.DecodedAval.filter((item :any) => {
        return Item.ID === item.ItemID
      })
      console.log(ItemAval)
      this.ViewLocDialog(ItemAval, Item.ID)
    },
    ViewLocDialog (ItemAval :any, ID: number) {
      this.OpenDialog('Inventory_AvailablItemLocations_Dialog', 'Locations', { Data: ItemAval, ItemID: ID }, { NoBackgroundClose: true, header: false })
    },
    ViewItemHistory (Item :any) {
      this.OpenDialog('Inventory_ItemHistory_Dialog', 'Item History', { Data: { ID: Item.ID } }, { NoBackgroundClose: true, header: false })
    },
    ReadCategories () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Items').then(response => {
        this.Categories = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBrands () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Brands').then(response => {
        this.Brands = this.GeneralSort(response, 'Name', 1)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditItem (Item :InventoryItem) {
      if (!this.Access('Inventory_Items_Edit')) {
        return
      }
      this.OpenDialog('Inventory_Item_Dialog', this.Translate('Edit Item') + ' (' + Item.Name + ') ', { Data: Item, Editing: true }, {})
    },
    DeleteItem (Item :InventoryItem) {
      if (!this.Access('Inventory_Items_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Inventory_Items', AddQuery: '/' + Item.ID, Text: 'Are you sure you want to delete <strong>' + Item.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.UserDeleted, AfterDialogFunctionValue: Item.ImgUrl } as GeneralAcceptDialogData, {})
    },
    UserDeleted (ImgUrl :string) {
      if (ImgUrl) this.DeleteImage(ImgUrl)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewItem':
          this.OpenDialog('Inventory_Item_Dialog', this.Translate('New Item'), { Data: {} })
          break
        case 'MassUpload':
          this.OpenDialog('Inventory_ItemMass_Dialog', this.Translate('Mass Import'), { Data: {} })
          break
        case 'EditCategories':
          this.OpenDialog('Main_Categories_Dialog', this.Translate('New Item'), { Data: {}, Type: 'Items' })
          break
      }
    },
    SwapStatus (Item :InventoryItem) {
      this.Loading.push('Loading')
      var CurrentStatus = Item.Status
      var PatchValue :any
      if (CurrentStatus === 'Active') {
        PatchValue = this.CookiesPatcher({ Status: 'Active' }, { Status: 'Inactive' })
      } else {
        PatchValue = this.CookiesPatcher({ Status: 'Inactive' }, { Status: 'Active' })
      }
      this.Post('PATCH', 'Inventory_Items', PatchValue, '/' + Item.ID).then(response => {
        this.ReadCookiesData()
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in editing status'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
