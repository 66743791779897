
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  name: 'System Config',
  data () {
    return {
      Companies: [] as Array<any>,
      Loading: [] as Array<'Loading'>,
      Saving: false
    }
  },
  mounted () {
    this.Companies = this.$store.state.CompanyManagment.AvailableCompanies as any
  },
  computed: {
  },
  methods: {
    NewCompany () {
      if (this.Access('Main_Companies_Create')) {
        this.OpenDialog('Company_Dialog', this.Translate('New Company'), {}, { NoBackgroundClose: true })
      }
    },
    ReadData () {
      this.Get('Authorization').then(response => {
        this.$store.state.CompanyManagment.AvailableCompanies = response?.UserCompanies
        this.Companies = response?.UserCompanies
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadData()
      }
    }
  }
})
