
const routes :Array<CookiesRouter> = [
  {
    path: '/HR/Employee/:EmployeeID',
    name: 'Employee View',
    component: () => import('@/Apps/HR/views/Employee.vue'),
    meta: {
      Access: 'Main_Public'
    }
  },
  {
    path: '/HR',
    name: 'HR',
    redirect: '/HR/Employees',
    component: () => import('@/Apps/HR/views/HR.vue'),
    meta: {
      Access: 'HR_Employees_Read'
    },
    children: [
      {
        path: '/HR/Employees',
        name: 'Employees',
        component: () => import('@/Apps/HR/views/Employees.vue'),
        meta: {
          Access: 'HR_Employees_Read'
        }
      },
      {
        path: '/HR/ArchivedEmployees',
        name: 'ArchivedEmployees',
        component: () => import('@/Apps/HR/views/ArchivedEmployees.vue'),
        meta: {
          Access: 'HR_Employees_Read'
        }
      },
      {
        path: '/HR/DailyEmployees',
        name: 'DailyEmployees',
        component: () => import('@/Apps/HR/views/DailyEmployees.vue'),
        meta: {
          Access: 'HR_Employees_Read'
        }
      },
      {
        path: '/HR/Departments',
        name: 'Departments',
        component: () => import('@/Apps/HR/views/Departments.vue'),
        meta: {
          Access: 'HR_Departments_Read'
        }
      },
      {
        path: '/HR/Positions',
        name: 'Positions',
        component: () => import('@/Apps/HR/views/Positions.vue'),
        meta: {
          Access: 'HR_Positions_Read'
        }
      },
      {
        path: '/HR/Warnings',
        name: 'Warnings',
        component: () => import('@/Apps/HR/views/Warnings.vue'),
        meta: {
          Access: 'HR_Warnings_Read'
        }
      },
      {
        path: '/HR/Deductions',
        name: 'Deductions',
        component: () => import('@/Apps/HR/views/Deductions.vue'),
        meta: {
          Access: 'HR_Deductions_Read'
        }
      },
      {
        path: '/HR/Attendance',
        name: 'Attendance',
        component: () => import('@/Apps/HR/views/Attendance.vue'),
        meta: {
          Access: 'HR_Attendance_Read'
        }
      },
      {
        path: '/HR/AttendanceRequest',
        name: 'Attendance Request',
        component: () => import('@/Apps/HR/views/AttendanceRequest.vue'),
        meta: {
          Access: 'HR_AttendanceRequests_Read'
        }
      },
      {
        path: '/HR/HRSettings',
        name: 'HR Settings',
        component: () => import('@/Apps/HR/views/HRSettings.vue'),
        meta: {
          Access: 'HR_Settings_View'
        }
      }
    ]
  }
]

export const HRRoutes = routes
