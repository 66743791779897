
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<InventoryTransfer>,
      Branches: [] as Array<Category>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {
        Lists: [
          {
            Name: 'Type',
            Visible: true,
            Options: [{ Type: 'Export' }, { Type: 'Import' }],
            Selectors: ['Type'],
            Res: 'ImportExport_Filter',
            DataPath: ['Type'],
            ListPath: ['Type']
          }
        ],
        Checks: [
          {
            GroupName: 'Status',
            Checks: [
              {
                Name: 'New',
                Visible: this.Access('Inventory_Transfers_Read'),
                Res: 'Users_Filter_New',
                Value: 'New',
                DataPath: ['Status']
              },
              {
                Name: 'Importing',
                Visible: this.Access('Inventory_Transfers_Read'),
                Res: 'Users_Filter_Importing',
                Value: 'Importing',
                DataPath: ['Status']
              },
              {
                Name: 'Exporting',
                Visible: this.Access('Inventory_Transfers_Read'),
                Res: 'Users_Filter_Exporting',
                Value: 'Exporting',
                DataPath: ['Status']
              },
              {
                Name: 'Done',
                Visible: this.Access('Inventory_Transfers_Read'),
                Res: 'Users_Filter_Done',
                Value: 'Done',
                DataPath: ['Status']
              }
              // {
              //   Name: 'Canceled',
              //   Visible: this.Access('Inventory_Transfers_Read'),
              //   Res: 'Users_Filter_Close',
              //   Value: 'Canceled',
              //   DataPath: ['Status']
              // }
            ]
          }
        ],
        Dates: true
      }
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'Import',
          this: this,
          Function: 'NewImport',
          Icon: 'ItemImport',
          Visible: this.Access('Inventory_Transfers_Import')
        },
        {
          Name: 'Export',
          this: this,
          Function: 'NewExport',
          Icon: 'Export',
          Visible: this.Access('Inventory_Transfers_Export')
        },
        {
          Name: 'Internal Transfer',
          this: this,
          Function: 'InternalTransfer',
          Icon: 'Leveling',
          Visible: this.Access('Inventory_Transfers_Internal')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: { Direction: -1, Path: ['AddedDate'] },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'NO',
            Path: ['TransferNO'],
            Sort: ['TransferNO'],
            Bold: true,
            Class: 'F1_25em',
            Hash: '#'
          },
          {
            Type: 'DateTime',
            Path: 'AddedDate',
            Name: 'Date',
            Sort: 'AddedDate'
          },
          {
            Type: 'DateTime',
            Path: 'AddedDate',
            Name: 'Time',
            Sort: 'AddedDate',
            DateStyle: 'Time'
          },
          {
            Type: 'ActionIcons',
            Path: 'Type',
            Name: 'Type',
            Sort: 'Type',
            Style: 'width: 2em;',
            ActionIcons: [
              {
                ID: 'ItemImport',
                ToolTip: this.Translate('Import'),
                DynamicVisiblity: {
                  FixedValue: 'Import',
                  ValuePath: 'Type',
                  Operator: 'EQUAL LOWERCASE'
                },
                Style: 'cursor: default;'
              },
              {
                ID: 'Export',
                ToolTip: this.Translate('Export'),
                DynamicVisiblity: {
                  FixedValue: 'Export',
                  ValuePath: 'Type',
                  Operator: 'EQUAL LOWERCASE'
                },
                Style: 'cursor: default;'
              }
            ]
          },
          {
            Type: 'Normal',
            Name: 'From',
            Path: ['SupplierName'],
            Sort: ['SupplierName']
          },
          {
            Type: 'Normal',
            Name: 'To',
            Path: ['CustomerName'],
            Sort: ['CustomerName']
          },
          {
            Type: 'Multi',
            Path: 'Items',
            Name: 'Item (Pack) Quantity',
            MultiFields: {
              Path: ['ItemCode'],
              Bold: true,
              Secondary: {
                Path: 'PackSize',
                Class: 'F_85em'
              },
              Quantity: 'Quantity'
            },
            Style: 'width: 2em;'
          },
          {
            Type: 'StatusBox',
            Name: 'Status',
            Path: 'Status',
            Sort: 'Status',
            StatusClassColors: {
              New: 'BGMain',
              Importing: 'BGMain',
              Done: 'BGGreen'
            },
            StatusStyleColors: {
              Importing: 'yellow',
              Exporting: 'yellow',
              Done: 'var(--colorg2)',
              undefined: ''
            },
            Bold: true
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                Style: 'cursor: unset !important; font-size: 0.75em;',
                ID: 'Info',
                DynamicToolTip: ['Notes'],
                DynamicVisiblity: {
                  FixedValue: '',
                  Operator: 'NOT EQUAL LOWERCASE',
                  ValuePath: 'Notes'
                }
              },
              {
                ID: 'Done',
                Visible: this.Access('Inventory_Transfers_Edit'),
                ToolTip: 'Importing Done',
                Emits: 'ImportDone',
                DynamicVisiblity: {
                  FixedValue: 'Importing',
                  Operator: 'EQUAL',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Select',
                Visible: this.Access('Inventory_Transfers_Edit'),
                ToolTip: 'Check Items',
                Emits: 'ItemsChecked',
                DynamicVisiblity: {
                  FixedValue: true,
                  Operator: 'EQUAL',
                  ValuePath: 'StatusDecoded'
                }
              },
              {
                ID: 'Select',
                Visible: this.Access('Inventory_Transfers_Edit'),
                ToolTip: 'Check Items',
                Emits: 'ItemsChecked',
                DynamicVisiblity: {
                  FixedValue: 'Exporting',
                  Operator: 'EQUAL',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Printer',
                Visible: this.Access('Inventory_Transfers_Edit'),
                ToolTip: 'Print',
                Emits: 'PrintExport',
                DynamicVisiblity: {
                  FixedValue: true,
                  Operator: 'EQUAL',
                  ValuePath: 'StatusExportDecoded'
                },
                Style: 'filter: Saturate(0) Brightness(1.5) contrast(1);'
              },
              {
                ID: 'Expand',
                Visible: this.Access('Inventory_Transfers_Edit'),
                ToolTip: 'View',
                Emits: 'ViewDialog'
              },
              {
                ID: 'Edit',
                Visible: this.Access('Inventory_Transfers_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditTransfer',
                DynamicVisiblity: {
                  FixedValue: 'Done',
                  Operator: 'NOT EQUAL',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Delete',
                Emits: 'DeleteTransfer',
                Visible: this.Access('Inventory_Transfers_Remove'),
                ToolTip: this.Translate('Remove'),
                DynamicVisiblity: {
                  FixedValue: 'New',
                  Operator: 'EQUAL',
                  ValuePath: 'Status'
                }
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      this.CookiesData.forEach((element :any) => {
        element.StatusDecoded = (element.Type === 'Import' || element.Type === 'ExternalImport') && element.Status === 'New'
        element.StatusExportDecoded = element.Type === 'Export' && element.Status === 'New'
      })
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      this.ReadBranches()
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Inventory_Transfers', '?' + this.DateAPIURL).then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Transfers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBranches () {
      this.Loading.push('Loading')
      this.Get('Branches').then(response => {
        this.Branches = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ItemsChecked (Transfer :InventoryTransfer) {
      this.OpenDialog('Inventory_TransferCheck_Dialog', 'Checking Items', { Data: Transfer }, { NoBackgroundClose: true })
    },
    ImportDone (Transfer :InventoryTransfer) {
      this.OpenDialog('General_Accept_Dialog', 'Items Imported', { PostMethod: 'POST', PostAPI: 'Inventory_Transfers_UPDATE', AddQuery: '/' + Transfer.ID + '?Stat=Imported', Text: this.Translate('Are you sure all items imported successfully?'), ButtonText: this.Translate('Accept'), Icon: 'Done', PostData: { Transfer: Transfer } } as GeneralAcceptDialogData, { NoBackgroundClose: true })
    },
    EditTransfer (Transfer :InventoryTransfer) {
      if (!this.Access('Inventory_Transfers_Edit')) {
        return
      }
      this.OpenDialog('Inventory_Transfer_Dialog', this.Translate('Edit Transfer') + ' (' + Transfer.TransferNO + ') ', { Data: Transfer, Editing: true, Type: Transfer.Type }, { NoBackgroundClose: true })
    },
    DeleteTransfer (Transfer :InventoryTransfer) {
      if (!this.Access('Inventory_Transfers_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Inventory_Transfers', AddQuery: '/' + Transfer.ID + '?Type=' + Transfer.Type, Text: 'Are you sure you want to delete <strong>' + Transfer.ID + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    PrintImport (Transfer :InventoryTransfer) {
      this.OpenDialog('Inventory_TransferPrint_Dialog', 'Print Import', { Data: Transfer }, { NoBackgroundClose: true, FullWindow: true, background: 'none', header: false })
    },
    PrintExport (Transfer :InventoryTransfer) {
      this.OpenDialog('Inventory_TransferPrint_Dialog', 'Print Export', { Data: Transfer }, { NoBackgroundClose: true, FullWindow: true, background: 'none', header: false })
    },
    ViewDialog (Transfer :InventoryTransfer) {
      this.OpenDialog('Inventory_TransferView_Dialog', 'Print Export', { Data: Transfer }, { NoBackgroundClose: true, FullWindow: true, background: 'none', header: false })
    },
    UserDeleted (ImgUrl :string) {
      if (ImgUrl) this.DeleteImage(ImgUrl)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewImport':
          this.OpenDialog('Inventory_Transfer_Dialog', this.Translate('New Transfer'), { Data: {}, Type: 'Import' }, { NoBackgroundClose: true })
          break
        case 'NewExport':
          this.OpenDialog('Inventory_Transfer_Dialog', this.Translate('New Transfer'), { Data: {}, Type: 'Export' }, { NoBackgroundClose: true })
          break
        case 'InternalTransfer':
          this.OpenDialog('Inventory_InternalTransfer_Dialog', this.Translate('Internal Transfer'), { Data: {}, Type: 'Internal' }, { NoBackgroundClose: true })
          break
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    DateAPIURL: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
