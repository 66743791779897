
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: [] as Array<'Saving'>,
      Saved: undefined as boolean|undefined,
      EditSave: false,
      Loading: [] as Array<'Loading'>,
      CookiesData: [] as Array<WarningType>,
      ErrorsVisibilty: false
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Warning Type') + this.DialogData?.Type ? ' ' + this.DialogData?.Type : '',
        CookiesID: 'D_WarningType_Table',
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'Name',
            Sort: 'Name',
            Editable: true,
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Hour Deduction',
            Type: 'numberInput',
            Value: 'HourDeduction',
            Sort: 'HourDeduction',
            Res_ID: 'ID',
            numberInput_Step: 1,
            numberInput_Default: 0,
            Unit: this.Translate('Hour')
          },
          {
            Name: 'Salary Deduction',
            Type: 'numberInput',
            Value: 'SalaryDeduction',
            Sort: 'SalaryDeduction',
            Res_ID: 'ID',
            numberInput_Step: 5,
            numberInput_Default: 0,
            Unit: this.PriceUnit
          },
          {
            Name: 'Dedctuion Occurance',
            Type: 'numberInput',
            Value: 'DedctuionOccurance',
            Sort: 'DedctuionOccurance',
            Res_ID: 'ID',
            numberInput_Step: 1,
            numberInput_Default: 1
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      Table.Fields.push({
        Name: 'Delete',
        Value: '',
        Type: 'delete',
        NoHeader: true
      })
      return Table
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadWarningType()
    },
    ReadWarningType () {
      this.Loading.push('Loading')
      this.Get('HR_WarningType').then(response => {
        this.CookiesData = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Warning Type'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewWarning () {
      this.Post('POST', 'HR_WarningType', {
        Name: this.DialogData?.Name,
        HourDeduction: this.DialogData?.HourDeduction,
        SalaryDeduction: this.DialogData?.SalaryDeduction,
        DedctuionOccurance: this.DialogData?.DedctuionOccurance
      }).then(() => {
        this.ReadDialogData()
      })
    },
    FieldEdited (item :any) {
      this.Saved = undefined
      this.Saving.push('Saving')
      var Original = this.CookiesData.find((cat :WarningType) => {
        return cat.ID === item.ID
      })
      var Patch = this.CookiesPatcher(Original, item)
      this.Post('PATCH', 'HR_WarningType', Patch, '/' + item.ID).then((response) => {
        this.Saving.shift()
        this.Saved = true
        this.ReadWarningType()
      }, error => {
        this.Saving.shift() // Remove Loading flag
        this.Saved = false
        console.log(error)
      })
    },
    InputChanged (item :{ field :any, element :any, value: any }) {
      this.Saved = undefined
      this.Saving.push('Saving')
      var Original = this.CookiesData.find((cat :WarningType) => {
        return cat.ID === item.element.ID
      })
      this.DeepChanger(item.element, item.field.Value, item.value)
      var Patch = this.CookiesPatcher(Original, item.element)
      this.Post('PATCH', 'HR_WarningType', Patch, '/' + item.element.ID).then((response) => {
        this.Saving.shift()
        this.Saved = true
      }, error => {
        this.Saving.shift() // Remove Loading flag
        this.Saved = false
        console.log(error)
      })
    },
    RemoveItem (Item :WarningType, index:number) {
      if (!this.Access('HR_WarningType_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_WarningType', AddQuery: '/' + Item.ID, Text: 'Are you sure you want to delete <strong>' + Item.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.AfterRemove, AfterDialogFunctionValue: Item } as GeneralAcceptDialogData, {})
    },
    AfterRemove (Item :WarningType) {
      this.ReadDialogData()
    }
  },
  mounted () {
    this.ReadDialogData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_WarningType')
  }
})
