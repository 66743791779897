
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
import stringSimilarity from 'string-similarity'

export default defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    DialogIndex: Number,
    DialogData: { default: {} as DialogData }
  },
  data () {
    return {
      Saving: false,
      Loading: [] as Array<'Loading'>,
      ErrorsVisibilty: false,
      MoreDetails: false,
      TransferItems: [] as Array<any>,
      ExportItems: [] as Array<any>,
      AvailableItems: [] as any,
      Sections: [] as any,
      ItemsLocation: [] as any,
      ImportLocations: {} as any
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'D_TransferCheck_Table',
        ResID: 'ID',
        MultiLevel: true,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Customer',
            Type: 'normal',
            Value: 'CustomerName'
          },
          {
            Name: 'TransferNO',
            Type: 'normal',
            Value: 'TransferNO'
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'Code',
            Bold: true
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'ItemName'
          },
          {
            Name: 'Brand',
            Type: 'normal',
            Value: ['Brand', 'Name']
          },
          {
            Name: 'Category',
            Type: 'normal',
            Value: ['Category', 'Name']
          },
          {
            Name: 'Details',
            Type: 'normal',
            Value: 'Details',
            Class: 'F1_25em'
          },
          {
            Name: 'Specs',
            Type: 'array-normal',
            Value: 'Specifications',
            MultiValue: 'Description'
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize',
            Visible: this.DialogData?.Data.Status !== 'Done' || (this.DialogData?.Data.Type !== 'Export' && this.DialogData?.Data.Type !== 'BranchesTransfer')
          },
          {
            Name: 'Quantity',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Code'
        }
      } as CookiesTable
      return Table
    },
    CombinedItems () {
      return this.TransferItems.filter((element :any) => {
        if (!(element.CustomerName) || !this.DialogData?.Data.CustomerName) return false
        return stringSimilarity.compareTwoStrings(element.CustomerName, this.DialogData?.Data.CustomerName) > this.DialogData?.Similarity
      })
    },
    ChartData () {
      var Data: any[] = []
      this.CombinedItems.forEach(element => {
        var Found = Data.find((value) => { return element.ItemID === value.ItemID })
        if (Found) {
          Found.Quantity += element.IsPack ? element.PackSize * element.Quantity : element.Quantity
          Found.Volume += element.IsPack ? element.PackSize * element.Quantity * element.Volume : element.Quantity * element.Volume
        } else {
          Data.push({
            CategoryName: element.CategoryName,
            ItemCode: element.ItemCode,
            ItemName: element.ItemName,
            Quantity: element.IsPack ? element.PackSize * element.Quantity : element.Quantity,
            Volume: element.IsPack ? element.PackSize * element.Quantity * element.Volume : element.Quantity * element.Volume,
            ItemID: element.ItemID,
            TransferItemItemID: element.ItemID
          })
        }
      })
      return this.GeneralSort(Data, 'Volume', -1)
    }
  },
  // ---------------------------------------------------
  methods: {
    ReadItems () {
      if (this.DialogData === undefined || this.DialogData?.Data === undefined) return
      this.Loading.push('Loading')
      var ItemsIds: string[] = []
      this.CombinedItems.forEach((item :any) => {
        if (!(ItemsIds.find((value) => { return item.ItemID === value }))) {
          ItemsIds.push(item.ItemID)
        }
      })
      if (ItemsIds.length === 0) {
        this.Loading.shift()
        return
      }
      this.Get('Inventory_Items', '?ID=[' + ItemsIds + ']').then(response => {
        var ComputedItems = this.TransferItems
        if (!Array.isArray(response)) {
          response = [response]
        }
        this.TransferItems = ComputedItems.map((item :any) => {
          var CuurentItem = response.find((element :any) => {
            return element.ID === item.ItemID
          })
          if (CuurentItem) {
            item.Specifications = CuurentItem.Specifications
            if (CuurentItem.Name) item.Name = CuurentItem.Name
            item.Code = CuurentItem.Code
            item.IsUserSelect = CuurentItem.IsUserSelect
            item.Volume = CuurentItem.Volume
            item.ImportLocations = this.ImportLocations['Item_' + item.ItemID]
          }
          return item
        })
        this.ExportItems = this.AvailableItems.map((item :any) => {
          var CuurentItem = response.find((element :any) => {
            return element.ID === item.ItemID
          })
          if (CuurentItem) {
            item.Specifications = CuurentItem.Specifications
            if (CuurentItem.Name) item.Name = CuurentItem.Name
            item.Code = CuurentItem.Code
            // item.ExportLocation = item.WarehouseCode + (item.SectionLevel1 !== undefined ? (' > ' + item.SectionLevel1.Code) : '') + (item.SectionLevel2 !== undefined ? (' > ' + item.SectionLevel2.Code) : '') + ' > ' + item.SectionCode
            item.ExportLocation = (item.WarehouseName && item.WarehouseName !== '' ? item.WarehouseName : '') + `(${item.WarehouseCode})` + (item.SectionLevel1ID ? (' > ' + (item.Section1Name && item.Section1Name !== '' ? item.Section1Name : '') + `(${item.Section1Code})`) : '') + (item.SectionLevel2ID ? (' > ' + (item.Section2Name && item.Section2Name !== '' ? item.Section2Name : '') + `(${item.Section2Code})`) : '') + ' > ' + (item.SectionName && item.SectionName !== '' ? item.SectionName : '') + `(${item.SectionCode})`
            // item.ExportLocation = item.SectionLevel1,
          }
          return item
        })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadAvailableItems () {
      this.Loading.push('Loading')
      this.Get('Inventory_AvailableItems', '?TransferID=' + this.DialogData?.Data.ID).then(response => {
        this.AvailableItems = response
        console.log('AvailableItems---------------')
        console.log(this.AvailableItems)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadTransferItems () {
      if (this.DialogData === undefined || this.DialogData?.Data === undefined) return
      this.Loading.push('Loading')
      this.Get('Inventory_TransferItems', '?' + this.DateAPIURL()).then(response => {
        this.TransferItems = response
        console.log('response')
        console.log(response)
        this.ReadItems()
        // if (this.DialogData?.Type === 'InternalTransfer') {
        //   this.ReadSections()
        //   this.GetLocations()
        // }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadSections () {
      this.Loading.push('Loading')
      this.Get('Inventory_Sections').then(response => {
        this.Sections = response
        console.log(response)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Sections'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    printIT (id: string) {
      if ((this.DialogData?.Data.Type === 'Export' || this.DialogData?.Data.Type === 'BranchesTransfer') && this.DialogData?.Data.Status === 'New') {
        this.Post('POST', 'Inventory_Transfers_UPDATE', {}, '/' + this.DialogData?.Data.ID + '?Stat=Exporting').then(() => {
          this.$router.go(-1)
        }, error => {
          error.CookiesError = 'Error in updating Transfer'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
      var bodd = document.getElementById(id)
      if (!bodd) return
      if (this.$store.state.PrintingOptions && id === 'TableToPrint1') this.$store.state.PrintingOptions.FontSize = '0.95em'
      if (this.$store.state.PrintingOptions && id === 'TableToPrint2') this.$store.state.PrintingOptions.FontSize = '0.9em'
      if (this.$store.state.PrintingOptions) this.$store.state.PrintingOptions.PageSize = 'A4 portrait'
      if (this.$store.state.PrintingOptions) this.$store.state.PrintingOptions.Padding = '0em'
      if (this.$store.state.PrintingOptions) this.$store.state.PrintingOptions.MaxWidth = '100%'
      this.$store.state.Printing = bodd.cloneNode(true) as HTMLElement
    },
    GetLocations () {
      // if (!this.Transfertem || !this.MEMRead('D_Transfer_WarehouseFrom')) return
      this.Loading.push('Loading')
      this.Get('Inventory_ItemsLocation').then(response => {
        this.ItemsLocation = response
        // this.ReadItems()
        console.log(response)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.ReadAvailableItems()
    this.ReadTransferItems()
  },
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})
