
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      Acc: 0,
      OriginalData: {} as Section,
      Saving: false,
      SavingTable: [] as Array<'Saving'>,
      Loading: [] as Array<'Loading'>,
      Saved: undefined as boolean|undefined,
      CookiesData: {} as any,
      TransferItems: [] as any,
      AvailableItems: [] as any,
      IsPack: false as any,
      Items: [] as any,
      Brands: [] as any,
      Categories: [] as any,
      CookiesSpecs: [] as any,
      TransferSources: [] as any,
      ErrorsVisibilty: false,
      CurrentImage: undefined as File | undefined
    }
  },
  methods: {
    RemoveItem (Item :any) {
      this.TransferItems.splice(Item.OriginalIndex, 1)
    },
    ReadAvailable () {
      this.Loading.push('Loading')
      this.Get('Inventory_AvailableItems', '?ItemLocationID=' + this.DialogData?.ID).then(response => {
        this.AvailableItems = response
        this.TransferItems = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      // var Mapped = response.map((element :any) => {
      //   return {
      //     ItemID: element.ItemID,
      //     ItemLocationID: element.ID,
      //     Type: 'Available',
      //     IsPack: element.IsPack,
      //     PackSize: element.PackSize,
      //     Quantity: element.QuantityMax - element.AvailableQuantity - element.ReservedQuantity - element.OrderedQuantity
      //   }
      // })
      var SelectedItems = this.TransferItems.map((item :any) => {
        return {
          ItemID: item.ItemID,
          ItemLocationID: item.ID,
          Type: 'Available',
          IsPack: this.IsPack,
          Details: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Details' + '_' + item.ResID) ? this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Details' + '_' + item.ResID) : '',
          PackSize: this.IsPack ? this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack Size' + '_' + item.ResID) : 1,
          Quantity: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Quantity' + '_' + item.ResID)
        }
      })
      console.log(this.DialogData?.Item)
      console.log(SelectedItems)
      this.Post('POST', 'Inventory_AvailableItems', SelectedItems, `?RemoveOldID=${this.DialogData?.ID}`).then(response => {
        this.Loading.shift() // Remove Loading flag
        this.$router.go(-1)
      }, (error :any) => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
      this.Loading.shift() // Remove Loading flag
    },
    InputChanged (Changed :any) {
      if (!this.DialogData?.Editing) {
        return
      }
      var CurrentValue = this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + Changed.field.Name + '_' + Changed.element.ResID)
      if (this.CookiesData.Items && this.CookiesData.Items.length > 0) {
        var Found = this.CookiesData.Items.find((item :any) => {
          return item.ItemID === Changed.element.ItemID
        })
        var OriginalValue = Found[Changed.field.Value]
      }
    },
    AddItem () {
      // if (this.DialogData?.Type === 'Export' && Item.AvailableQuantity === 0) {
      //   this.MEMClean('D_Transfer_Item', true)
      //   return
      // }
      // var found = this.TransferItems.find((element :InventoryItem) => {
      //   return !Item.IsPack
      // })
      // if (this.DialogData?.Type === 'Export' && found) {
      //   this.MEMClean('D_Transfer_Item', true)
      //   return
      // }
      console.log(this.DialogData)
      var ModifiedItem :any = this.DialogData
      ModifiedItem.ResID = ModifiedItem.ID + '_' + this.Acc
      // ModifiedItem.IsPack = Dialo
      if (ModifiedItem.Quantity > 1) ModifiedItem.Quantity = 1
      this.TransferItems.push(this.DeepCloner(ModifiedItem))
      this.Acc += 1
      this.MEMClean('D_Transfer_Item', true)
      setTimeout(() => {
        const scrollpoint = document.getElementById('scrollpoint')
        if (scrollpoint) {
          scrollpoint.scrollIntoView({ block: 'nearest', inline: 'nearest' })
        }
      }, 100)
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    EditCategories (Type: string, Name: string) {
      this.OpenDialog('Main_Categories_Dialog', this.Translate('Edit ' + Name), { Data: {}, Type: Type })
    },
    ReadTransferSources () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=TransferSources').then(response => {
        this.TransferSources = response
        console.log(this.CookiesData)
        if (this.CookiesData && this.CookiesData.SupplierID) {
          var SelectedSource = this.TransferSources.find((Source :any) => {
            return Source.ID === this.CookiesData.SupplierID
          })
          if (SelectedSource) {
            this.MEMWrite('D_Transfers_ImportSource_Value', SelectedSource)
            this.MEMWrite('D_Transfers_ImportSource', SelectedSource.Name)
          }
        } else {
          this.MEMClean('D_Transfers_ImportSource')
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Sources'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  mounted () {
    // this.ReadAvailable()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    this.IsPack = this.DialogData?.IsPack
    if (!this.DialogData?.IsPack) {
      this.AddItem()
    }
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_Transfer')
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('TransferDIalogItems') + this.DialogData?.Type ? ' ' + this.DialogData?.Type : '',
        CookiesID: 'D_Transfer_Table',
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          // {
          //   Name: 'Pack',
          //   Type: 'checkBox',
          //   Value: 'IsPack',
          //   Res_ID: 'ResID',
          //   Default: false
          // },
          {
            Name: 'Pack Size',
            Type: 'numberInput',
            Value: 'PackSize',
            Res_ID: 'ResID',
            Visible: this.IsPack === true || this.IsPack === 1
          },
          {
            Name: 'Quantity',
            Type: 'numberInput',
            numberInput_Default: 1,
            numberInput_Min: this.IsPack ? 1 : 0,
            Value: 'Quantity',
            Res_ID: 'ResID',
            numberInput_Max: !this.DialogData?.IsPack ? 'QuantityMax' : undefined,
            numberInput_Step: 1
          },
          {
            Name: 'Details',
            Type: 'normal',
            Editable: true,
            Value: 'Details',
            Res_ID: 'ResID'
          },
          {
            Name: 'Delete',
            Value: '',
            Type: 'delete',
            NoHeader: true
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      return Table
    },
    ComputedSelectors () {
      if (this.DialogData?.Type === 'Export') {
        return [['Code'], ['Name'], ['Specifications', 0, 'Description'], ['Specifications', 1, 'Description']]
      } else {
        return [['Code'], ['Name'], ['Specifications', 0, 'Description'], ['Specifications', 1, 'Description']]
      }
    },
    FilteredItems () {
      var Filteredd :any = []
      var IsSearch = this.MEMRead('D_Transfer_Search') && this.MEMRead('D_Transfer_Search').length > 2
      var IsBrand = this.MEMRead('D_Transfer_Brand') && this.MEMRead('D_Transfer_Brand').ID !== undefined
      var IsCategory = this.MEMRead('D_Transfer_Brand') && this.MEMRead('D_Transfer_Category').ID !== undefined
      var Items = this.Items
      var TransferItems = this.TransferItems
      Filteredd = Items.filter((item :any) => {
        var ItemSelected = false
        if (this.DialogData?.Type === 'Export') {
          ItemSelected = TransferItems.some((element :any) => {
            return item.ID === element.ID
          })
        }
        var Search = this.MEMRead('D_Transfer_Search') === '' || this.MEMRead('D_Transfer_Search').length < 3 || this.CookiesSearcher(item, this.MEMRead('D_Transfer_Search'))
        var Brand = !this.MEMRead('D_Transfer_Brand').ID || (this.MEMRead('D_Transfer_Brand').ID === item.BrandID)
        var Category = !this.MEMRead('D_Transfer_Category').ID || (this.MEMRead('D_Transfer_Category').ID === item.CategoryID)
        return (IsSearch || IsBrand || IsCategory) && !ItemSelected && Search && Brand && Category
      })
      return Filteredd
    },
    CashedTranslations () {
      return {
        Name: this.Translate('Name'),
        Brand: this.Translate('Brand'),
        Category: this.Translate('Category'),
        Specs: this.Translate('Specs')
      }
    },
    TotalQuantity () {
      var Total = 0
      this.TransferItems.forEach((item :any) => {
        Total += this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Quantity' + '_' + item.ResID)
      })
      return Total
    },
    DialogDataComputed () {
      return this.DialogData
    },
    DialogMapper () {
      return [
        { Path: 'Items', Res: 'D_Transfer_Items', Default: [] },
        { Path: 'CustomerName', Res: 'D_Transfer_Customer', Default: '' },
        { Path: 'CustomerPhone', Res: 'D_Transfer_CustomerPhone', Default: '' },
        { Path: 'VehicleNumber', Res: 'D_Transfer_VehicleNumber', Default: '' },
        { Path: 'Type', Value: this.DialogData?.Type },
        { Path: 'Notes', Res: 'D_Transfer_Note', Default: '' },
        { Path: 'SupplierID', Res: 'D_Transfers_ImportSource', ValuePath: 'ID', Default: null },
        { Path: 'SupplierName', Res: 'D_Transfers_SupplierName' }
      ] as Array<DialogMapperObject>
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4e2a91fc": (_ctx.Theme.Shade1),
  "42498833": (_ctx.Theme.Light1),
  "7dfd8550": (_ctx.Theme.BG5)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__