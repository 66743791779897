
const routes :Array<CookiesRouter> = [
  {
    path: '/Finance',
    name: 'Finance',
    meta: {
      Access: 'Finance_View' as AccessFeature
    },
    component: () => import('@/Apps/Finance/views/Finance.vue'),
    children: [
      {
        path: '/Finance/InTransactions',
        name: 'View Income',
        component: () => import('@/Apps/Finance/views/TransactionsIncome.vue'),
        meta: {
          Access: 'Finance_View' as AccessFeature,
          LoginHeader: true
        }
      },
      {
        path: '/Finance/OutTransactions',
        name: 'View Outcome',
        component: () => import('@/Apps/Finance/views/TransactionsOutcome.vue'),
        meta: {
          Access: 'Finance_View' as AccessFeature,
          LoginHeader: true
        }
      },
      {
        path: '/Finance/Accounts',
        name: 'View Accounts',
        component: () => import('@/Apps/Finance/views/Accounts.vue'),
        meta: {
          Access: 'Finance_Accounts_Read' as AccessFeature,
          LoginHeader: true
        }
      }
    ]
  }
]
export const FinanceRoutes = routes
