
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: false,
      selectedFile: null,
      Loading: [] as Array<'Loading'>,
      Profiles: [],
      ErrorsVisibilty: false,
      Vendors: [] as any[],
      Items: [] as any[],
      DialogMapper: [
        { Path: 'ItemName', Res: 'D_Purchase_Order_ItemName', ValuePath: 'Name' },
        { Path: 'Price', Res: 'D_Purchase_Order_Price' },
        { Path: 'Quantity', Res: 'D_Purchase_Order_Quantity' },
        { Path: 'CustomDate', Res: 'D_Purchase_Order_CustomDate' },
        { Path: 'Description', Res: 'D_Purchase_Order_Description' },
        { Path: 'CustomerID', Res: 'D_Purchase_Order_Vendor', ValuePath: 'ID', SavingPath: 'CustomerID' },
        { Path: 'Location', Value: '' },
        { Path: 'AddedCost', Value: 0 },
        { Path: 'Discount', Value: 0 },
        { Path: 'SerialNO', Value: 'p' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadVendors()
      this.ReadItems()
    },
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Purchase_Orders',
        OriginalData: this.OriginalData,
        PatchAPI: 'Purchase_Orders',
        Editing: this.DialogData?.Editing,
        PatchQuery: this.DialogData?.Editing ? '/' + this.DialogData?.Data.ID : ''
      })
    },
    ReadVendors () {
      this.Loading.push('Loading')
      this.Get('Purchase_Vendors', '?Type=v').then(response => {
        this.Vendors = this.GeneralSort(response.map((value: any) => { return { ...value, ID: value.ID.toString(), Serial: '#' + value.ID } }), 'ID', -1)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadItems () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=PurchaseItems').then(response => {
        this.Items = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    LogoChanged (event :any) {
      var file = event.target.files[0]
      this.selectedFile = file
      if (!file) {
        return
      }
      this.PostImage(this.selectedFile).then((response :any) => {
        this.MEMWrite('D_Purchase_Order_Logo', response)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Uploading Image'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewVendor () {
      this.OpenDialog('Purchase_Vendor_Dialog', this.Translate('New Vendor'), {})
    },
    EditCategories (Type: string, Name: string) {
      this.OpenDialog('Main_Categories_Dialog', this.Translate('Edit ' + Name), { Data: {}, Type: Type })
    }
  },
  created () {
    if (this.DialogData?.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
    }
    this.ReadDialogData()
  },
  mounted () {
    this.MEMWrite('D_Purchase_Order_CustomDate', new Date().toISOString().slice(0, 10))
    if (this.DialogData?.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
      if (this.DialogData?.Editing && this.DialogData?.Data && this.DialogData?.Data.CustomDate) {
        this.MEMWrite('D_Purchase_Order_CustomDate', new Date(this.DialogData?.Data.CustomDate).toISOString().slice(0, 10))
      }
    }
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadDialogData()
      }
    }
  }
})
