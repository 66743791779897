export const GeneralData = {
  data () {
    return {
      Errors: false,
      ConsoleLogs: false,
      // //////////////////////////////////////////////////
      BakingMinitues: 29,
      CreateCustomersAccounts: true,
      // //////////////////////////////////////////////////
      // //////////////////////////////////////////////////
      Testing: false,
      // ///////////////////////////////////////////////
      EmpTypes: [
        {
          AR: 'سائق',
          EN: 'Driver',
          value: 'Driver',
          salary: 220
        },
        {
          AR: 'سائق عمولة',
          EN: 'Temp Driver',
          value: 'TempDriver'
        },
        {
          AR: 'مدخل بيانات',
          EN: 'Data Entry',
          value: 'DataEntry',
          salary: 240
        },
        {
          AR: 'محاسب',
          EN: 'Financial',
          value: 'Financial',
          salary: 200
        },
        {
          AR: 'متابعة',
          EN: 'Support',
          value: 'Support',
          salary: 260
        },
        {
          AR: 'مدير',
          EN: 'Admin',
          value: 'Admin'
        }
      ],
      States: {
        Ordered: {
          ARA: 'مدخل جديد',
          ENG: 'Ordered',
          value: 'Ordered'
        }
      },
      Languages: {
        ENG: {
          Name: 'English'
        },
        ARA: {
          Name: 'العربية'
        }
      }
    }
  }
}
