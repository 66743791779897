
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    DialogIndex: Number,
    DialogData: { default: {} as DialogData }
  },
  data () {
    return {
      Saving: false,
      Loading: [] as Array<'Loading'>,
      ErrorsVisibilty: false,
      TransferItems: [] as Array<any>,
      ExportItems: [] as Array<any>,
      AvailableItems: [] as any,
      Sections: [] as any,
      ItemsLocation: [] as any,
      ImportLocations: {} as any,
      PayrollItemTypes: [] as Array<PayrollItemType>
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'D_TransferCheck_Table',
        ResID: 'ID',
        MultiLevel: true,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'Code',
            Bold: true
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'ItemName'
          },
          {
            Name: 'Brand',
            Type: 'normal',
            Value: ['Brand', 'Name']
          },
          {
            Name: 'Category',
            Type: 'normal',
            Value: ['Category', 'Name']
          },
          {
            Name: 'Details',
            Type: 'normal',
            Value: 'Details',
            Class: 'F1_25em'
          },
          {
            Name: 'Specs',
            Type: 'array-normal',
            Value: 'Specifications',
            MultiValue: 'Description'
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize',
            Visible: this.DialogData?.Data.Status !== 'Done' || (this.DialogData?.Data.Type !== 'Export' && this.DialogData?.Data.Type !== 'BranchesTransfer')
          },
          {
            Name: 'Quantity',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Code'
        }
      } as CookiesTable
      return Table
    },
    TableSettingsExport () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'D_TransferCheck_Table',
        ResID: 'ID',
        MultiLevel: true,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'Code',
            Bold: true
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'Name'
          },
          {
            Name: 'Brand',
            Type: 'normal',
            Value: ['Brand', 'Name']
          },
          {
            Name: 'Category',
            Type: 'normal',
            Value: ['Category', 'Name']
          },
          {
            Name: 'Specs',
            Type: 'array-normal',
            Value: 'Specifications',
            MultiValue: 'Description'
          },
          {
            Name: 'Details',
            Type: 'normal',
            Value: 'Details',
            Class: 'F1_25em'
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize',
            Class: 'F1_25em'
          },
          {
            Name: 'Quantity',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true,
            Class: 'F1_25em'
          },
          {
            Name: 'Location',
            Type: 'normal',
            Value: 'ExportLocation',
            Class: 'F1_25em',
            Bold: true,
            Style: 'min-width: 11em'
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Code'
        }
      } as CookiesTable
      return Table
    },
    NotEnoughSpace () {
      var NoSpace = false
      var Locations = this.ImportLocations
      Object.keys(Locations).some(function (item) {
        var Found = Locations[item].find((element:any) => {
          console.log(element)
          return element.LocationFullPath === 'Not Enough Space'
        })
        console.log(Found)
        if (Found) {
          NoSpace = true
        }
      })
      return NoSpace
    },
    TransferItemsFrom () {
      return this.TransferItems.filter(element => {
        return element.Type === 'Reserved'
      }).map(element => {
        var Location = ''
        var ItemLoc = this.ItemsLocation.find((loc :any) => {
          return loc.ID === element.ItemLocationID
        })
        console.log(element)
        console.log('ItemLoc')
        console.log(ItemLoc)
        if (ItemLoc) {
          var Section = this.Sections.find((sec :any) => {
            return ItemLoc.SectionID === sec.ID
          })
        }
        // console.log('Section')
        // console.log(Section)
        if (Section) {
          var Sectionlevel1 = null
          var Sectionlevel2 = null
          if (Section.SectionLevel1ID) {
            Sectionlevel1 = this.Sections.find((sec :any) => {
              return sec.ID === Section.SectionLevel1ID
            })
          }
          if (Section.SectionLevel2ID) {
            Sectionlevel2 = this.Sections.find((sec :any) => {
              return sec.ID === Section.SectionLevel2ID
            })
          }
          Location = (Section.WarehouseName && Section.WarehouseName !== '' ? Section.WarehouseName : '') + `(${Section.WarehouseCode})` + (Sectionlevel1 ? (' > ' + (Sectionlevel1.Name ? Sectionlevel1.Name : '') + `(${Sectionlevel1.Code})`) : '') + (Sectionlevel2 ? (' > ' + (Sectionlevel2.Name ? Sectionlevel2.Name : '') + `(${Sectionlevel2.Code})`) : '') + ' > ' + (Section.Name && Section.Name !== '' ? Section.Name : '') + `(${Section.Code})`
        }
        console.log('Location')
        console.log(Location)
        return {
          ExportLocation: Location,
          ...element
        }
      })
    },
    TransferItemsTo () {
      return this.TransferItems.filter(element1 => {
        return element1.Type === 'Ordered'
      }).map(element => {
        var Location = ''
        var ItemLoc = this.ItemsLocation.find((loc :any) => {
          return loc.ID === element.ItemLocationID
        })
        console.log(element)
        console.log('ItemLoc')
        console.log(ItemLoc)
        if (ItemLoc) {
          var Section = this.Sections.find((sec :any) => {
            return ItemLoc.SectionID === sec.ID
          })
        }
        console.log('Section')
        console.log(Section)
        if (Section) {
          var Sectionlevel1 = null
          var Sectionlevel2 = null
          if (Section.SectionLevel1ID) {
            Sectionlevel1 = this.Sections.find((sec :any) => {
              return sec.ID === Section.SectionLevel1ID
            })
          }
          if (Section.SectionLevel2ID) {
            Sectionlevel2 = this.Sections.find((sec :any) => {
              return sec.ID === Section.SectionLevel2ID
            })
          }
          Location = (Section.WarehouseName && Section.WarehouseName !== '' ? Section.WarehouseName : '') + `(${Section.WarehouseCode})` + (Sectionlevel1 ? (' > ' + (Sectionlevel1.Name ? Sectionlevel1.Name : '') + `(${Sectionlevel1.Code})`) : '') + (Sectionlevel2 ? (' > ' + (Sectionlevel2.Name ? Sectionlevel2.Name : '') + `(${Sectionlevel2.Code})`) : '') + ' > ' + (Section.Name && Section.Name !== '' ? Section.Name : '') + `(${Section.Code})`
        }
        console.log('Location')
        console.log(Location)
        return {
          ExportLocation: Location,
          ...element
        }
      })
    },
    CombinedItems () {
      if (this.DialogData?.Data.Type !== 'Export' && this.DialogData?.Data.Type !== 'BranchesTransfer' && !this.$store.state.CookiesConfig[`Branch_${this.$store.state.BranchManagment.CurrentBranch?.ID}_AlwaysCombineItems`]) {
        return this.TransferItems
      }
      var Combined :Array<any> = []
      if (!this.TransferItems) {
        return Combined
      }
      this.TransferItems.forEach(element => {
        var Found = Combined.find((added:any) => {
          console.log(element)
          return !element.IsUserSelect && added.ItemID === element.ItemID
        })
        if (Found) {
          Found.Quantity += element.IsPack ? element.Quantity * element.PackSize : element.Quantity
        } else {
          // var Name = element.Name ? element.Name : this.ObjectFinder()
          Combined.push({ ...element, Quantity: element.IsPack ? element.Quantity * element.PackSize : element.Quantity, PackSize: 1 })
        }
      })
      return Combined
    }
  },
  // ---------------------------------------------------
  methods: {
    ReadPayrollItemTypes () {
      this.Get('HR_PayrollItemTypes').then(response => {
        this.PayrollItemTypes = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading PayrollItem Type'
        this.OpenDialog('Error_Dialog', 'Error', error)
      })
    },
    printIT (id: string) {
      var bodd = document.getElementById(id)
      if (!bodd) return
      this.$store.state.Printing = bodd.cloneNode(true) as HTMLElement
    },
    GetLocations () {
      // if (!this.Transfertem || !this.MEMRead('D_Transfer_WarehouseFrom')) return
      this.Loading.push('Loading')
      this.Get('Inventory_ItemsLocation').then(response => {
        this.ItemsLocation = response
        console.log(response)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.ReadPayrollItemTypes()
  },
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})
