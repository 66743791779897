
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: false,
      EditSave: false,
      Loading: [] as Array<'Loading'>,
      CookiesData: {
        Items: []
      } as any,
      ErrorsVisibilty: false,
      TableSettings: {
        TableName: this.Translate('Items'),
        CookiesID: 'D_Catalog_Items_Table',
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Type: 'Image',
            Value: 'ImgURL',
            Name: 'Image',
            Style: 'width: 3em;'
          },
          {
            Name: 'Code',
            Type: 'normal',
            Value: 'Code',
            Sort: 'Code',
            Bold: true
          },
          {
            Name: 'Category',
            Type: 'normal',
            Value: 'Description',
            Sort: 'Description',
            Bold: true
          },
          {
            Name: 'QR',
            Type: 'QR',
            Value: 'QRURL',
            Style: 'width: 5em;',
            QR_Style: 'width: 4em; height: 4em;'
          },
          {
            Name: 'Edit',
            Type: 'icon',
            Value: 'Edit' as IconsIDs,
            Style: 'width: 3em',
            Function: 'EditItem',
            ToolTip: this.Translate('Edit')
          },
          {
            Name: 'Delete',
            NoHeader: true,
            Type: 'delete',
            Res_ID: 'ID',
            Style: 'width: 3em',
            NoPrint: true
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Name'
        },
        Sorter: 'Name'
      } as CookiesTable,
      TableData: [] as Array<CatalogItem>
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadCatalog()
    },
    ReadCatalog () {
      this.Loading.push('Loading')
      this.Get('Catalogs', '/' + this.DialogData?.Data.ID).then(response => {
        this.Console(response)
        var modifiedResItems = response.Items.map((item :any) => {
          item.Name = parseInt(item.Name)
          item.QRURL = window.location.hostname + '/I/Q/' + this.DialogData?.Data.ID + '/' + item.ID
          return item
        })
        var ModifiedRes = response
        ModifiedRes.Items = modifiedResItems
        this.CookiesData = ModifiedRes
        this.MEMWrite('D_Catalog_Items', ModifiedRes.Items)
        this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.CookiesData)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Catalog'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Catalogs',
        OriginalData: this.OriginalData,
        PatchAPI: 'Catalogs',
        Editing: this.DialogData?.Editing,
        PatchQuery: this.DialogData?.Editing ? '/' + this.DialogData?.Data.ID : undefined
      })
    },
    DataSaved (response :any) {
      this.Saving = false
      this.$router.go(-1)
    },
    PrintTable () {
      const TableDom = document.getElementById('D_Profiles_Print')
      this.PrintIT(TableDom, { PageSize: 'A4 portrait', Header: true })
    },
    AddItem () {
      this.OpenDialog('CatalogItem_Dialog', this.Translate('New Item'), { Data: { Res: 'D_Catalog_Items', Name: this.MEMRead('D_Catalog_Items').length + 1, IsNew: true, Categories: this.AvailableCategories() } })
    },
    EditItem (item :CatalogItem, Index :number) {
      var IsEdited = true
      if (item.IsNew) {
        IsEdited = false
      }
      this.OpenDialog('CatalogItem_Dialog', this.Translate('New Item'), { Data: { Res: 'D_Catalog_Items', ...item, ResIndex: Index, IsEdited: IsEdited, Categories: this.AvailableCategories() }, Editing: true })
    },
    RemoveItem (Item :CatalogItem, index:number) {
      var ModifiedTable = this.DeepCloner(this.MEMRead('D_Catalog_Items'))
      if (this.TableSettings.Sorter) {
        var OriginalItemIndex = this.DeepFetcher(Item, this.TableSettings.Sorter)
        ModifiedTable.splice(index, 1)
        ModifiedTable.forEach((element :any) => {
          if (this.TableSettings.Sorter && this.DeepFetcher(element, this.TableSettings.Sorter) > OriginalItemIndex) {
            this.DeepChanger(element, this.TableSettings.Sorter, this.DeepFetcher(element, this.TableSettings.Sorter) - 1)
          }
        })
      } else {
        ModifiedTable.splice(index, 1)
      }
      this.MEMWrite('D_Catalog_Items', ModifiedTable)
    },
    CustomSorting (NewSort :any) {
      this.MEMWrite('D_Catalog_Items', NewSort.SortedData)
    },
    AvailableCategories () {
      var Categories :Array<string> = []
      var CategoriesObjArray :any = []
      this.MEMRead('D_Catalog_Items').forEach((element :any) => {
        if (element.Description !== '' && Categories.indexOf(element.Description) === -1) {
          Categories.push(element.Description)
          CategoriesObjArray.push({
            Name: element.Description
          })
        }
      })
      return CategoriesObjArray
    }
  },
  created () {
    this.MEMWrite('D_Catalog_Items', [])
    // if (this.DialogData?.Data) {
    //   this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
    // }
    // this.MEMWrite('D_Catalog_AddedItems', [])
  },
  mounted () {
    if (this.DialogData?.Data) {
      // this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
    }
    if (this.DialogData?.Editing) {
      this.ReadDialogData()
    }
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_Catalog')
  },
  computed: {
    DialogMapper () {
      return [
        { Path: 'Name', Res: 'D_Catalog_Name' },
        { Path: 'Description', Res: 'D_Catalog_Description', Default: '' },
        { Path: 'ImgURL', Res: 'D_Catalog_Image', IsImage: true, Default: '' },
        { Path: 'Description', Res: 'D_Catalog_Description', Default: '' },
        { Path: 'Media', Res: 'D_Catalog_Media', Default: [] },
        { Path: 'Tags', Res: 'D_Catalog_Tags', Default: {} },
        { Path: ['Configs', 'Sort'], Value: this.DialogData?.Sort },
        { Path: 'Items', Res: 'D_Catalog_Items', IDPath: 'ID', Array: [['ID'], ['Name'], ['Code'], ['Description'], ['ImgURL']], Default: [] }
      ] as Array<DialogMapperObject>
    }
  }
})
