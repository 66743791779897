
import { defineComponent } from 'vue'
import CookiesCoreMixin from '@/modules/CookiesCoreMixin.vue'
const __default__ = defineComponent({
  mixins: [CookiesCoreMixin],
  data () {
    return {
      Email: '',
      Password: '',
      ErrorsVisibilty: false,
      UserNameServerErrors: '',
      PassServerErrors: '',
      ServerError: '',
      ShowPass: false
    }
  },
  mounted () {
    var savedEmail = this.LocalRead('CookiesUserName')
    this.Console('savedEmail')
    this.Console(savedEmail)
    if (savedEmail !== null) {
      this.Email = savedEmail
      this.MEMWrite('SignInRememberMe', true)
    }
    try {
      const SigninDialog = document.getElementById('CookiesSignDialog')
      const DialogPos = SigninDialog ? SigninDialog.offsetTop - 60 : 0
      window.scrollTo(0, DialogPos)
    } catch (error) {
    }
  },
  computed: {
    DialogErrors () {
      var ERR = []
      if (!this.Email || this.Email === '') {
        ERR.push('Enter Email')
      }
      if (!this.Password || this.Password === '') {
        ERR.push('Enter Password')
      }
      return ERR
    }
  },
  methods: {
    SignIN () {
      const _this = this
      if (this.DialogErrors.length > 0) {
        this.ErrorsVisibilty = true
        return
      }
      this.UserNameServerErrors = ''
      this.PassServerErrors = ''
      this.ServerError = ''
      this.ErrorsVisibilty = false
      var RememberMe = this.$store.state.CookiesMemory.SignInRememberMe
      if (RememberMe) {
        this.LocalSave('CookiesUserName', this.Email.replace(/nbsp;/g, '').trim())
      } else {
        this.LocalSave('CookiesUserName', null)
      }
      this.Post('POST', 'Login', { UserName: this.Email.replace(/nbsp;/g, '').trim(), Password: this.Password.trim() }).then((response) => {
        var ExpirationTime = new Date().getTime() + (this.BakingMinitues * 60 * 1000)
        this.Console(response)
        this.Console(this.DeepFetcher(response, 'AccessToken'))
        if (RememberMe) {
          this.LocalSave('CookiesJWT', { value: this.DeepFetcher(response, 'AccessToken'), exp: ExpirationTime })
          this.LocalSave('CookiesBAKERY', { value: this.DeepFetcher(response, 'RefreshToken') })
        } else {
          this.SessionSave('CookiesJWT', { value: this.DeepFetcher(response, 'AccessToken'), exp: ExpirationTime })
          this.SessionSave('CookiesBAKERY', { value: this.DeepFetcher(response, 'RefreshToken') })
        }
        this.$emit('SignedIn')
      }, error => {
        this.Console('errorrr')
        this.Console(error)
        handleErrors(error)
        function handleErrors (errortobehandeled: any) {
          _this.Console('error')
          _this.Console(errortobehandeled)
          _this.Console(errortobehandeled.errors)
          console.log(errortobehandeled)
          var Errors = errortobehandeled.errors
          if (Errors && Errors.UserName !== undefined) {
            _this.UserNameServerErrors = Errors.UserName[0]
          }
          if (Errors && Errors.Password !== undefined) {
            _this.PassServerErrors = Errors.Password[0]
          }
          _this.ErrorsVisibilty = true
          _this.ServerError = errortobehandeled.Message ? errortobehandeled.Message : errortobehandeled.message
        }
      })
    },
    CloseMe () {
      if (this.Access(this.$route.meta.Access as AccessFeature)) {
        this.$store.state.Accessable = true
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0c171cb6": (_ctx.Theme.BackBlur),
  "6579c636": (_ctx.Theme.BG2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__