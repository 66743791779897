
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ReadCustomers()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, undefined)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<any>,
      Customers: [] as any[],
      MappedCustomers: {} as any,
      Profiles: [] as Array<Profile>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {
        Lists: [
          {
            Name: 'Customers',
            Options: this.Customers,
            Res: 'PurchaseOrderFilter',
            Selectors: [['Name'], ['ID']],
            Visible: true,
            DataPath: ['CustomerID'],
            ListPath: ['ID']
          }
        ]
      }
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Sale',
          this: this,
          Function: 'NewSale',
          Icon: 'Add',
          Visible: this.Access('Sales_Orders_Create')
        },
        {
          Name: this.Translate('Collect'),
          this: this,
          Function: 'NewPayment',
          Icon: 'ItemImport',
          Visible: this.Access('Finance_Transactions_Create')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: { Direction: -1, Path: 'ID' },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'DateTime',
            Name: 'Date',
            Path: 'CustomDate',
            Sort: 'CustomDate'
          },
          {
            Type: 'Normal',
            Name: 'Serial',
            Bold: true,
            Path: ['CustomerID'],
            Sort: ['CustomerID'],
            Hash: '#',
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Customer',
            Path: ['CustomerName'],
            Sort: ['CustomerName'],
            Bold: true,
            Class: 'F1_25em',
            Style: 'cursor: pointer;',
            IsSearchable: true,
            Emits: 'OpenVendor'
          },
          {
            Type: 'Normal',
            Name: 'Item',
            Path: ['ItemName'],
            Sort: ['ItemName'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Location',
            Path: ['Location'],
            Sort: ['Location'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Details',
            Path: ['Description'],
            Sort: ['Description'],
            MultiLine: true,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Quantity',
            Path: ['Quantity'],
            Sort: ['Quantity'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Price',
            Path: ['Price'],
            Sort: ['Price'],
            Unit: this.PriceUnit,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Added Cost',
            Path: ['AddedCost'],
            Sort: ['AddedCost'],
            Unit: this.PriceUnit,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Discount',
            Path: ['Discount'],
            Sort: ['Discount'],
            Class: 'colorr1',
            Unit: this.PriceUnit,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Total',
            Path: ['Total'],
            Sort: ['Total'],
            Bold: true,
            Class: 'F1_25em',
            Unit: this.PriceUnit,
            IsSearchable: true
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Edit',
                Visible: this.Access('Sales_Orders_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditBranch',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: false,
                  ValuePath: 'IsNew'
                }
              },
              {
                ID: 'Edit',
                Visible: true,
                ToolTip: this.Translate('Edit'),
                Emits: 'EditBranch',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: true,
                  ValuePath: 'IsNew'
                }
              },
              {
                ID: 'Delete',
                Emits: 'DeleteBranch',
                Visible: this.Access('Sales_Orders_Remove'),
                ToolTip: this.Translate('Remove'),
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: false,
                  ValuePath: 'IsNew'
                }
              },
              {
                ID: 'Delete',
                Emits: 'DeleteBranch',
                ToolTip: this.Translate('Remove'),
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: true,
                  ValuePath: 'IsNew'
                }
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.ModifiedData, this.Filter, this.Search)
    },
    ModifiedData () {
      var DateSpan = new Date(new Date().getTime() - (60 * 60 * 24 * 1000))
      return this.CookiesData.map((value) => { return { ...value, CustomerName: this.MappedCustomers['c' + value.CustomerID?.toString()], IsNew: new Date(value.AddedDate) > DateSpan } })
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Sales_Orders', '?SerialNO=s').then(response => {
        this.CookiesData = response.map((value: any) => { return { ...value, PhoneCall: 'tel:' + value.PhoneNumber, Total: (value.Price * value.Quantity) + value.AddedCost + value.Discount } })
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCustomers () {
      this.Loading.push('Loading')
      this.Get('CRM_Customers', '?Type=c').then(response => {
        this.Customers = this.GeneralSort(response.map((value: any) => { return { ...value, ID: value.ID.toString(), Serial: '#' + value.ID } }), 'ID', -1)
        this.Customers.forEach((value, index, array) => { this.MappedCustomers['c' + value.ID] = value.Name })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditBranch (Customer: any) {
      this.OpenDialog('Sales_Order_Dialog', this.Translate('Edit Sale') + ' (' + Customer.ID + ')', { Data: Customer, Editing: true }, {})
    },
    DeleteBranch (Branch: any) {
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Sales_Orders', AddQuery: '/' + Branch.ID, Text: 'Are you sure you want to delete <strong>' + Branch.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.BranchDeleted, AfterDialogFunctionValue: Branch.ImgUrl } as GeneralAcceptDialogData, {})
    },
    BranchDeleted (ImgUrl :string) {
      if (ImgUrl) this.DeleteImage(ImgUrl)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewSale':
          this.OpenDialog('Sales_Order_Dialog', this.Translate('New Sale'), {})
          break
        case 'NewPayment':
          this.OpenDialog('Finance_Transaction_Dialog', this.Translate('New Transaction'), { Data: { Type: 'Collect', CustomerType: 'c' } })
          break
      }
    },
    ViewLocation (Branch :Branch) {
      window.open('https://www.google.com/maps/@' + Branch.GPSLongitude + ',' + Branch.GPSLatitude)
    },
    Whatsapp (customer: any) {
      this.OpenWhatsapp(customer.PhoneNumber)
    },
    OpenVendor (Order :any) {
      this.OpenDialog('Purchase_VendorView_Dialog', this.Translate('View Customer'), { Data: { VendorID: Order.CustomerID } })
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
        this.ReadCustomers()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
